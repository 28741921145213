import { Box, Typography } from '@mui/material';
import React from 'react';

function CustomHeader({value = null, name = null, label = '', sublabel = '', helperText = null, disabled = false}) {
    return (
        <Box sx={{ marginTop: 1, marginBottom: 1 }}>
            <Typography component="h1" variant="h5">{label}</Typography>
            <Typography component="p" color="secondary">{helperText ?? sublabel}</Typography>
        </Box>
    );
}

export default CustomHeader;