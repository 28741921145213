import { Close } from '@mui/icons-material';
import { TextField } from '@mui/material';
import { Slider } from '@mui/material';
import { AppBar, Button, Container, Dialog, Toolbar, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { Box } from '@mui/system';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import SophyappService from '../../Services/SophyappService';
import Events from '../../Utility/Events';
import CachingHelper from '../../Utility/CachingHelper.js';

const useStyles = (theme) => ({
    toolbar: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    alert: {
        marginTop: theme.spacing(2)
    },
    feedbackText: {
        width: '100%'
    }
});

const DEFAULT_STATE = {
    value: 5,
    feedback: "",
    mounted: false,
    types: CachingHelper.get('feedback-types') || [],
    type: {},
};

class SophyappProgramFeedback extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            ...DEFAULT_STATE,
        };
    }

    componentDidMount() {
        const { types } = this.state;
        if (types.length === 0) {
            SophyappService
                .feedbackTypes()
                .then(response => {
                    const body = response.data;

                    this.setState({
                        types: body
                    }, () => {
                        this.setCurrentType();
                        CachingHelper.set('feedback-types', body);
                    });
                });
        } else {
            this.setCurrentType();
        }
    }

    setCurrentType = () => {
        const { types } = this.state;
        const { entity } = this.props;
        const goal_id = parseInt(entity.goal_id ?? 1);

        if (goal_id) {
            const type = types.find(el => el.id === goal_id);
            if (type) {
                this.setState({
                    type: type,
                });
            }
        }
    }

    resetDialog = () => {
        this.setState({
            ...DEFAULT_STATE
        });
    }

    handleOnScaleChange = (e, value) => {
        this.setState({
            value: value,
        });
    }

    handleOnChangeFeedback = (e) => {
        const value = e.target.value;
        this.setState({
            feedback: value
        });
    }

    handleSubmitFeedback = (e) => {
        const { entity, onClose, onSubmit, t } = this.props;
        const { value, feedback } = this.state;

        if (typeof entity.id !== 'undefined' && entity.id) {
            Events.dispatch('startLoading');
            SophyappService.feedback(entity.id, {
                value: value,
                feedback: feedback
            })
            .then(response => {
                this.resetDialog();
                if (typeof onClose === 'function') {
                    onSubmit(e);
                }
                if (typeof onClose === 'function') {
                    onClose(e);
                }
                Events.dispatch('msgSuccess', t('feedback-successfull'));
                Events.dispatch('doneLoading');
            })
            .catch(err => {
                Events.dispatch('msgError', t('feedback-failed'));
                Events.dispatch('doneLoading');
            });
        }
    }

    render() {
        const { t, classes, show, onClose } = this.props;
        const { value, feedback, type } = this.state;
        
        return (
            <Dialog
                fullScreen
                open={show}
                onClose={() => {}}
            >
                <AppBar sx={{ position: 'sticky' }}>
                    <Toolbar className={classes.toolbar}>
                        <Container sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
                            <Box flexGrow={1}>
                                <Typography variant='h5'>
                                    {t('feedback')}
                                </Typography>
                            </Box>
                            <Button autoFocus color='inherit' onClick={(e) => onClose(e)}>
                                {t('close')} <Close />
                            </Button>
                        </Container>
                    </Toolbar>
                </AppBar>
                <Container sx={{ margin: 'auto' }}>
                    <Box textAlign={'center'}>
                        <Typography variant='h4' sx={{ marginBottom: 4 }}>
                            {type.question ?? ''}
                        </Typography>
                        <Box>
                            <Typography variant={'h1'} sx={{ marginBottom: 2 }}>
                                {value}
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', width: '100%' }}>
                            <Box flexShrink={1}>{type.min ?? ''}</Box>
                            <Box flexGrow={1}>&nbsp;</Box>
                            <Box flexShrink={1}>{type.max ?? ''}</Box>
                        </Box>
                        <Slider min={0} max={10} value={value} onChange={this.handleOnScaleChange} valueLabelDisplay={'off'}/>
                        <TextField
                            className={classes.feedbackText}
                            label={t('feedback') + ' (' + t('optional') + ')'}
                            multiline
                            rows={6}
                            placeholder={t('additional-feedback')}
                            value={feedback}
                            onChange={this.handleOnChangeFeedback}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                        <Box sx={{ marginTop: 2 }} textAlign={'center'}>
                            <Button variant="contained" size="large" onClick={this.handleSubmitFeedback}>{t('send')}</Button>
                        </Box>
                    </Box>
                </Container>
            </Dialog>
        );
    }
}

SophyappProgramFeedback.defaultProps = {
    show: false,
    onClose: null,
    entity: null,
};


const TranslatedPasswordRequest = withTranslation('translation')(SophyappProgramFeedback);
export default withStyles(useStyles)(TranslatedPasswordRequest);