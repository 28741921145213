import { Box, Paper, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { formatDate, formatWeekdayDate, formatWeekdayShort, getTimeInMinutes } from '../../../Utility/Formatter';
import DateCardItem from './DateCardItem';

const useStyles = (theme) => ({
    weekday: {
        position: 'relative',
        height: 'auto',
        width: '100%',
        marginLeft: theme.spacing(0.5),
        marginRight: theme.spacing(0.5),
        marginBottom: theme.spacing(2),
    },
    weekdayHeader: {
        position: 'sticky',
        top: theme.spacing(8.5),
        zIndex: 10,
        padding: theme.spacing(1),
        paddingTop: theme.spacing(1.25),
        paddingBottom: theme.spacing(1.5),
        paddingLeft: theme.spacing(1.5),
        height: theme.spacing(8),
        marginTop: theme.spacing(1.5),
        marginBottom: theme.spacing(1),
        background: theme.palette.primary.main,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
    },
    weekdayHeaderText: {
        color: theme.palette.getContrastText(theme.palette.primary.main)
    }
});

const DAYDURATION = 1440;

class Weekday extends Component {
    render() {
        const { t, classes, theme } = this.props;
        const { date, items, intervall, onInfoOpen, denseView, minTime, slotHeight, viewType } = this.props;
        
        const getDateHeight = (dateItem) => {
            let duration = getTimeInMinutes(dateItem.end) - getTimeInMinutes(dateItem.begin);
            let slots    = parseInt(Math.ceil(duration / intervall));

            return theme.spacing(slots * slotHeight - 0.5);
        };

        const getDatePosition = (dateItem) => {
            let start    = getTimeInMinutes(dateItem.begin) - minTime;
            let slots    = parseInt(Math.ceil(start / intervall));

            return theme.spacing(slots * slotHeight + 12 + 0.5);
        };

        const isToday = (date) => {
            return formatDate(date).setHours(0,0,0,0) === formatDate(new Date()).setHours(0,0,0,0);
        }

        return (
            <Box className={classes.weekday}>
                <Paper className={classes.weekdayHeader}>
                    <Box marginTop={1} position='relative'>
                        <Typography variant="h6" component="p" className={classes.weekdayHeaderText} sx={{ fontWeight: (isToday(date) ? 'bold' : 'normal') }} title={isToday(date) ? 'Heutiger Tag' : undefined }>{formatWeekdayShort(date) + ", " + formatWeekdayDate(date)}</Typography>
                    </Box>
                </Paper>
                {items.length > 0
                    ?items.map((dateItem, index) => {
                        const props = {
                            key: 'weekday-item-'+index+'-'+(dateItem.user_id??'u')+'-'+(dateItem.article_id ?? 'a')+'-'+(dateItem.location_id?? 'l')+'-'+(date.begin ?? 'b'),
                            item: dateItem,
                            denseView: viewType !== 'calendar',
                            viewType: viewType,
                            sx: {
                                position: (viewType !== 'calendar' ? 'relative' : 'absolute' ),
                                height: (viewType !== 'calendar' ? 'auto' : getDateHeight(dateItem)),
                                top: (viewType !== 'calendar' ? 0 : getDatePosition(dateItem)),
                                marginBottom: (viewType !== 'calendar' ? 1 : 0)
                            }
                        };
                        return <DateCardItem {...props}/>;
                    })
                    :(viewType === 'grid' && <Box sx={{ padding: 2, textAlign: 'center' }}>
                        <Typography variant="subtitle2" component="div" color="secondary">{t('no-appointments')}</Typography>
                    </Box>)
                }
            </Box>
        );
    }
}

Weekday.defaultProps = {
    date: null,
    viewType: 'grid',
    intervall: 5,
    items: [],
    onInfoOpen: null,
    minTime: 0,
    maxTime: DAYDURATION
}


const  TranslatedComponent = withTranslation('translation')(Weekday);
export default withStyles(useStyles, { withTheme: true })(TranslatedComponent);