import { Container } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import AuthService from '../../../Services/AuthService';
import { AuthContext } from '../../Contexts/AuthContext';
import DsgvoContent from './DsgvoContent';

const useStyles = (theme) => ({
});

class DsgvoPublic extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            mounted: false,
        };
    }
    
    componentDidMount() {
        const { clientNumber } = this.props.match.params;

        if (clientNumber !== undefined) {
            const { switchTheme } = this.context;
            switchTheme(clientNumber)
                .then(r => {
                    this.setState({
                        mounted: true,
                    });
                });
        }
    }
    

    render() {
        const { mounted } = this.state;

        return (
            <Container>
                {mounted && <DsgvoContent/>}
            </Container>
        );
    }
}

DsgvoPublic.contextType=AuthContext;

const TranslatedPasswordRequest = withTranslation('translation')(DsgvoPublic);
export default withStyles(useStyles)(TranslatedPasswordRequest);