import withStyles from '@mui/styles/withStyles';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import DocumentService from '../../Services/DocumentService.js';
import CachingHelper from '../../Utility/CachingHelper.js';
import { AuthContext } from '../Contexts/AuthContext.js';
import ConfiguredCustomText from '../Elements/ConfiguredCustomText.js';
import MainContent from '../Elements/MainContent.js';
import DocumentList from './Lists/DocumentList.js';

const useStyles = (theme) => ({
    
});


class Receipts extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            documents: CachingHelper.get('receipts') || [],
            path: '',
        };
    }

    componentDidMount() {
        this.loadData();
    }

    loadData = () => {
        DocumentService
            .listReceipts(true, true)
            .then(response => {
                this.setState({
                    documents: response
                }, () => {
                    CachingHelper.set('receipts', response);
                });
            });
    }

    handleSwitchPath = (e, path) => {
        this.setState({
            path: path
        });
    }

    render() {
        const { t, classes } = this.props;
        const { documents, path } = this.state;

        const getDocumentsInPath = (path) => {
            if (path !== null) {
                const folder =  documents.find(item => item.path === path);
                if (folder) {
                    if (typeof folder.children !== 'undefined') {
                        return folder.children;
                    }
                }
            }
            return documents;
        }

        return (
            <AuthContext.Consumer>
                {({ clientConfigurations }) => (
                    <MainContent
                        title={<ConfiguredCustomText fallback={t('receipts')} section={'receipts'} label={'title'}/>}
                        textPrimary={<ConfiguredCustomText fallback={t('text-head-receipts')} section={'receipts'} label={'primary'}/>}
                        textSecondary={<ConfiguredCustomText fallback={t('text-subhead-receipts')} section={'receipts'} label={'secondary'}/>}
                    >
                        <DocumentList items={getDocumentsInPath(path)} handleSwitchPath={(e, path) => this.handleSwitchPath(e, path)} type={'receipts'}/>
                    </MainContent>
                )}
            </AuthContext.Consumer>
        );
    }
}

const  TranslatedComponent = withTranslation('translation')(Receipts);
export default withStyles(useStyles)(TranslatedComponent);