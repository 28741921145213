import { ContentCopy } from '@mui/icons-material';
import { Accordion, AccordionSummary, Alert, Box, ButtonBase, Grid, Hidden, IconButton, ListItemText, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import BookmarkService from '../../Services/BookmarkService.js';
import CachingHelper from '../../Utility/CachingHelper.js';
import Events from '../../Utility/Events.js';
import { AuthContext } from '../Contexts/AuthContext.js';
import ConfiguredCustomText from '../Elements/ConfiguredCustomText.js';
import MainContent from '../Elements/MainContent.js';

const useStyles = (theme) => ({
    url: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    listItem: {
        maxWidth: '100%',
        position: 'relative',
        '& .MuiAccordionSummary-content': {
            maxWidth: '100%',
            position: 'relative',
        }
    }
});


class Bookmarks extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            bookmarks: CachingHelper.get('bookmarks') || [],
        };
    }

    componentDidMount() {
        this.loadData();
    }

    loadData = () => {
        const { t } = this.props;

        BookmarkService
            .list()
            .then(response => {
                const body = response.data;
                
                this.setState({
                    bookmarks: body
                }, () => {
                    CachingHelper.set('bookmarks', body);
                });
            })
            .catch(err => {
                Events.dispatch('msgError', t('failed-to-fetch-data'));
            });
    }

    handleCopyToClipboard = (e, item) => {
        const { t } = this.props;

        if (typeof item.url === 'string' && item.url !== '') {
            if (navigator.clipboard) {
                navigator.clipboard.writeText(item.url);
                Events.dispatch('msgSuccess', t('successfully-copied-text'));
                e.stopPropagation();
                e.preventDefault();
                return;
            }
        }
    }

    render() {
        const { t, classes } = this.props;
        const { bookmarks } = this.state;

        return (
            <AuthContext.Consumer>
                {({ clientConfigurations }) => (
                    <MainContent
                        title={<ConfiguredCustomText fallback={t('bookmarks')} section={'bookmarks'} label={'title'}/>}
                        textPrimary={<ConfiguredCustomText fallback={t('text-head-bookmarks')} section={'bookmarks'} label={'primary'}/>}
                        textSecondary={<ConfiguredCustomText fallback={''} section={'bookmarks'} label={'secondary'}/>}
                    >
                        {bookmarks.length > 0
                        ? bookmarks.map((item, index) => {
                            return <React.Fragment key={"bookmark-"+item.id ?? index}>
                                <Accordion expanded={false}>
                                    <AccordionSummary className={classes.listItem}>
                                        <ButtonBase
                                            sx={{ width: '100%', position: 'relative' }}
                                            component={'a'}
                                            href={item.url}
                                            target={'_blank'}
                                            title={'Link öffnen'}
                                        >
                                            <Grid container>
                                                <Grid item xs={12} md={6} sx={{ position: 'relative' }}>
                                                    <Box sx={{ display: 'flex', alignItems: 'center', height: '100%', position: 'relative' }} title={item.url}>
                                                        <Box flexGrow={1} flexShrink={1}>
                                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                                <ListItemText
                                                                    primary={item.title ?? ''}
                                                                    secondary={item.description ?? ''}
                                                                />
                                                            </Box>
                                                        </Box>
                                                        {navigator.clipboard && <Box flexShrink={1} marginRight={2}>
                                                            <IconButton onClick={(e) => this.handleCopyToClipboard(e, item)}>
                                                                <ContentCopy size="large"/>
                                                            </IconButton>
                                                        </Box>}
                                                    </Box>
                                                </Grid>
                                                <Hidden mdDown>
                                                    <Grid item md={6} sx={{ position: 'relative' }}>
                                                        <Box sx={{ display: 'flex', alignItems: 'center', height: '100%', position: 'relative' }} title={item.url}>
                                                            <Box flexGrow={1} flexShrink={1} className={classes.url}>
                                                                <Typography color='secondary' variant='p'>
                                                                    {item.url}
                                                                </Typography>
                                                            </Box>
                                                        </Box>
                                                    </Grid>
                                                </Hidden>
                                            </Grid>
                                        </ButtonBase>  
                                    </AccordionSummary>                                   
                                </Accordion>
                            </React.Fragment>;
                        })
                        :<Box className={classes.galleryplaceholder}>
                            <Alert severity="info">{t('no-bookmarks-available')}</Alert>
                        </Box>}
                    </MainContent>
                )}
            </AuthContext.Consumer>
        );
    }
}

const  TranslatedComponent = withTranslation('translation')(Bookmarks);
export default withStyles(useStyles)(TranslatedComponent);