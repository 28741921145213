import { Box, Card, Grid, Link, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import ConfiguredCustomText from '../Elements/ConfiguredCustomText';



const useStyles = (theme) => ({
    form: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        paddingTop: theme.spacing(2),
    },
    spacer: {
        marginTop: theme.spacing(1),
    },
});


class VerifyMsg extends Component {
    render() {
        const { t, classes } = this.props;

        return (
            <Box className={classes.form}>
                <Box>
                    <Typography component="h1" variant="h5"><ConfiguredCustomText fallback={t('verification-link-sent-title')} section={'verify'} label={'title'}/></Typography>
                </Box>
                <Box style={{ flexGrow: 1 }}>
                    <Card style={{ marginTop: 20, padding: 10, textAlign: 'center' }}>
                        <Box><Typography component="h5" variant="subtitle2" color="textSecondary"><ConfiguredCustomText fallback={t('verification-link-sent')} section={'verify'} label={'primary'}/></Typography></Box>
                        <Box><Typography component="h5" variant="subtitle2" color="textSecondary"><ConfiguredCustomText fallback={t('follow-verification-instructions')} section={'verify'} label={'secondary'}/></Typography></Box>
                    </Card>
                </Box>
                <Box>
                    <Grid container className={classes.spacer}>
                        <Grid item xs></Grid>
                        <Grid item>
                            <Link
                                to="/"
                                component={RouterLink}
                                variant="subtitle2"
                                color="secondary"
                                underline="hover">
                                {t('back-to-login')}
                            </Link>
                        </Grid>
                        <Grid item xs></Grid>
                    </Grid>
                </Box>
            </Box>
        );
    }
}

const TranslatedPasswordRequest = withTranslation('translation')(VerifyMsg);
export default withStyles(useStyles)(TranslatedPasswordRequest);