import { Alert } from '@mui/lab';
import { Box, Button, Card, CardActionArea, CardContent, Grid, Link, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import AuthService from '../../Services/AuthService.js';
import Events from '../../Utility/Events.js';
import history from '../../Utility/RouterHistory';
import { AuthContext } from '../Contexts/AuthContext.js';
import { CustomThemeContext } from '../Contexts/CustomThemeContext.js';
import ConfiguredCustomText from '../Elements/ConfiguredCustomText.js';


const useStyles = (theme) => ({
    form: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        paddingTop: theme.spacing(2),
    },
    card: {
        margin: theme.spacing(2),
    },
    cardItem: {
        marginBottom: theme.spacing(1)
    },
    cardItemButton: {
        width: '100%',
        textAlign: 'left'
    },
    cardItemContent: {
        width: '100%',
        textAlign: 'left'
    },
});

class ChooseClient extends Component {
    handleChangeClient = (e, changeClientCallback, client) => {
        const { t } = this.props;

        Events.dispatch('startLoading');
        changeClientCallback(client)
            .then(res => {
                Events.dispatch('msgSuccess', t('switched-provider'));
                Events.dispatch('doneLoading');
            })
            .catch(err => {
                Events.dispatch('msgError', t('login-failed-invalid'));
                Events.dispatch('doneLoading');
            });
    }

    handleLogout = (e, logoutCallback) => {
        const { t } = this.props;
        logoutCallback()
            .then(res => {
                Events.dispatch('msgSuccess', t('successfull-logged-out'));
            });
    }

    handleCreateAccount = (e, user, configurations) => {
        AuthService
            .inlineVerificationRequest({
                client_number: configurations.main_client_number,
            })
            .then(res => {
                const data = res.data;
                history.push({
                    pathname: '/create/' + data.verification,
                    state: {
                        verified: true,
                        passwordtoken: data.passwordtoken,
                    }
                });
            });
    }

    render() {
        const { classes, t }    = this.props;

        const getContactInfo = (client) => {
            if (typeof client.contact !== 'undefined') {
                return <span><strong>{t('contact')}:</strong> {client.contact.firstname + " " + client.contact.lastname}</span>;
            }
            return '';
        };

        const getContactConnectionInfo = (client) => {
            if (typeof client.contact !== 'undefined') {
                if (typeof client.contact.connection_type_name === 'string') {
                    return <span><strong>{t('access-as')}:</strong> {client.contact.connection_type_name}</span>;
                }
            }
            return '';
        }

        const getClientItem = (client, index) => {
            return <React.Fragment key={"client-item-" + client.client_number + "-" + index}>
                <AuthContext.Consumer>
                    {({ changeClient }) => (
                        <Card
                            variant='outlined'
                            className={classes.cardItem}
                            title={t('choose-provider')}
                        >
                            <CardActionArea className={classes.cardItemButton} onClick={(e) => this.handleChangeClient(e, changeClient, client)}>
                                <CardContent className={classes.cardItemContent}>
                                    <Typography component="p" variant="h6">
                                        {client.client_name}
                                    </Typography>
                                    <Typography component="p" color="secondary">
                                        {getContactInfo(client)}
                                    </Typography>
                                    <Typography component="p" color="secondary">
                                        {getContactConnectionInfo(client)}
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    )}
                </AuthContext.Consumer>
            </React.Fragment>;
        };

        const watchdogMethod = (user, changeTheme) => {
            const { clientNumber } = this.props.match.params;
            
            if (typeof clientNumber !== 'undefined') {
                changeTheme(clientNumber)
                    .then(history.push('/'));
            }

            if (!user) {
                history.push('/');
            }
        }

        const getRegistrationText = (pubConfigurations) => {
            let parts = [];
            parts.push(t('do-you-want-to-register-for-the-portal-of'));
            parts.push(pubConfigurations.client_name+'?');
            return parts.join(" ");
        }

        const hasCurrentClient = (assocs, configs) => {
            return assocs.some(item => item.main_client_number === configs.main_client_number);
        }

        const isClientPreset = (configs) => {
            if (typeof configs.main_client_number !== 'undefined') {
                if (configs.main_client_number) {
                    return true;
                }
            }
            return false;
        }

        const isRegistrationActive = (configs) => {
            if (typeof configs.registration !== 'undefined') {
                if (configs.registration) {
                    return true;
                }
            }
            return false;
        }

        return (
            <CustomThemeContext.Consumer>
                {({ changeTheme }) => (
                    <AuthContext.Consumer>
                        {({ user, logout, associations, currentPublicClientConfigurations }) => (
                            <Box className={classes.form}>
                                {watchdogMethod(user, changeTheme)}
                                <Box marginBottom={2}>
                                    <Typography component="h1" variant="h5"><ConfiguredCustomText fallback={t('provider')} section={'chooseclient'} label={'title'}/></Typography>

                                    {isClientPreset(currentPublicClientConfigurations) && isRegistrationActive(currentPublicClientConfigurations) && !hasCurrentClient(associations, currentPublicClientConfigurations) && <React.Fragment>
                                        <Alert severity="warning" sx={{ mt: 1 }} icon={false}>
                                            <Typography component="h5" variant="subtitle2" color="textSecondary" textAlign={'center'}><ConfiguredCustomText fallback={getRegistrationText(currentPublicClientConfigurations)} section={'chooseclient'} label={'creation'}/></Typography>
                                            <Box sx={{ textAlign: 'center', mt: 1 }}>
                                                <Button
                                                    onClick={(e) => this.handleCreateAccount(e, user, currentPublicClientConfigurations)}
                                                    variant="contained"
                                                    color="primary"
                                                >
                                                    {t('register')}
                                                </Button>
                                            </Box>
                                        </Alert>
                                        <Box sx={{ mt: 1, mb: 1 }}>
                                            <strong>{t('or')}...</strong>
                                        </Box>
                                    </React.Fragment>}

                                    <Typography component="h5" variant="subtitle2" color="textSecondary"><ConfiguredCustomText fallback={t('text-subhead-choose-provider')} section={'chooseclient'} label={'primary'}/></Typography>
                                    <Typography component="h5" variant="subtitle2" color="textSecondary"><ConfiguredCustomText section={'chooseclient'} label={'secondary'}/></Typography>
                                </Box>
                                <Box style={{ flexGrow: 1, overflowY: 'auto' }}>
                                    {associations.map((item, index) => getClientItem(item, index))}
                                </Box>
                                <Box>
                                    <Grid container>
                                        <Grid item xs></Grid>
                                        <Grid item>
                                            <Link
                                                to="/"
                                                component={RouterLink}
                                                variant="subtitle2"
                                                onClick={(e) => this.handleLogout(e, logout)}
                                                color="secondary"
                                                underline="hover">
                                                {t('logout')}
                                            </Link>
                                        </Grid>
                                        <Grid item xs></Grid>
                                    </Grid>
                                </Box>
                            </Box>
                        )}
                    </AuthContext.Consumer>
                )}
            </CustomThemeContext.Consumer>
        );
    }
}

const  TranslatedComponent = withTranslation('translation')(ChooseClient);
export default withStyles(useStyles)(TranslatedComponent);