import withStyles from '@mui/styles/withStyles';
import { Box } from '@mui/material';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Typography } from '@mui/material';

const useStyles = (theme) => ({
    wrapper: {
        overflow: 'hidden',
        display: 'block',
    },
    content: {

    },
    showMore: {
        height: theme.spacing(1),
        fontSize: '0.75em',
        opacity: 0.5,
        '&:hover': {
            opacity: 1,
        }
    },
    list: {
        listStylePosition: 'outside',
        paddingInlineStart: theme.spacing(3),
    }
});


class ExpandText extends Component {
    constructor(props) {
        super(props);
        
        this.state={
            expanded: this.props.expanded,
            expandable: false,
        };

        this.wrapper = React.createRef();
        this.content = React.createRef();
    }

    componentDidMount() {
        if (this.wrapper && this.wrapper.current) {
            if (this.content && this.content.current) {
                const wrapperHeight = this.wrapper.current.offsetHeight;
                const contentHeight = this.content.current.offsetHeight;

                this.setState({
                    expandable: (contentHeight > wrapperHeight)
                });
            }
        }
    }
    
    handleOnToggle = (e) => {
        this.setState({
            expanded: !this.state.expanded,
        });
    }
    

    render() {
        const { expanded, expandable } = this.state;
        const { t, classes, theme, lines, child, listComponent, minHeight } = this.props;

        const hasChildMethod = () => {
            if (typeof child === 'function') {
                return true;
            }
            return false;
        }

        const getExpandableProps = () => {
            let props = {
                title: lines.join("\n")
            };
            if (expandable) {
                props.component = 'a';
                props.onClick = this.handleOnToggle;
                props.sx = {
                    '&:hover': {
                        cursor: 'pointer',
                    }
                };
            }
            return props;
        }

        return (
            <Box {...getExpandableProps()}>
                <Box 
                    className={classes.wrapper} 
                    sx={{ 
                        maxHeight: (expanded ? null : theme.spacing(minHeight)),
                        minHeight: theme.spacing(minHeight) 
                    }} 
                    ref={this.wrapper}
                >
                    <Box className={classes.content} ref={this.content}>
                        <ul className={classes.list}>
                            {hasChildMethod() && lines.map((l, index) => {
                                return <Box component={listComponent} key={'exptext-line'+index}>
                                    {child(l)}
                                </Box>;
                            })}
                        </ul>
                    </Box>
                </Box>
                {expanded === false && <Box className={classes.showMore}>
                    <Typography variant='p' color={'secondary'}>{expandable ? <span title={(t('show-more') + '...')}>{(t('show-more') + '...')}</span> : <span>&nbsp;</span>}</Typography>
                </Box>}
            </Box>
        );
    }
}

ExpandText.defaultProps={
    expanded: false,
    lines: [],
    child: null,
    listComponent: 'li',
    minHeight: 8,
}

const  TranslatedComponent = withTranslation('translation')(ExpandText);
export default withStyles(useStyles, { withTheme: true })(TranslatedComponent);