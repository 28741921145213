import { Edit as EditIcon, Save as SaveIcon } from '@mui/icons-material';
import { Alert, Box, Paper } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import QuestionairesService from '../../Services/QuestionairesService';
import Events from '../../Utility/Events';
import CustomFab from '../Elements/CustomFab';
import Customfield from '../Elements/Customfield';
import MainContent from '../Elements/MainContent';


const useStyles = (theme) => ({
    paperStyle: {
        margin: theme.spacing(5),
        width: 'calc(100% - ' + theme.spacing(10) + ') !important',
        [theme.breakpoints.down('sm')]: {
            margin: theme.spacing(2),
            width: 'calc(100% - ' + theme.spacing(4) + ') !important',
            padding: theme.spacing(3)
        }
    },
    editIcon: {
        marginRight: theme.spacing(1),
        fontSize: '1rem'
    }
});

const LOCKED_ANAMNESIS = 2;

const extractData = (fields) => {
    let data = {};
    if (typeof fields.customfield_values !== 'undefined') {
        (fields.customfield_values).forEach(i => {
            if (typeof i.customfield.type !== 'undefined' && i.customfield.type === 'uploadbox') {
                //uploadbox, ignore values
            } else {
                data[i.id] = i.value;
            }
        })
    }

    return data;
}

class QuestionairesData extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            editMode: false,
            fields: {},
            data: {},
            errors: {},
        };
    }

    componentDidMount() {
        const { id } = this.props.match.params;

        QuestionairesService
            .get(id)
            .then(result => {
                const fields = result;
                const data   = extractData(fields);
                this.setState({
                    fields: fields,
                    data: data,
                });
            });
    }

    handleDataChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        this.handleDataChangeGeneric(name, value);
    }

    handleDataChangeBoolean = (e) => {
        const name = e.target.name;
        const value = e.target.checked;
        
        if (name.indexOf('.') !== -1) {
            const [mainname, subname] = name.split('.');
            const { data } = this.state;
            this.handleDataChangeGeneric(mainname, {...data[mainname], [subname]: value});
        } else {
            this.handleDataChangeGeneric(name, value);
        }
    }

    handleDataChangeGeneric = (name, value) => {
        const { data } = this.state;
        let { errors } = this.state;

        if (typeof errors[name] !== 'undefined') {
            delete errors[name];
        }

        this.setState({
            data: {...data, [name]: value},
            errors: errors,
        });
    }

    handleToggleEditMode = (e) => {
        this.setState({
            editMode: !this.state.editMode
        });
    }

    handleSubmit = () => {
        const { id } = this.props.match.params;
        const { t } = this.props;
        
        QuestionairesService
            .store({anamnesis_id: id, values: this.state.data})
            .then(response => {
                this.setState({
                    editMode: false
                });
                Events.dispatch('msgSuccess', t('save-successfull'));
            })
            .catch(err => {
                this.setState({
                    errors: err.response.data.errors ?? {},
                });
                Events.dispatch('doneLoading');
                Events.dispatch('msgError', t('save-failed'));
            });
    }

    render() {
        const { t, classes } = this.props;
        const { fields, data, editMode, errors } = this.state;

        const editBtn = <CustomFab
            onClick={this.handleToggleEditMode}
        >
            <EditIcon className={classes.editIcon} />
            {t('edit')}
        </CustomFab>;

        const saveBtn = <CustomFab
            onClick={this.handleSubmit}
        >
            <SaveIcon className={classes.editIcon} />
            {t('save')}
        </CustomFab>;

        const getCustomFields = () => {
            return fields.customfield_values ?? [];
        }

        const isLocked = () => {
            return ((fields.status ?? false) && (fields.status & LOCKED_ANAMNESIS)) ? true : false;
        };

        return (
            <MainContent
                title={fields.title ?? ''}
                textPrimary={''}
                actionComponent={
                    <React.Fragment>
                        {!isLocked() && <Box flexShrink={1} alignSelf="center">
                            { editMode ? saveBtn : editBtn }
                        </Box>}
                    </React.Fragment>
                }
                MainComponent={Paper}
                MainComponentClassName={classes.paperStyle}
            >
                {isLocked() && <Box marginBottom={2}><Alert severity="error">
                    {t('questionaire-already-filled')}
                </Alert></Box>}
                <Box>
                    {getCustomFields().map((item, index) => {
                        return <Customfield
                            key={"qcf-"+item.id??index}
                            disabled={!editMode} 
                            item={item}
                            data={data}
                            errors={errors}
                            onChange={this.handleDataChange}
                            onChangeBoolean={this.handleDataChangeBoolean}
                        />;
                    })}
                </Box>
            </MainContent>
        );
    }
}

const  TranslatedComponent = withTranslation('translation')(QuestionairesData);
export default withStyles(useStyles)(TranslatedComponent);