import RestAppService from "./RestAppService";

class QuestionairesService {
    list(params = {}) {
        return RestAppService
            .get("/form-anamnesis/getList", params);
    }

    get(anamnesis_id) {
        return RestAppService
            .get("/form-anamnesis/get", { anamnesis_id: anamnesis_id });
    }

    store(data = {}) {
        return RestAppService
            .put("/form-anamnesis/update", data);
    }
}

export default new QuestionairesService();