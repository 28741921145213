import { Close, PauseCircleOutline, PlayCircleOutline } from '@mui/icons-material';
import { Box, Button, Card, CardContent, CardHeader, CardMedia, Chip, Grid, LinearProgress, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import SophyappService from '../../Services/SophyappService';
import CachingHelper from '../../Utility/CachingHelper';
import Events from '../../Utility/Events';
import CustomFab from '../Elements/CustomFab';
import ExpandText from '../Elements/ExpandText';
import MainContent from '../Elements/MainContent';
import SophyappProgramFeedback from '../Elements/SophyappProgramFeedback';

const FALLBACK_IMG_PATH = process.env.PUBLIC_URL + '/images/sophyapp.svg';

const useStyles = (theme) => ({
    playBtn: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-'+theme.spacing(5)+', -'+theme.spacing(5)+')',
        height: theme.spacing(10),
        width: theme.spacing(10),
    },
    mediaContainer: {
        '&:hover': {
            cursor: 'pointer',
        }
    },
    playBtnIcon: {
        height: theme.spacing(10),
        width: theme.spacing(10),
        color: theme.palette.primary.main,
    },
    cardItem: {
        margin: theme.spacing(2),
        [theme.breakpoints.down('md')]: {
            marginLeft: 0,
            marginRight: 0,
        }
    },
    playerSize: {
        height: 300,
    },
    playerControls: {
        position: 'absolute',
        bottom: 0,
        zIndex: 1,
        width: '100%',
    }
});

class SophyappProgram extends Component {
    constructor(props) {
        super(props);
        
        const { id } = this.props.match.params;

        this.state = {
            data: CachingHelper.get('program_'+id) || {},
            sources: CachingHelper.get('sources_'+id) || {},
            playing_id: null,
            paused: false,
            feedback: false,
            locked: false,
        };

        this.playingVideoRef = React.createRef();
    }

    componentDidMount() {
        const { id } = this.props.match.params;
        if (id) {
            Events.dispatch('startLoading');
            SophyappService
                .get(id)
                .then(response => {
                    const body = response.data;
                    
                    this.setState({
                        data: body,
                    }, () => {
                        CachingHelper.set(('program_'+id), body);
                    });

                    if (typeof body.exercises !== 'undefined') {
                        const source_ids = body.exercises.map(i => i.source_id ?? null);

                        SophyappService
                            .sources({
                                ids: source_ids,
                            })
                            .then(sourceResponse => {
                                const sourceBody = sourceResponse.data;
                                let sources = {};
                                sourceBody.forEach(i => {
                                    sources[i.id] = i
                                });

                                this.setState({
                                    sources: sources,
                                }, () => {
                                    CachingHelper.set(('sources_'+id), sources);
                                });
                                Events.dispatch('doneLoading');
                            });
                    }

                });
        }
    }

    lockProgram = (e) => {
        this.setState({
            locked: true,
        });
    }

    closeProgramFeedback = (e) => {
        this.setState({
            feedback: false
        });
    }

    openProgramFeedback = (e) => {
        this.setState({
            feedback: true
        });
    }

    handlePlayVideo = (e) => {
        const { sources } = this.state;
        if (typeof e.source_id !== 'undefined') {
            if (typeof sources[e.source_id] !== 'undefined') {
                const src = sources[e.source_id];

                this.setState({
                    playing_id: e.source_id,
                    paused: false,
                });
            }
        }
    }

    handlePauseState = (e) =>  {
        this.setState({
            paused: true,
        });
    }

    handlePlayState = (e) => {
        this.setState({
            paused: false,
        });
    }

    handlePauseVideo = (e) => {
        if (this.playingVideoRef && this.playingVideoRef.current) {
            if (this.playingVideoRef.current.paused) {
                this.playingVideoRef.current.play();
            } else {
                this.playingVideoRef.current.pause();
            }
        }
    }

    handleStopVideo = (e) => {
        this.setState({
            playing_id: null,
            paused: false,
        });
    }

    handleFallbackImage = (e) => {
        e.target.onError = null;
        e.target.src = FALLBACK_IMG_PATH;
        e.target.style = 'opacity: 0.15; object-fit: scale-down;';
    }

    render() {
        const { t, classes } = this.props;
        const { data, sources, playing_id, paused, feedback, locked } = this.state;

        const getThumbnailSrc = (e) => {
            if (typeof e.source_id !== 'undefined') {
                if (typeof sources[e.source_id] !== 'undefined') {
                    const src = sources[e.source_id];
                    return process.env.REACT_APP_SOPHYAPP_S3_STORAGE_THUMBNAILS + src.thumbnail;
                }
            }
            return FALLBACK_IMG_PATH;
        }

        const isFallbackImage = (e) => {
            return getThumbnailSrc(e) === FALLBACK_IMG_PATH;
        }

        const getVideoSrc = (e) => {
            if (typeof e.source_id !== 'undefined') {
                if (typeof sources[e.source_id] !== 'undefined') {
                    const src = sources[e.source_id];
                    return process.env.REACT_APP_SOPHYAPP_S3_STORAGE_VIDEOS + src.video;
                }
            }
            return undefined;
        }

        const isPlaying = (e) => {
            if (playing_id) {
                if (typeof e.source_id !== 'undefined') {
                    if (playing_id === e.source_id) {
                        return true;
                    }
                }
            }
            return false;
        }

        const getExerciseInstructions = (e) => {
            let parts = [];
            if (typeof e.source_id !== 'undefined') {
                if (typeof sources[e.source_id] !== 'undefined') {
                    const src = sources[e.source_id];
                    const toe = src.type_of_exercise ?? 'seconds';

                    if (typeof e.repetitions !== 'undefined') {
                        if (toe === 'seconds') {
                            parts.push(e.repetitions + "s " + t('execution'));
                        } else {
                            parts.push(e.repetitions + " " + t('repetitions'));
                        }
                    }

                    if (typeof e.break !== 'undefined') {
                        parts.push(e.break + "s " + t('break'));
                    }

                    if (typeof e.series !== 'undefined') {
                        parts.push(e.series + " " + t('series'));
                    }
                }
            }
            return <Typography variant={'h6'}>{parts.join(', ')}</Typography>;
        }

        const getExerciseSteps = (e) => {
            if (typeof e.source_id !== 'undefined') {
                if (typeof sources[e.source_id] !== 'undefined') {
                    const src = sources[e.source_id];

                    if (typeof src.source_translations !== 'undefined' && typeof src.source_translations[0] !== 'undefined') {
                        const translation = src.source_translations[0];
                        if (typeof translation.steps === 'string') {
                            let split = replaceIrregularChars(translation.steps).split("\n");
                            
                            return <ExpandText lines={split} child={(text) => <Typography variant={'p'} color={'secondary'}>{text}</Typography>}/>;
                        }
                    }
                }
            }
            return "";
        }

        const getExerciseGoal = (e) => {
            if (typeof e.source_id !== 'undefined') {
                if (typeof sources[e.source_id] !== 'undefined') {
                    const src = sources[e.source_id];
                    if (typeof src.goal !== 'undefined') {
                        return t('goal') + ': ' + src.goal ?? '';
                    }
                }
            }
            return undefined;
        }

        const replaceIrregularChars = (str) => {
            let newStr = str;
            newStr = newStr.replaceAll("<br/>", "\n");
            newStr = newStr.replaceAll("<br />", "\n");
            newStr = newStr.replaceAll("<li>", "\n");
            return newStr;
        }

        const listExercises = () => {
            if (typeof data.exercises !== 'undefined') {
                if (Array.isArray(data.exercises)) {
                    return (data.exercises).map((e, i) => {
                        return <React.Fragment key={"exercise-item-" + i}>
                            <Grid item xs={12} md={6}>
                                <Card className={classes.cardItem}>
                                    <CardHeader 
                                        title={(i+1) + ". " + (replaceIrregularChars(e.name ?? ''))}
                                        subheader={getExerciseGoal(e)}
                                    />
                                    <Box sx={{ position: 'relative' }} className={classes.mediaContainer}>
                                        {isPlaying(e)
                                        ?<React.Fragment>
                                            <CardMedia
                                                component="video"
                                                controls={true}
                                                className={classes.playerSize}
                                                src={getVideoSrc(e)}
                                                alt="video"
                                                autoPlay={true}
                                                loop={true}
                                                ref={this.playingVideoRef}
                                                onPause={this.handlePauseState}
                                                onPlay={this.handlePlayState}
                                            />
                                            {paused && <Box className={classes.playBtn} onClick={ () => this.handlePauseVideo(e) }><PauseCircleOutline className={classes.playBtnIcon}/></Box>}
                                        </React.Fragment>
                                        :<React.Fragment>
                                            <CardMedia
                                                component="img"
                                                className={classes.playerSize}
                                                image={getThumbnailSrc(e)}
                                                onError={this.handleFallbackImage}
                                                style={ isFallbackImage(e) ? { opacity: 0.15, objectFit: 'scale-down' } : {}}
                                                alt="thumb"
                                                onClick={ () => this.handlePlayVideo(e) }
                                            />
                                            <Box className={classes.playBtn} onClick={ () => this.handlePlayVideo(e) }><PlayCircleOutline className={classes.playBtnIcon}/></Box>
                                        </React.Fragment>}
                                    </Box>
                                    <CardContent>
                                        <Box>{getExerciseInstructions(e)}</Box>
                                        <Box>
                                            <Typography variant={'p'}>{getExerciseSteps(e)}</Typography>
                                        </Box>
                                    </CardContent>
                                </Card>
                            </Grid>
                        </React.Fragment>;
                    })
                }
            }
            return null;
        }

        const closeButton = () => {
            return <Box flexShrink={1} alignSelf="center">
                <CustomFab
                    color="secondary"
                    component={Link}
                    to="/programs"
                >
                    <Close className={classes.extendedIcon} />
                    {t('back')}
                </CustomFab>
            </Box>;
        }

        const hasTrainedToday = () => {
            if (locked) return true;
            if (typeof data.has_trained_today !== 'undefined') {
                if (data.has_trained_today) {
                    return true;
                }
            }
            return false;
        }

        const programStillActive = () => {
            if (typeof data.is_active !== 'undefined') {
                if (data.is_active) {
                    return true;
                }
            }
            return false;
        }

        const getTitle = () => {
            return <span><span>{replaceIrregularChars(data.name ?? '')}</span> <span>{programStillActive() ? <Chip color="success"  variant="outlined" label={t('active')}/> : <Chip color="warning" variant="outlined" label={t('done')}/>}</span></span>;
        }

        return (
            <MainContent
                title={getTitle()}
                textPrimary={replaceIrregularChars(data.description ?? '')}
                actionComponent={closeButton()}
            >
                <SophyappProgramFeedback show={feedback} onClose={this.closeProgramFeedback} onSubmit={this.lockProgram} entity={data}/>
                <Box>
                    <Box sx={{ marginBottom: 2 }}>
                        <Box textAlign={'center'}>
                            <Typography variant={'h6'}>
                                {data.progress ?? 0}% {t('completed')}
                            </Typography>
                        </Box>
                        <LinearProgress variant="determinate" value={data.progress ?? 0}/>
                    </Box>
                    <Grid container>
                        {listExercises()}
                    </Grid>
                    {programStillActive() 
                    ?<Box sx={{ marginTop: 2 }} textAlign={'center'}>
                        {(hasTrainedToday() === false) 
                        ? <Button variant="contained" size="large" onClick={this.openProgramFeedback}>{t('mark as done')}</Button>
                        : <Button variant="contained" size="large" disabled={true}>{t('program-done-for-today')}</Button>}
                    </Box>
                    :<Box sx={{ marginTop: 2 }} textAlign={'center'}>
                        <Button variant="contained" size="large" disabled={true}>{t('program-done')}</Button>
                    </Box>}
                </Box>
            </MainContent>
        );
    }
}

const  TranslatedComponent = withTranslation('translation')(SophyappProgram);
export default withStyles(useStyles)(TranslatedComponent);