import React, { Component } from 'react';
import { Typography, TextField, Button, Grid, Link, Box, CircularProgress, Tabs, Tab } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import { Link as RouterLink } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import Events from '../../Utility/Events';
import AuthService from '../../Services/AuthService';

import { CustomThemeContext } from '../Contexts/CustomThemeContext';

import history from '../../Utility/RouterHistory';
import ConfiguredCustomText from '../Elements/ConfiguredCustomText';
import { AuthContext } from '../Contexts/AuthContext';
import { PersonAdd } from '@mui/icons-material';
import { Login } from '@mui/icons-material';

const useStyles = (theme) => ({
    form: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        paddingTop: theme.spacing(2),
    },
    spacer: {
        marginTop: theme.spacing(1),
    },
    spaceActions: {
        marginTop: theme.spacing(4),
    }
});


class Register extends Component {
    constructor(props) {
        super(props);

        this.state = {
            mounted: false,
            username: '',
            usernameError: undefined,
            joincode: null,
        };
    }

    componentDidMount() {
        const { clientNumber, joincode } = this.props.match.params;

        if (clientNumber !== undefined) {
            const { switchTheme } = this.context;
            switchTheme(clientNumber)
                .then(e => {
                    this.setState({
                        mounted: true,
                    });
                    const { currentPublicClientConfigurations } = this.context;
                    if (!(currentPublicClientConfigurations.registration ?? false)) {
                        history.push('/');
                    }
                });
            if (joincode !== undefined) {
                this.setState({
                    joincode: joincode
                });
            }
        } else {
            this.setState({
                mounted: true,
            });
            const { currentPublicClientConfigurations } = this.context;
            if (!(currentPublicClientConfigurations.registration ?? false)) {
                history.push('/');
            }
        }
    }

    handleOnFocus = (e) => {
        const target = e.target;
        if (target) {
            setTimeout(() => {
                target.scrollIntoView({ block: 'start', behavior: 'smooth' });
            }, 250);
        }
    }

    
    
    handleUsernameChange = (e) => {
        this.setState({
            username: e.target.value.trim()
        });
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const { t } = this.props;
        const { username, joincode} = this.state;

        this.setState({
            usernameError: undefined,
        });

        localStorage.setItem('last-username', username);

        let params = {
            username: username,
        };
        if (joincode) {
            params.joincode = joincode;
        }

        AuthService
            .verificationRequest(params)
            .then(response => {
                if (response.status === 208) {
                    history.push('/');
                    Events.dispatch('msgInfo', t('account-already-exists'));
                } else {
                    history.push('/verify');
                    Events.dispatch('msgSuccess', t('verification-request-successfull'));
                }
            })
            .catch(err => {
                this.setState({
                    usernameError: true,
                });
                Events.dispatch('msgError', t('verification-request-failed'));
            });
    }

    render() {
        const { t, classes } = this.props;
        const { mounted, username, usernameError } = this.state;

        return (
            <React.Fragment>
                {!mounted && <Box textAlign='center' marginTop={15}><CircularProgress size={150}/></Box>}
                {mounted && <form className={classes.form} noValidate onSubmit={this.handleSubmit}>
                        <Box marginBottom={2}>
                            <Tabs value={'register'}>
                                <Tab value={'login'} sx={{ padding: 0, flexBasis: '50%' }} label={
                                    <Link
                                        to="/"
                                        component={RouterLink}
                                        variant="subtitle2"
                                        color="secondary"
                                        underline="hover">
                                        <Button startIcon={<Login />} color={'secondary'}>
                                            {t('login')}
                                        </Button>
                                    </Link>
                                }/>
                                <Tab value={'register'} sx={{ padding: 0, flexBasis: '50%' }} label={
                                    <Button startIcon={<PersonAdd />} color={'primary'}>
                                        {t('register')}
                                    </Button>}/>
                            </Tabs>
                        </Box>
                        <Box style={{ flexGrow: 1 }}>
                            <TextField
                                margin="normal"
                                required
                                fullWidth
                                id="username"
                                name="username"
                                label={t('email')}
                                autoComplete="username"
                                onFocus={this.handleOnFocus}
                                autoFocus
                                error={usernameError}
                                className={usernameError ? 'shake' : ''}
                                value={username}
                                onChange={this.handleUsernameChange}
                            />
                        </Box>
                        <CustomThemeContext.Consumer>
                            {({ isMobileKeyboardOpen }) => (
                                <Box className={(isMobileKeyboardOpen && classes.spaceActions)}>
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        color="primary"
                                        disabled={username === '' ? true : false}
                                        className={classes.submit}
                                    >
                                        {t('next')}
                                    </Button>
                                    <Grid container className={classes.spacer}>
                                        <Grid item xs></Grid>
                                        <Grid item>
                                            <Link
                                                to="/"
                                                component={RouterLink}
                                                variant="subtitle2"
                                                color="secondary"
                                                underline="hover">
                                                {t('cancel')}
                                            </Link>
                                        </Grid>
                                        <Grid item xs></Grid>
                                    </Grid>
                                </Box>
                            )}
                        </CustomThemeContext.Consumer>
                    </form>}
            </React.Fragment>
        );
    }
}
Register.contextType=AuthContext;

const TranslatedPasswordRequest = withTranslation('translation')(Register);
export default withStyles(useStyles)(TranslatedPasswordRequest);