import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Grid,
    Typography,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { Alert } from '@mui/material';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import { ExpandMore as ExpandMoreIcon, NotificationsActive as NotificationsActiveIcon, EventNote as EventNoteIcon } from '@mui/icons-material';
import { formatDate, dateStrToDate, formatDateWithDay, formatTime } from '../../../Utility/Formatter';
import { red } from '@mui/material/colors';
import AppointmentService from '../../../Services/AppointmentService';
import { YmdHiFormat } from '../../../Utility/BookingFormatter';
import ConfiguredCustomText from '../../Elements/ConfiguredCustomText';
import { AuthContext } from '../../Contexts/AuthContext.js';

const useStyles = (theme) => ({
    gallery: {
        marginTop: theme.spacing(2),
        '& .MuiGrid-item': {
            marginBottom: theme.spacing(2),
        }
    },
    galleryplaceholder: {
        textAlign: 'center',
        marginTop: theme.spacing(2)
    },
    cancelBtn: {
        color: theme.palette.getContrastText(red[500]),
        backgroundColor: red[500],
        '&:hover': {
            backgroundColor: red[700],
        },
    }
});

class AppointmentList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            expanded: [0]
        };
    }

    handleAccordionChange = (index) => {
        let { expanded } = this.state;

        if (expanded.includes(index)) {
            expanded = expanded.filter(i => i !== index);
        } else {
            expanded.push(index);
        }

        this.setState({
            expanded: expanded
        });
    }

    handleIcsDownload = (e, item) => {
        if (typeof item.id !== 'undefined') {
            const { t } = this.props;
            let filename = t('appointment') + '_' + YmdHiFormat(item.appointment.begin).replace(" ", "_");
            AppointmentService.ics({ id: item.id }, filename);
        }
    }

    render() {
        const { t, classes, items, onCancelClick }   = this.props;
        const { expanded } = this.state;

        const hasArticle = (item) => {
            if (item) {
                if (typeof item.article !== 'undefined' &&  item.article && typeof item.article.id !== 'undefined') {
                    return true;
                }
            }
            return false;
        }

        const checkCancellation = (item) => {
            if (typeof item.appointment !== 'undefined' && typeof item.appointment.cancellation_till !== 'undefined') {
                if (formatDate(dateStrToDate(item.appointment.cancellation_till)) > formatDate(new Date())) {
                    return true;
                }
            }
            return false;
        }

        const showDuration = (configs) => {
            if (typeof configs !== 'undefined') {
                if (typeof configs['online_booking_article_hide_duration'] !== 'undefined') {
                    if (configs['online_booking_article_hide_duration']) {
                        return false;
                    }
                }
            }
            return true;
        }

        const hasTreatment = (item) => {
            if (typeof item.treatment !== 'undefined') {
                return item.treatment;
            }
            return false;
        }

        const showTreatments = (conf) => {
            return true;
        }

        const hasLocation = (item) => {
            if (item) {
                if (typeof item.location !== 'undefined' && item.location && typeof item.location.id !== 'undefined') {
                    return true;
                }
            }
            return false;
        }

        const showLocation = () => {
            return true;
        }

        const listing = () => {
            return items.map((item, index) => {
                return (
                    <Accordion key={"appointments-"+(item.id??index)} expanded={expanded.includes(index)} onChange={() => this.handleAccordionChange(index)}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                        >
                            <Grid container>
                                <Grid item xs={12} md={4}>
                                    <Typography component="p">
                                        <strong style={{ textTransform: 'uppercase' }}>{formatDateWithDay(item.appointment.begin ?? '').replace(',', '')}</strong>
                                    </Typography>
                                    <Typography component="p" color="secondary">
                                        {formatTime(item.appointment.begin ?? '')} - {formatTime(item.appointment.end ?? '')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={11} md={7} style={{ alignSelf: 'center' }}>
                                    {hasArticle(item) && <Typography component="p" color="secondary" title={t('article')}>
                                        <strong>{<ConfiguredCustomText fallback={t('service')} section="service" label="label"/>}:</strong> {item.article.name}
                                    </Typography>}
                                    <Typography component="p" color="secondary" title={<ConfiguredCustomText fallback={t('therapist')} section="ressource" label="label"/>}>
                                        <strong>{<ConfiguredCustomText fallback={t('therapist')} section="ressource" label="label"/>}:</strong> {item.ressource ?? ''}
                                    </Typography>
                                </Grid>
                                <Grid item xs={1} style={{ textAlign: 'center', alignSelf: 'center' }}>
                                    {item.has_notification && <Box title={t('has-notifications')}><NotificationsActiveIcon /></Box>}
                                </Grid>
                            </Grid>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container>
                                <Grid item xs={12} md={7}>
                                    {(item.description) && <Typography component="p">
                                        <strong>{t('description')}:</strong> {item.description ?? ''}
                                    </Typography>}
                                    <AuthContext.Consumer>
                                        {({ clientConfigurations }) => (
                                            <React.Fragment>
                                                {hasArticle(item) && showDuration(clientConfigurations) && <Typography component="p">
                                                    <strong>{t('duration')}:</strong> {item.article.length ?? ''}min
                                                </Typography>}
                                                {hasTreatment(item) && showTreatments(clientConfigurations) && <Typography component="p">
                                                    <strong>{t('appointment')}:</strong> {item.treatment_number ?? ''} {t('of')} {item.treatment.number_treatments ?? ''}
                                                </Typography>}
                                                {hasLocation(item) && showLocation(clientConfigurations) && <Typography component="p">
                                                    <strong>{t('location')}:</strong> {item.location.name ?? ''} {item.location.address ? ('(' + item.location.address + ')') : ''}
                                                </Typography>}                                    
                                            </React.Fragment>
                                        )}
                                    </AuthContext.Consumer>
                                </Grid>
                                <Grid item xs={12} md={5}>
                                    <Grid container>
                                        <Grid item xs={12} sm={8} md={7} marginTop={2}>
                                            <Box marginLeft='auto' textAlign='right'>
                                                <Button variant="contained" className={classes.cancelBtn} disabled={!checkCancellation(item)} onClick={(e) => onCancelClick(e, item)} title={(checkCancellation(item) ? t('cancel-appointment') : t('cancel-not-available'))}>{t('cancel-appointment')}</Button>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} sm={4} md={5} marginTop={2}>
                                            <Box marginLeft='auto' textAlign='right'>
                                                <Button variant="contained" onClick={(e) => this.handleIcsDownload(e, item)}>
                                                    <EventNoteIcon />&nbsp;{t('download')}
                                                </Button>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                );
            });
        }
    
        const placeholder = () => {
            return <Box className={classes.gallery}>
                <Box className={classes.galleryplaceholder}>
                    <Alert severity="info">{t('no-booked-appointments')}</Alert>
                </Box>
            </Box>;
        }

        return (
            <Box>
                {items.length
                    ? listing()
                    : placeholder()
                }
            </Box>
        );
    }
}

AppointmentList.defaultProps = {
    items: [],
    onCancelClick: null,
};


const  TranslatedComponent = withTranslation('translation')(AppointmentList);
export default withStyles(useStyles)(TranslatedComponent);