import { Button, FormControl, Input } from '@mui/material';
import { CloudUpload } from '@mui/icons-material';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import DocumentService from '../../../Services/DocumentService';
import Events from '../../../Utility/Events';
import DocumentList from '../../Secured/Lists/DocumentList';

class CustomUploadbox extends Component {
    constructor(props) {
        super(props);

        this.state = {
            list: []
        };
    }

    componentDidMount() {
        this.handleListReload();
    }
    

    handleListReload = () => {
        const { name } = this.props;

        DocumentService
            .listCustomfieldUploads(name)
            .then(res => {
                //load all
                this.setState({
                    list: res
                });
            });
    }

    handleFileUpload = (e) => {
        e.preventDefault();
        const { t } = this.props;
        if (typeof e.target.files[0] !== 'undefined') {
            const { name } = this.props;
            //push upload reference
            DocumentService.upload(e.target.files, {customfield_value_id: name})
                .then(res => {
                    this.handleListReload();
                    Events.dispatch('doneLoading');
                    Events.dispatch('msgSuccess', t('upload-successfull'));
                })
                .catch(err => {
                    Events.dispatch('doneLoading');
                    Events.dispatch('msgError', t('upload-failed'));
                });
        }   
    }
    
    render() {
        const { list } = this.state;
        const { name, label, disabled } = this.props;
        
        return (
            <React.Fragment>
                <FormControl fullWidth component="fieldset" margin='normal'>
                    {!disabled && <Input type="file" inputProps={{ multiple: true, capture: false, accept: "*" }} id={"custom-uploadbox-id-"+name} style={{ display: 'none' }} onChange={this.handleFileUpload}/>}
                    <label component="legend" htmlFor={"custom-uploadbox-id-"+name}>
                        <Button
                            variant="contained"
                            size="medium"
                            color="primary"
                            aria-label="add"
                            disabled={disabled}
                            component="span"
                        >
                            <CloudUpload sx={{ marginRight: 1 }}/>
                            {label}
                        </Button>
                    </label>
                </FormControl>
                <DocumentList items={list}/>
            </React.Fragment>
        );
    }
}

CustomUploadbox.defaultProps = {
    value: null,
    name: null,
    label: '',
    disabled: false,
};

export default withTranslation('translation')(CustomUploadbox);