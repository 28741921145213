import { Box, Divider, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';


const SLOTHEIGHT = 6;

const useStyles = (theme) => ({
    timegrid: {
        position: 'relative',
    },
    timegridContainer: {
        paddingTop: theme.spacing(12),
    },
    slot: {},
    divider: {
        position: 'absolute',
        width: 'calc(100% + ' + theme.spacing(8) + ')',
        maxWidth: 'calc(100vw - ' + theme.spacing(4) + ')',
        transform: 'translate(0, -50%)',
        '&::before': {
            width: 0
        }
    },
    weekdays: {
        position: 'absolute',
        height: '100%',
        width: '100%',
        top: 0,
        // left: theme.spacing(8)
    },
    weekdaysDense: {
        position: 'relative',
        [theme.breakpoints.down('md')]: {
            paddingBottom: theme.spacing(12),
        },
        [theme.breakpoints.down('sm')]: {
            paddingBottom: 0
        }
    }
});

const DAYDURATION = 1440;

class TimeGrid extends Component {
    render() {
        const { t, classes, theme } = this.props;
        const { intervall, subSpanIntervall, mainSpanIntervall, viewType, minTime, maxTime, slotHeight } = this.props;

        const getTimeSlots = () => {
            let slots = [];
            let curTime = minTime;
            while (curTime < maxTime) {
                slots.push(curTime);
                curTime += intervall;
            }
            return slots;
        }

        const intToTime = (timeInt) => {
            let hours = parseInt(Math.floor(timeInt / 60)).toString().padStart(2, "0");
            let min   = parseInt(timeInt - (hours * 60)).toString().padStart(2, "0");

            return hours + ":" + min;
        };

        const getContentSx = () => {
            if (viewType === 'list') {
                return { position: 'relative', display: 'block', height: '100%' };
            } 
            return { display: 'flex', height: '100%' };
        }

        return <Box className={classes.timegrid}>
            {viewType === 'calendar' && <Box className={classes.timegridContainer}>
                {getTimeSlots().map(i => {
                    return <React.Fragment key={'time-slot-'+i}>
                        {i % mainSpanIntervall === 0 
                            ?   <React.Fragment>
                                    <Divider textAlign="left" className={classes.divider}><Typography variant="p" color="primary">{intToTime(i)}</Typography></Divider>
                                </React.Fragment>
                            : i % subSpanIntervall === 0 && <Divider textAlign="left" className={classes.divider}><Typography variant="p" color="secondary">{intToTime(i)}</Typography></Divider>
                        }
                        <Box className={classes.slot} sx={{ height: theme.spacing(slotHeight) }}></Box>
                    </React.Fragment> 
                })}
            </Box>}
            <Box className={(viewType !== 'calendar' ? classes.weekdaysDense : classes.weekdays)}>
                <Box sx={getContentSx()}>
                    {this.props.children}
                </Box>
            </Box>
        </Box>;
    }
}

TimeGrid.defaultProps = {
    date: null,
    viewType: 'grid',
    intervall: 5,
    subSpanIntervall: 30,
    mainSpanIntervall: 60,
    minTime: 0,
    maxTime: DAYDURATION,
    slotHeight: 2,
}


const  TranslatedComponent = withTranslation('translation')(TimeGrid);
export default withStyles(useStyles, { withTheme: true })(TranslatedComponent);