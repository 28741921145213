import { Accordion, AccordionSummary, Box, ButtonBase, Card, Divider, Grid, List, ListItem, ListItemText, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { Edit, Lock } from '@mui/icons-material';
import { Alert } from '@mui/material';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import QuestionairesService from '../../Services/QuestionairesService';
import history from '../../Utility/RouterHistory';
import MainContent from '../Elements/MainContent';
import CachingHelper from '../../Utility/CachingHelper.js';


import { green, red } from '@mui/material/colors';
import { formatDateWithDay } from '../../Utility/Formatter';
import ConfiguredCustomText from '../Elements/ConfiguredCustomText';


const useStyles = (theme) => ({

});

const LOCKED_ANAMNESIS = 2;

const lockColor = red[500];
const editColor = green[500];

class Questionaires extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            list: CachingHelper.get('questionaires') || [],
        };
    }

    componentDidMount() {
        QuestionairesService
            .list()
            .then(result => {
                if (result.length === 1) {
                    history.push('/questionaires/' + result[0].id);
                }
                this.setState({
                    list: result
                }, () => {
                    CachingHelper.set('questionaires', result);
                });
            });
    }

    render() {
        const { t, classes } = this.props;
        const { list } = this.state;

        const isLocked = (item) => {
            return ((item.status ?? false) && (item.status & LOCKED_ANAMNESIS)) ? true : false;
        };

        const getQuestionCountLabel = (item) => {
            const count = item.customfield_values ? item.customfield_values.length : 0; 

            if (count === 1) {
                return "1 " + t('question');
            }
            return count + " " + t('questions');
        }

        return (
            <MainContent
                title={<ConfiguredCustomText fallback={t('questionaires')} section={'questionaires'} label={'title'}/>}
                textPrimary={<ConfiguredCustomText fallback={t('text-head-questionaires')} section={'questionaires'} label={'primary'}/>}
                textSecondary={<ConfiguredCustomText fallback={t('text-subhead-questionaires')} section={'questionaires'} label={'secondary'}/>}
            >
                {list.length > 0 
                        ?<List>
                            {list.map((item, index) => {
                                return <React.Fragment key={"questionaire-"+item.id ?? index}>
                                    <Accordion expanded={false}>
                                        <AccordionSummary>
                                            <ButtonBase
                                                sx={{ width: '100%' }}
                                                component={RouterLink} 
                                                to={"/questionaires/" + item.id}
                                            >
                                                <Grid container>
                                                    <Grid item xs={12} md={5}>
                                                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                            <Box flexShrink={1} marginRight={2}>
                                                                {
                                                                    isLocked(item)
                                                                    ? <Lock style={{ fill: lockColor }}/>
                                                                    : <Edit style={{ fill: editColor }}/>
                                                                }
                                                            </Box>
                                                            <ListItemText
                                                                primary={item.title ?? ''}
                                                                secondary={<span style={{ textTransform: 'uppercase' }}>{formatDateWithDay(item.modified ?? '').replace(',', '')}</span>}
                                                            />
                                                        </Box>
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        <Box sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                                                            <Box flexGrow={1} flexShrink={1} marginLeft={5}>
                                                                <Typography color='secondary' variant='p'>
                                                                    {getQuestionCountLabel(item)}
                                                                </Typography>
                                                            </Box>
                                                        </Box>
                                                    </Grid>
                                                </Grid>
                                            </ButtonBase>  
                                        </AccordionSummary>                                   
                                    </Accordion>
                                </React.Fragment>;
                            })}
                        </List>
                        :<Box className={classes.galleryplaceholder}>
                            <Alert severity="info">{t('no-questionaires-available')}</Alert>
                        </Box>
                    }
            </MainContent>
        );
    }
}

const  TranslatedComponent = withTranslation('translation')(Questionaires);
export default withStyles(useStyles)(TranslatedComponent);