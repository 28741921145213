import { Box } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { Cancel, CheckCircle } from '@mui/icons-material';
import React, { Component } from 'react';

const useStyles = (theme) => ({
    conditions: {
        display: 'inline-block', 
        height: theme.spacing(2),
        verticalAlign: 'middle',
        marginRight: theme.spacing(1)
    }
});

class CheckLabel extends Component {
    render() {
        const { classes, value, label } = this.props;

        const getIcon = (check) => {
            if (check) {
                return <CheckCircle style={{color:"#8bc34a"}} fontSize="small"/>;
            }
            return  <Cancel style={{color:"f44336"}} fontSize="small"/>;
        }   

        return (
            <Box>
                <Box className={classes.conditions}>{getIcon(value)}</Box>
                <Box className={classes.conditions}>{label}</Box>
            </Box>
        );
    }
}

CheckLabel.defaultProps = {
    value: false,
    label: '',
};

export default withStyles(useStyles)(CheckLabel);