import { Box, Button, Grid, Link, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import AuthService from '../../Services/AuthService';
import Events from '../../Utility/Events';
import PasswordRequirements from '../../Utility/PasswordRequirements';
import history from '../../Utility/RouterHistory';
import { CustomThemeContext } from '../Contexts/CustomThemeContext';
import ConfiguredCustomText from '../Elements/ConfiguredCustomText';
import PasswordCreateField from '../Elements/PasswordCreateField';

const useStyles = (theme) => ({
    form: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        paddingTop: theme.spacing(2),
    },
    spacer: {
        marginTop: theme.spacing(1),
    },
    input: {
        width: '100%',
    },
    spaceActions: {
        marginTop: theme.spacing(4),
    }
});

class PasswordChange extends Component {
    constructor(props) {
        super(props);

        this.state = {
            password: '',
            passwordRepeat:'',
        };
    }

    handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        this.setState({
            [name]: value
        });
    }

    handleClickShowPassword = () => {
        this.setState(oldstate => ({
            showPassword: !oldstate.showPassword
        }));
    }

    handleSubmit = (e) => {
        e.preventDefault();
        
        const { t } = this.props;
        const { code } = this.props.match.params;
        const { password, passwordRepeat } = this.state;
        
        if (password === passwordRepeat) {
            Events.dispatch('startLoading');
            AuthService
                .passwordChange(code, password, passwordRepeat)
                .then(repsonse => {
                    history.push('/');
                    Events.dispatch('doneLoading');
                    Events.dispatch('msgSuccess', t('password-change-successfull'));
                })
                .catch(err => {
                    Events.dispatch('doneLoading');
                    Events.dispatch('msgError', t('password-change-failed'));
                });
        } else {
            Events.dispatch('msgError', t('passwords-dont-match'));
        }
    }

    handleOnFocus = (e) => {
        const target = e.target;
        if (target) {
            setTimeout(() => {
                target.scrollIntoView({ block: 'start', behavior: 'smooth' });
            }, 250);
        }
    }


    render() {
        const { t, classes } = this.props;
        const { password, passwordRepeat } = this.state;

        const passwordField = (field) => {
            return <PasswordCreateField field={field} value={this.state[field] ?? ''} onChange={this.handleChange} onFocus={this.handleOnFocus}/>;
        }

        return (
            <form className={classes.form} noValidate onSubmit={this.handleSubmit}>
                <Box>
                    <Typography component="h1" variant="h5"><ConfiguredCustomText fallback={t('change-password')} section={'pwchange'} label={'title'}/></Typography>
                    <Typography component="h5" variant="subtitle2" color="textSecondary"><ConfiguredCustomText section={'pwchange'} label={'primary'}/></Typography>
                    <Typography component="h5" variant="subtitle2" color="textSecondary"><ConfiguredCustomText section={'pwchange'} label={'secondary'}/></Typography>
                </Box>
                <Box style={{ flexGrow: 1 }}>
                    {passwordField('password')}
                    {passwordField('passwordRepeat')}
                </Box>
                <CustomThemeContext.Consumer>
                    {({ isMobileKeyboardOpen }) => (
                        <Box className={(isMobileKeyboardOpen && classes.spaceActions)}>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                disabled={!PasswordRequirements.isValidPassword(password) || password !== passwordRepeat}
                                className={classes.submit}
                            >
                                {t('next')}
                            </Button>
                            <Grid container className={classes.spacer}>
                                <Grid item xs></Grid>
                                <Grid item>
                                    <Link
                                        to="/"
                                        component={RouterLink}
                                        variant="subtitle2"
                                        color="secondary"
                                        underline="hover">
                                        {t('cancel')}
                                    </Link>
                                </Grid>
                                <Grid item xs></Grid>
                            </Grid>
                        </Box>
                    )}
                </CustomThemeContext.Consumer>
            </form>
        );
    }
}

const  TranslatedComponent = withTranslation('translation')(PasswordChange);
export default withStyles(useStyles)(TranslatedComponent);