import { Box, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';


const useStyles = (theme) => ({
    form: {
        width: '100%',
        padding: theme.spacing(4),
        [theme.breakpoints.down('md')]: {
            padding: theme.spacing(2)
        }
    },
    header: {
        display: 'flex',
        maxWidth: '100%',
        alignItems: 'center',
    },
    defaultTypography: {
        maxWidth: 'fit-content',
    },
    scrollDownClass: {
        fontSize: '5em',
    },
    content: {
        position: 'relative',
        maxWidth: '100%',
    }
});

class MainContent extends Component {
    render() {
        const { classes, title, textPrimary, textSecondary, actionComponent, marginBottomHead, MainComponent, MainComponentSx, MainComponentClassName } = this.props;
        return (
            <MainComponent className={classes.form + ' ' + MainComponentClassName} sx={MainComponentSx}>
                <Box className={classes.header} marginBottom={marginBottomHead}>
                    <Box flexGrow={1} flexShrink={1} sx={{ width: '100%', maxWidth: '100%' }}>
                        <Typography component="h1" variant="h5" className={classes.defaultTypography}>
                            {title}
                        </Typography>
                        <Typography component="p" color="secondary" className={classes.defaultTypography}>
                            {textPrimary}
                        </Typography>
                        <Typography component="p" color="secondary" className={classes.defaultTypography}>
                            {textSecondary}
                        </Typography>
                    </Box>
                    {actionComponent}
                </Box>
                <Box className={classes.content}>
                   {this.props.children}
                </Box>
            </MainComponent>
        );
    }
}

MainContent.defaultProps = {
    title: '',
    actionComponent: null,
    textPrimary: '',
    textSecondary: '',
    marginBottomHead: 4,
    MainComponent: Box,
    MainComponentSx: {},
    MainComponentClassName: '',
}

const  TranslatedComponent = withTranslation('translation')(MainContent);
export default withStyles(useStyles)(TranslatedComponent);