import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import LanguageBackend from 'i18next-http-backend';

i18n
	.use(LanguageBackend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: 'de',
        backend: {
            loadPath: process.env.PUBLIC_URL + '/locales/{{lng}}/{{ns}}.json'
        }
    });


export default i18n;