import RestAppService from "./RestAppService";
import fileDownload from 'js-file-download';

class AppointmentService {
    list(params = {}) {
        return RestAppService
            .get("/appointments/getList", params);
    }

    create (data = {}) {
        return RestAppService
                .post("/appointments/create", data);
    }

    cancel (data = {}) {
        return RestAppService
                .post("/appointments/cancel", data);
    }

    ics (data, filename = 'calendar') {
        return RestAppService
            .get("/appointments/ics", data)
            .then(iCalCalendar => {
                fileDownload(iCalCalendar, filename + '.ics');
            });
    }
}

export default new AppointmentService();