import { Box, Button, Card, Paper, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { daysInMonth, modifyDays, YmdFormat } from '../../../Utility/BookingFormatter';
import { formatDate, formatDateWithDay, formatDateWithoutDay } from '../../../Utility/Formatter';
import { BookingContext } from '../../Contexts/BookingContext';

const useStyles = (theme) => ({
    item: {
        padding: 8,
        margin: 4,
        '&.dark': {
            boxShadow: "0px 0px 3px " + theme.palette.primary.main,
        }
    },
    month: {
        position: 'relative',
        height: 'auto',
        width: '100%',
        marginLeft: theme.spacing(0.5),
        marginRight: theme.spacing(0.5),
        marginBottom: theme.spacing(2),
    },
    monthHeader: {
        position: 'sticky',
        top: theme.spacing(8.5),
        zIndex: 10,
        padding: theme.spacing(1),
        paddingTop: theme.spacing(1.25),
        paddingBottom: theme.spacing(1.5),
        paddingLeft: theme.spacing(1.5),
        marginTop: theme.spacing(1.5),
        marginBottom: theme.spacing(1),
        background: theme.palette.primary.main,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        height: theme.spacing(8),
        [theme.breakpoints.down('sm')]: {
            height: 'auto',
        }
    },
    monthHeaderText: {
        color: theme.palette.getContrastText(theme.palette.primary.main),
        whiteSpace: 'normal',
    }
});

const DAYDURATION = 1440;

class Month extends Component {
    render() {
        const { t, classes, theme } = this.props;
        const { dates, startdate, enddate } = this.props;

        const isInFuture = (ymd) => {
            if (formatDate(ymd) >= formatDate(YmdFormat(new Date()))) {
                return true;
            }
            return false;
        }

        const indexToYmd = (index) => {
            return YmdFormat(startdate).substring(0, 8) + index.toString().padStart(2, '0');
        }
        const getDayItem = (handleSetByDate, index) => {
            const key = indexToYmd(index);
            let dayCount = 0;
            if (typeof dates[key] !== 'undefined') {
                dayCount = dates[key].length;
            }

            if (index <= daysInMonth(startdate)) {
                if (isInFuture(key)) {
                    return <Card className={classes.item + " " + theme.palette.mode} variant='elevation' sx={{ opacity: (dayCount == 0) ? 0.5 : 1 }}>
                        <Box marginTop={1} marginBottom={1}><Typography color='secondary' variant='p' whiteSpace={'nowrap'}>{formatDateWithDay(key)}</Typography></Box>
                        <Box marginTop={1} marginBottom={1}>
                            <Typography variant="h4">{dayCount}</Typography>
                            <Typography variant="p" color='secondary'>{(dayCount != 1) ? t('free dates') : t('free date')}</Typography>
                        </Box>
                        <Box marginTop={1} marginBottom={1}>
                            <Button variant='contained' color='primary' onClick={(e) => handleSetByDate(e, key)} title={t('show slots')}>{t('show')}</Button>
                        </Box>
                    </Card>;
                }
            }
        } 

        const showCard = (index) => {
            return index > daysInMonth(startdate) || isInFuture(indexToYmd(index));
        }

        const getAllDays = (handleSetByDate) => {
            return <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                {Array.from(Array(35).keys()).map((index) => {
                    return (showCard(parseInt(index) + 1)) && <Box flex={1} alignSelf={'center'} textAlign={'center'} key={'week-item-'+index} sx={{ flexBasis: '14.28%', minWidth: '125px' }} flexShrink={1}>
                        {getDayItem(handleSetByDate, (parseInt(index) + 1))}
                    </Box>;
                })}
            </Box>;
        }

        return (
            <BookingContext.Consumer>
                {({ handleSetByDate }) => (
                    <Box className={classes.month}>
                        <Paper className={classes.monthHeader}>
                            <Box marginTop={1} position='relative'>
                                <Typography variant="h6" component="p" className={classes.monthHeaderText}>{formatDateWithoutDay(startdate) + ' - ' + formatDateWithoutDay(modifyDays(startdate, daysInMonth(startdate) - 1))}</Typography>
                            </Box>
                        </Paper>
                        {getAllDays(handleSetByDate)}
                    </Box>
                )}
            </BookingContext.Consumer>
        );
    }
}

Month.defaultProps = {
    startdate: null,
    enddate: null,
    dates: {},
}


const  TranslatedComponent = withTranslation('translation')(Month);
export default withStyles(useStyles, { withTheme: true })(TranslatedComponent);