import { Box, CircularProgress } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import AuthService from '../../../Services/AuthService';

const useStyles = (theme) => ({
    content: {
        paddingBottom: theme.spacing(4),
        '& span.highlight': {
            color: theme.palette.primary.main,
            fontWeight: 'bold',
        },
        '& table': {
            minWidth: '100%',
            tableLayout: 'fixed',
            '& th': {
                textAlign: 'left',
            },
            '& td': {
                verticalAlign: 'top',
                '& p': {
                    marginTop: 0,
                    marginBottom: 0,
                },
                '& ul': {
                    marginBlockStart: 0,
                    marginBlockEnd: 0,
                }
            }
        },
        '& table, th, td': {
            padding: theme.spacing(1),
            border: '1px solid ' + theme.palette.secondary.main,
            borderCollapse: 'collapse',
        }
    }
});

class DsgvoContent extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            statement: this.props.statement,
        };
    }

    componentDidMount() {
        if (this.state.statement === null) {
            AuthService
                .privacyStatement()
                .then(res => {
                    this.setState({
                        statement: res.data
                    });
                });
        }
    }

    render() {
        const { classes } = this.props;

        const { statement } = this.state;
        
        const jsxifyName = (propName) => {
            if (propName.indexOf('-') !== -1) {
                let split = propName.split('-');
                split = split.map(i => i[0].toUpperCase() + i.slice(1));
                let str = split.join('');
                return str[0].toLowerCase() + str.slice(1);
            }
            return propName;
        }

        const getTagStyleFromString = (styleStr) => {
            var regex = /([\w-]*)\s*:\s*([^;]*)/g;
            var match, properties={};
            while(match=regex.exec(styleStr)) properties[jsxifyName(match[1])] = match[2].trim();
            return properties;
        };

        const parseBlock = (block) => {
            return block.map((b, index) => {
                if (typeof b === 'string') {
                    return b;
                } else {
                    if (typeof b.tag !== 'undefined') {
                        const TAGTYPE = b.tag;
                        const tagClassName = b.class;
                        const tagStyle = getTagStyleFromString(b.style ?? '');
                        if (typeof b.children !== 'undefined' && (b.children).length > 0) {
                            return <TAGTYPE key={'gdpr-tag-' + index} className={tagClassName} style={tagStyle}>
                                {parseBlock(b.children)}
                            </TAGTYPE>;
                        } else {
                            if (['br'].includes(TAGTYPE)) {
                                return <TAGTYPE key={'gdpr-tag-' + index}/>
                            } else {
                                return <TAGTYPE key={'gdpr-tag-' + index}>&nbsp;</TAGTYPE>;
                            }
                        }
                    }
                }
            });
        }

        const getParsedStatement = () => {
            const { content} = statement;
            return parseBlock(content);
        }

        return (
            <React.Fragment>
                {statement === null && <Box textAlign='center' marginTop={25}><CircularProgress size={200}/></Box>}
                {statement && <Box className={classes.content}>{getParsedStatement()}</Box>}
            </React.Fragment>
        );
    }
}

DsgvoContent.defaultProps = {
    statement: null,
};

const TranslatedPasswordRequest = withTranslation('translation')(DsgvoContent);
export default withStyles(useStyles)(TranslatedPasswordRequest);