import { TextField } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { format, parse } from 'date-fns';
import deLocale from 'date-fns/locale/de';
import React, { useEffect, useRef, useState } from 'react';
import { getDateOnly } from '../../Utility/Formatter';

function AdvancedDatepicker(props) {
    const { onChange, inputProps, value } = props;

    const pickerRef = useRef(null);
    const [date, setDate] = useState(null);

    let timeoutId = null;

    useEffect(() => {
        if (date !== value) {
            setDate(value);
        }
    }, [value, date]);

    const handleChangeValue = (newvalue) => {
        const newDate = getDateOnly(newvalue);
        setDate(newDate);
        onChange(newDate);
    }

    const isLongDateFormat = (dateString) => {
        return dateString.match(/^\d{2}\.\d{2}\.\d{4}$/) ? true : false;
    }

    const isShortDateFormat = (dateString) => {
        return dateString.match(/^\d{2}\.\d{2}\.\d{2}$/) ? true : false;
    }

    const getLongDateFormat = (dateString) => {
        if (isLongDateFormat(dateString)) {
            return dateString;
        }

        let parts = dateString.split('.');
        
        const currentYear = (new Date()).getFullYear();
        let currentCentury = '20' + String(parts[2]);
        let lastCentury = '19' + String(parts[2]);

        let useYear = currentCentury;
        if (parseInt(currentCentury) > currentYear) {
            useYear = lastCentury;
        }

        return `${parts[0]}.${parts[1]}.${useYear}`;
    }


    const isValidDateFormat = (dateString) => {
        return isLongDateFormat(dateString) || isShortDateFormat(dateString);
    }

    const unsetPickerValue = () => {
        setDate('');
    }

    const handleOnChangeDatepicker = (newvalue, keyboardValue) => {
        clearTimeout(timeoutId);
        if (typeof keyboardValue !== 'undefined') {
            if (isValidDateFormat(keyboardValue)) {
                let patchedKeyboardValue = getLongDateFormat(keyboardValue);
                timeoutId = setTimeout(() => {
                    const refDate = date ? new Date(date.getTime()) : new Date();
                    let parsedDate = parse(patchedKeyboardValue, 'dd.MM.yyyy', refDate);
                    if (isADate(parsedDate)) {
                        handleChangeValue(parsedDate);
                    } else {
                        unsetPickerValue();
                    }
                }, 1250);
            }
        } else {
            if (!newvalue && !keyboardValue) {
                timeoutId = setTimeout(() => {
                    handleChangeValue('');
                }, 500);
            } else {
                if (isADate(newvalue)) {
                    handleChangeValue(newvalue);
                }
            }
        }
    }

    const isADate = (maybeDate) => {
        if ( Object.prototype.toString.call(maybeDate) === "[object Date]" ) {
            if ( isNaN( maybeDate.getTime() ) ) {
                return false;
            }
            return true;
        }
        return false;
    };

    const { helperText, error, required } = inputProps;
    return (
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={deLocale}>
            <DatePicker
                ref={pickerRef}
                {...inputProps}
                inputFormat='dd.MM.yyyy'
                mask="__.__.____"
                onChange={(v,kv) => handleOnChangeDatepicker(v, kv)}
                value={date}
                renderInput={(params) => <TextField {...params} helperText={helperText} error={error} required={required}/>}
            />
        </LocalizationProvider>
    );
}

export default AdvancedDatepicker;