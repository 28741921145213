import Events from "../Utility/Events";
import RestAppService from "./RestAppService";

import { getCleanedUrl, getDefaultHeaders, getAuthHeader } from "./RestAppService";

import qq from 'fine-uploader/s3.fine-uploader/s3.fine-uploader.core.js';

class DocumentService {
    list(all = false, nested = false) {
        return RestAppService
            .get("/documents/getList", {
                all: all,
                nested: nested,
            });
    }
    listReceipts(all = false, nested = false) {
        return RestAppService
            .get("/documents/getList", {
                type: 3,
                all: all,
                nested: nested,
            });
    }
    listCustomfieldUploads(customfield_value_id) {
        return RestAppService
            .get("/documents/getListForCustomfield", {
                customfield_value_id: customfield_value_id
            });
    }
    checkUploadSize(files) {
        const items = files.length;
        let filesize = 0;
        for (let index = 0; index < items; index++) {
            const element = files[index];
            filesize += element.size;
        }
        //more than 50MB
        if (filesize >= 52428800) {
            return false;
        }
        return true;
    }
    upload(files, data = {}) {
        let fd = new FormData();
        const items = files.length;
        for (let index = 0; index < items; index++) {
            const element = files[index];
            fd.append('files[]', element);
        }
        (Object.entries(data)).forEach(([key, value]) => {
            fd.append(key, value);
        });
        return RestAppService
            .post("/documents/upload", fd, {
                'Content-Type': 'multipart/form-data'
            });
    }
    download(id) {
        return RestAppService
                .download("/documents/download", {id: id});
    }
    registerUploader (elem, uploadOptions = {}, configurationOptions = {}) {
        if (typeof uploadOptions['storage_bucket'] !== 'string' 
            || uploadOptions['storage_bucket'] === ''
            || typeof uploadOptions['storage_folder'] !== 'string' 
            || uploadOptions['storage_folder'] === ''
            || typeof uploadOptions['storage_subfolder'] !== 'string'
            || uploadOptions['storage_subfolder'] === ''
        ) {
            console.log("Error while init uploader", new Error());
            return null;
        }
        return new qq.s3.FineUploaderBasic({
            multiple: false,
            button: elem,
            validation: {
                allowedExtensions: [ 
                    "jpg", "jpeg", "png", "gif", "mov", "mp4", "3gp", "xls", "xlsx", "doc", "docx", "odt", "pdf", "txt", "csv", "wmv", "zip", "tiff", "tif", "rar" 
                ]
            },
            messages: {
                typeError: 'Ungültiger Dateityp'
            },
            request: {
                endpoint: "https://"+uploadOptions['storage_bucket']+".s3.amazonaws.com/",
                accessKey: process.env.REACT_APP_AWS_FINEUPLOADER_KEY,
            },
            signature: {
                endpoint: getCleanedUrl('documents/signature'),
                version: 4,
                customHeaders: {
                    // ...getDefaultHeaders(),
                    ...getAuthHeader(),
                }
            },
            uploadSuccess: {
                endpoint: getCleanedUrl('documents/success'),
                customHeaders: {
                    ...getDefaultHeaders(),
                    ...getAuthHeader(),
                }
            },
            text: {
                waitingForResponse: "upload..."
            },
            maxConnections: 1,
            objectProperties: {
                key: function (id) {
                    var filename    = this.getName(id);
                    var extension   = filename.split(".").pop();

                    // Create random
                    var current_date = (new Date()).valueOf().toString();
                    var random = Math.random().toString();
                    var filepart = current_date + random;
                    var filename_ready = filepart.replace(".", "") + "." + extension;
                    filename_ready = filename_ready.toLowerCase();
                    return uploadOptions['storage_folder'] + '/' + uploadOptions['storage_subfolder'] + '/' + Date.now() + "_" + filename_ready;
                },
                region: "eu-central-1"
            },
            iframeSupport: {
                localBlankPagePath: "/success.html"
            },
            callbacks: {
                onSubmit: function (id, fileName) {
                    // this.setParams(uploadParams);
                },
                onAllComplete: function(succeeded, failed) {

                }
            },
            ...configurationOptions
        });
    }
}

export default new DocumentService();