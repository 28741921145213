import { Box, Divider, Paper, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import PasswordRequirements from '../../Utility/PasswordRequirements';
import CheckLabel from './CheckLabel';

const useStyles = (theme) => ({
    meter: {
        width: '100%',
        display: 'flex',
        flexWrap: 'no-wrap',
        marginBottom: theme.spacing(1)
    },
    item: {
        flexBasis: '25%',
        margin: '1px',
        marginTop: '2px',
        marginBottom: '2px',
        borderRadius: '3px',
        height: theme.spacing(1),
        background: 'rgba(0,0,0,0.15)',
        '&.dark': {
            background: 'rgba(255,255,255,0.15)'
        }
    }
});

const colorMap = {
    1: '#f44336',
    2: '#ff5722',
    3: '#ffc107',
    4: '#8bc34a',
    5: '#8bc34a',
    6: '#8bc34a',
};

class PasswordStrengthMeter extends Component {
    render() {
        const { t, classes, theme, password, hasFocus } = this.props;

        const getStyles = (itemStrength) => {
            const strength = PasswordRequirements.getCurrentStrength(password);
            if (strength >= itemStrength) {
                return { background: colorMap[strength] };
            };
            return {}
        };

        return (
            <Box style={{ position: 'relative' }}>
                <Box className={classes.meter + " " + theme.palette.mode}>
                    <Box className={classes.item + " " + theme.palette.mode} style={getStyles(1)} title={t('useless')}>&nbsp;</Box>
                    <Box className={classes.item + " " + theme.palette.mode} style={getStyles(2)} title={t('weak')}>&nbsp;</Box>
                    <Box className={classes.item + " " + theme.palette.mode} style={getStyles(3)} title={t('okay')}>&nbsp;</Box>
                    <Box className={classes.item + " " + theme.palette.mode} style={getStyles(4)} title={t('strong')}>&nbsp;</Box>
                </Box>
                {hasFocus && 
                    <Paper elevation={3} style={{ position: 'fixed', zIndex: 10 }}>
                        <Box style={{ padding: '10px' }}>
                            <Typography variant="subtitle1">{t('basic-requirement')}</Typography>
                            <CheckLabel value={PasswordRequirements.hasLength(password)} label={t('password-requirement-length')} />
                            <CheckLabel value={PasswordRequirements.getSecurityFactors(password) > 2} label={t('three-out-of-four')}/>
                        </Box>
                        <Divider />
                        <Box style={{ padding: '10px' }}>
                            <Typography variant="subtitle1">{t('security-factors')}</Typography>
                            <CheckLabel value={PasswordRequirements.hasLowercase(password)} label={t('password-requirement-lowercase')}/>
                            <CheckLabel value={PasswordRequirements.hasUppercase(password)} label={t('password-requirement-uppercase')}/>
                            <CheckLabel value={PasswordRequirements.hasDigits(password)} label={t('password-requirement-digits')}/>
                            <CheckLabel value={PasswordRequirements.hasOthers(password)} label={t('password-requirement-others')}/>
                        </Box>
                    </Paper>
                }
            </Box>
        );
    }
}

PasswordStrengthMeter.defaultProps = {
    password: '',
    hasFocus: false,
};

const  TranslatedComponent = withTranslation('translation')(PasswordStrengthMeter);
export default withStyles(useStyles, { withTheme: true })(TranslatedComponent);