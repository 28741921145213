import { TextField } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import CustomCheckbox from './CustomfieldTypes/CustomCheckbox';
import CustomDatefield from './CustomfieldTypes/CustomDatefield';
import CustomRadio from './CustomfieldTypes/CustomRadio';
import CustomSelect from './CustomfieldTypes/CustomSelect';
import CustomUploadbox from './CustomfieldTypes/CustomUploadbox';
import CustomHeader from './CustomfieldTypes/CustomHeader';

const useStyles = (theme) => ({

});

class Customfield extends Component {
    render() {
        const { item, errors, data, disabled } = this.props;
        
        const isRequired = () => {
            if (typeof item.required !== 'undefined' && item.required) {
                return true;
            }
            if (typeof item.status !== 'undefined' && (item.status & 2)) {
                return true;
            }
            return false;
        }

        const inputProps = () => {
            const name  = item.id ?? '';
            const title = item.title ?? '';
            var props = {
                margin: 'normal',
                fullWidth: true,
                name: name,
                required: isRequired(),
                value: data[name] ?? '',
                label: title,
                helperText: item.description ?? '',
                disabled: disabled,
                onChange: this.props.onChange,
                error: (typeof errors[name] !== 'undefined' ? true : undefined),
            };

            if (props.error) {
                if (typeof errors[name]['validFormat'] !== 'undefined') {
                    props['helperText'] = errors[name]['validFormat'];
                }
            }

            if (typeof item.customfield.type !== 'undefined') {
                if (['checkbox'].includes(item.customfield.type)) {   
                    props['onChange'] = this.props.onChangeBoolean;
                }
            }
            
            if (typeof item.customfield !== 'undefined') {
                const cf = item.customfield;
                if (cf.type === 'textarea') {
                    props.multiline = true;
                    props.rows = 6;
                }

                if (typeof item.select_options !== 'undefined' && Object.entries(item.select_options).length > 0) {
                    props.options = item.select_options;
                }
            }
            return props;
        };

        const typeMap = {
            'radio': CustomRadio,
            'checkbox': CustomCheckbox,
            'select': CustomSelect,
            'uploadbox': CustomUploadbox,
            'date': CustomDatefield,
            'header': CustomHeader,
        };

        const getCutomFieldType = () => {
            return item.customfield.type ?? 'text';
        };

        const CustomfieldComponent = typeMap[getCutomFieldType()] ?? TextField;

        return (
            <CustomfieldComponent
                {...inputProps()}
            />
        );
    }
}

Customfield.defaultProps = {
    item: {},
    onChange: null,
    disabled: false,
};

const  TranslatedComponent = withTranslation('translation')(Customfield);
export default withStyles(useStyles)(TranslatedComponent);