import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

const useStyles = (theme) => ({

});

class DateInfoDialog extends Component {
    handleOnClose = (e) => {
        const { onClose } = this.props;
        if (typeof onClose === 'function') {
            onClose();
        }
    }

    render() {
        const { t, classes } = this.props;
        const { item, open } = this.props;

        return <Dialog open={open} onClose={this.handleOnClose}>
            <DialogTitle id={'date-item-info-dialog-'+(item.id??'')}>
                asdasdas
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                asdasdasdas
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={this.handleOnClose}>Cancel</Button>
            </DialogActions>
        </Dialog>;
    }
}

DateInfoDialog.defaultProps = {
    item: {},
    open: false,
    onClose: null,
}


const  TranslatedComponent = withTranslation('translation')(DateInfoDialog);
export default withStyles(useStyles)(TranslatedComponent);