import { CalendarMonth } from '@mui/icons-material';
import { CalendarViewMonth, CalendarViewWeek, FormatListBulleted, NavigateBefore, NavigateNext } from '@mui/icons-material';
import { Box, Button, ButtonGroup, Divider, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import Events from '../../Utility/Events';
import { BookingContext } from '../Contexts/BookingContext';
import { CustomThemeContext } from '../Contexts/CustomThemeContext';
import DateInfoDialog from './Calendar/DateInfoDialog';
import Month from './Calendar/Month';
import TimeGrid from './Calendar/TimeGrid';
import Weekday from './Calendar/Weekday';


const useStyles = (theme) => ({
    weekdayToggle: {
        position: 'sticky',
        top: theme.spacing(8.5),
        zIndex: 10,
        padding: theme.spacing(2),
        height: theme.spacing(8),
        marginTop: theme.spacing(1.5),
        marginBottom: theme.spacing(1),
        width: theme.spacing(8),
        [theme.breakpoints.down('md')]: {
            width: theme.spacing(5),
            minWidth: theme.spacing(5),
        }
    },
    weekdayToggleIcon: {
        height: theme.spacing(6),
        width: theme.spacing(6),
    },
    topButtons: {
        display: 'flex',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    }
});

class Calendar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            infoOpen: false,
            minTime: 300,
            maxTime: 480,
            intervall: 5
        };
    }

    componentDidMount() {
        Events.on("scrolledToBottom", (e) => {
            this.handleLoadMoreAppointments(e);
        });
    }

    componentWillUnmount() {
        Events.remove("scrolledToBottom");
    }
    
    handleLoadMoreAppointments = () => {
        const { loadNextData } = this.context;
        if (typeof loadNextData === 'function') {
            loadNextData();
        }
    }

    handleOnInfoOpen = (e) => {
        this.setState({
            infoOpen: true,
        });
    }

    handleOnInfoClose = (e) => {
        this.setState({
            infoOpen: false,
        });
    }

    render() {
        const { t, classes } = this.props;
        const { infoOpen, intervall } = this.state;

        const weekdayProps = (datesByDay, day, viewType) =>  {
            return {
                date: day,
                items: datesByDay[day] ?? [],
                viewType: viewType,
                onInfoOpen: this.handleOnInfoOpen,
                onInfoClose: this.handleOnInfoClose
            };
        };

        const getColumnCountLabel = (count) => {
            if (count === 1) {
                return count + " " + t("day");
            }
            return count + " " + t('days');
        }

        const isSingleUser = (users) => {
            if (users) {
                if (users.length > 1) {
                    return false;
                }
            }
            return true;
        }

        const getBookingSlotsInfoText = (configurations) => {
            if (typeof configurations['online_booking_slots_info_text'] === 'string') {
                return <Box textAlign="center" marginTop={2} marginBottom={5}>
                    <Typography variant="subtitle2" component="div" color="secondary">{configurations['online_booking_slots_info_text']}</Typography>
                </Box>;
            }
            return null;
        }

        return (
            <BookingContext.Consumer>
                {({ dates, dayOptions, datesByDay, mindate, startdate, columnCount, handlePrevDate, handleNextDate, handleLoadMore, disablePrevDate, handleColumnCountChange, handleGoBack, passed, minTime, maxTime, slotHeight, viewType, setViewType, getListTimeRange, users, handleSearchFirstFreeSlot }) => (
                    <Box>
                        <Box className={classes.topButtons}>
                            <Box flexShrink={1} marginRight={1}>
                                <Button aria-label="back" variant='contained' disabled={passed.length === 0} onClick={(e) => handleGoBack(e)}>
                                    <NavigateBefore />
                                </Button>
                            </Box>
                            {viewType !== 'list' && <Box flexShrink={1}>
                                {dayOptions && dayOptions.length > 1 && <ButtonGroup>
                                    {dayOptions.map(i => {
                                        return <Button key={'column-count-'+i} variant="contained" disabled={(viewType === 'month') ? true : false} onClick={() => handleColumnCountChange(i)} color={columnCount === i ? 'primary' : 'secondary'}>
                                            {getColumnCountLabel(i)}
                                        </Button>;
                                    })}
                                </ButtonGroup>}                         
                            </Box>}
                            <Box flexGrow={1}>&nbsp;</Box>
                            <Box flexShrink={1}>
                                <ButtonGroup>
                                    <Button variant="contained" title={t('calendar-list-view')} onClick={e => setViewType('list')} color={viewType === 'list' ? 'primary' : 'secondary'}>
                                        <FormatListBulleted />
                                    </Button>
                                    <Button variant="contained" title={t('calendar-grid-view')} onClick={e => setViewType('grid')} color={viewType === 'grid' ? 'primary' : 'secondary'}>
                                        <CalendarViewWeek />
                                    </Button>
                                    {isSingleUser(users) && <Button variant="contained" title={t('calendar-block-view')} onClick={e => setViewType('calendar')} color={viewType === 'calendar' ? 'primary' : 'secondary'}>
                                        <CalendarViewMonth />
                                    </Button>}
                                    <Button variant="contained" title={t('calendar-month-view')} onClick={e => setViewType('month')} color={viewType === 'month' ? 'primary' : 'secondary'}>
                                        <CalendarMonth />
                                    </Button>
                                </ButtonGroup>
                            </Box>
                        </Box>
                        <Box>
                            {viewType === 'list' && <Box textAlign="center">
                                <Typography component="h1" variant="h5" sx={{ overflow: 'hidden' }}>
                                    {getListTimeRange && getListTimeRange()}
                                </Typography>
                            </Box>}
                        </Box>
                        <CustomThemeContext.Consumer>
                            {({ currentPublicClientConfigurations }) => (
                                <React.Fragment>
                                    <Box>
                                        <DateInfoDialog open={infoOpen} onClose={this.handleOnInfoClose}/>
                                        <TimeGrid viewType={viewType} minTime={minTime} maxTime={maxTime} intervall={intervall} slotHeight={slotHeight}>
                                            {viewType !== 'list' && <Box>
                                                <Button variant='contained' disabled={disablePrevDate} className={classes.weekdayToggle} onClick={handlePrevDate}><NavigateBefore className={classes.weekdayToggleIcon}/></Button>
                                            </Box>}
                                            {viewType === 'month'
                                                ? <Month dates={datesByDay} startdate={startdate} />
                                                : dates && dates.map((i, index) => {
                                                    return <React.Fragment key={'weekday-'+i}>
                                                        <Weekday {...weekdayProps(datesByDay, i, viewType)} {...{ minTime: minTime, maxTime:maxTime, intervall: intervall, slotHeight: slotHeight }}/>
                                                        {viewType === 'calendar' && (index < dates.length - 1) && <Divider orientation="vertical" variant="middle" flexItem />}
                                                    </React.Fragment>;
                                                })
                                            }
                                            {viewType === 'list' && <Box textAlign="center">
                                                <Button color="primary" variant="contained" onClick={(e) => handleLoadMore(e)}>{t('load-more')}</Button>
                                            </Box>}
                                            {viewType !== 'list' && <Box>
                                                <Button variant='contained' className={classes.weekdayToggle} onClick={handleNextDate}><NavigateNext className={classes.weekdayToggleIcon}/></Button>
                                            </Box>}
                                        </TimeGrid>
                                    </Box>
                                    <Box textAlign="center" marginTop={2}>
                                        <Button variant='contained' title={t('find-first-slot')} onClick={e => handleSearchFirstFreeSlot()} color={'primary'}>{t('find-first-slot')}</Button>
                                    </Box>
                                    {getBookingSlotsInfoText(currentPublicClientConfigurations)}                        
                                </React.Fragment>
                            )}
                        </CustomThemeContext.Consumer>
                    </Box>
                )} 
            </BookingContext.Consumer>
        );
    }
}

Calendar.contextType=BookingContext;

const  TranslatedComponent = withTranslation('translation')(Calendar);
export default withStyles(useStyles, { withTheme: true })(TranslatedComponent);