import { Close } from '@mui/icons-material';
import { Alert, AppBar, Box, Button, Checkbox, CircularProgress, Container, Dialog, FormControlLabel, FormGroup, Toolbar, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import AuthService from '../../../Services/AuthService';
import Events from '../../../Utility/Events';
import { formatDateTime } from '../../../Utility/Formatter';
import { AuthContext } from '../../Contexts/AuthContext';
import DsgvoContent from './DsgvoContent';

const useStyles = (theme) => ({
    toolbar: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    alert: {
        marginTop: theme.spacing(2)
    }
});

class DsgvoDialog extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            statement: null,
            consentChk: false,
        };
    }

    componentDidMount() {
        AuthService
            .privacyStatement()
            .then(res => {
                this.setState({
                    statement: res.data
                })
            });
    }

    handleChangeConsent = (e) => {
        const { consentChk } = this.state;

        this.setState({
            consentChk: !consentChk
        });
    }

    handleLogout = (e, logoutFunction) => {
        const { t } = this.props; 
        logoutFunction()
            .then(res => {
                Events.dispatch('msgSuccess', t('successfull-logged-out'))
            })
    }

    handlePrivacyConsentConfirmation = (e, consentMethod) => {
        const { t } = this.props;
        consentMethod()
            .then(res => {
                Events.dispatch('msgSuccess', t('dsgvo-accepted'))
            });
    }

    render() {
        const { t, classes, show, onClose } = this.props;

        const { statement, consentChk } = this.state;
        
        const parseBlock = (block) => {
            return block.map((b, index) => {
                if (typeof b === 'string') {
                    return b;
                } else {
                    if (typeof b.tag !== 'undefined') {
                        const TAGTYPE = b.tag;
                        const tagClassName = b.class;
                        if (typeof b.children !== 'undefined' && (b.children).length > 0) {
                            return <TAGTYPE key={'gdpr-tag-' + index} className={tagClassName}>
                                {parseBlock(b.children)}
                            </TAGTYPE>;
                        } else {
                            if (['br'].includes(TAGTYPE)) {
                                return <TAGTYPE key={'gdpr-tag-' + index}/>
                            } else {
                                return <TAGTYPE key={'gdpr-tag-' + index}>&nbsp;</TAGTYPE>;
                            }
                        }
                    }
                }
            });
        }

        const getParsedStatement = () => {
            const { content} = statement;
            return parseBlock(content);
        }

        const getPrivacyConsent = (consent, consentDate) => {
            const { t } = this.props;
            if (consent) {
                return <Alert severity="success" className={classes.alert}>
                    {t('consent-given-on')}: {formatDateTime(consentDate)}
                </Alert>;
            }
            return <Alert severity="error" className={classes.alert}>
                {t('please-read-carefully')}
            </Alert>;
        }

        return (
            <AuthContext.Consumer>
                {({ logout, privacyConsent, privacyConsentDate, handlePrivacyConsent }) => (
                    <Dialog
                        fullScreen
                        open={show || !privacyConsent}
                        onClose={() => {}}
                    >
                        <AppBar sx={{ position: 'sticky' }}>
                            <Toolbar className={classes.toolbar}>
                                <Container sx={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
                                    <Typography variant='h5' flexGrow={1}>
                                        {t('dsgvo-title')}
                                    </Typography>
                                    {privacyConsent && <Button autoFocus color='inherit' onClick={(e) => onClose(e)}>
                                        {t('close')} <Close />
                                    </Button>}
                                </Container>
                            </Toolbar>
                        </AppBar>
                        <Container>
                            <React.Fragment>
                                {statement === null && <Box textAlign='center' marginTop={25}><CircularProgress size={200}/></Box>}
                                {getPrivacyConsent(privacyConsent, privacyConsentDate)}
                                {statement !== null && <DsgvoContent statement={statement}/>}
                                {!privacyConsent && <Box>
                                    <FormGroup>
                                        <FormControlLabel control={<Checkbox checked={consentChk} onChange={this.handleChangeConsent}/>} label={t('dsgvo-accept')}/>
                                    </FormGroup>
                                    <Box textAlign='center' sx={{ marginBottom: 2 }}>
                                        <Button variant='contained' sx={{ margin: 1 }} color='secondary' onClick={(e) => this.handleLogout(e,logout)}>{t('logout')}</Button>
                                        <Button variant='contained' sx={{ margin: 1 }} color='primary' disabled={!consentChk} onClick={(e) => this.handlePrivacyConsentConfirmation(e, handlePrivacyConsent)}>{t('accept')}</Button>
                                    </Box>
                                </Box>}
                            </React.Fragment>
                        </Container>
                    </Dialog>
                )} 
            </AuthContext.Consumer>
        );
    }
}

DsgvoDialog.defaultProps = {
    show: false,
    onClose: null,
};


const TranslatedPasswordRequest = withTranslation('translation')(DsgvoDialog);
export default withStyles(useStyles)(TranslatedPasswordRequest);