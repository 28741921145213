import RestAppService from "./RestAppService";

class DateSearchService {
    search(params) {
        return RestAppService
            .getCore("/datesSearch/find", params);
    }
    first(params) {
        return RestAppService
            .getCore("/datesSearch/first", params);
    }
}

export default new DateSearchService();