import { format } from "date-fns";
import { dateStrToDate, formatDate, getTimeInMinutes } from "./Formatter";

export function getDatesByDay (dates) {
    let datesByDay = {};

    (Object.entries(dates)).forEach(([key, value]) => {
        let dateKey = value.begin.substring(0, 10);
        if (typeof datesByDay[dateKey] === 'undefined') {
            datesByDay[dateKey] = [];
        }
        datesByDay[dateKey].push(value);
    });

    return datesByDay;
}

export function getMinTimeByDates (dates, fallback = 0) {
    let entries = Object.entries(dates);
    if (entries.length > 0) {
        let minTime = 1440;
        entries.forEach(([key, value]) => {
            let curTime = getTimeInMinutes(value.begin);
            if (curTime < minTime) {
                minTime = curTime;
            }
        });
        if (minTime < 0) {
            minTime = 0;
        }
        return minTime;
    }
    return fallback;
}

export function getMaxTimeByDates (dates, fallback = 1440) {
    let entries = Object.entries(dates);
    if (entries.length > 0) {
        let maxTime = 0;
        entries.forEach(([key, value]) => {
            let curTime = getTimeInMinutes(value.end);
            if (curTime > maxTime) {
                maxTime = curTime;
            }
        });
        if (maxTime > 1440) {
            maxTime = 1440;
        }
        return maxTime;
    }
    return fallback;
}

export function YmdFormat (date) {
    const ts = formatDate(date);
    return ts.getFullYear() + '-' + (ts.getMonth() + 1).toString().padStart(2, '0') + '-' + ts.getDate().toString().padStart(2, '0');
}

export function HiFormat (date) {
    const ts = dateStrToDate(date);
    return ts.getHours().toString().padStart(2, '0') + ':' + ts.getMinutes().toString().padStart(2, '0');
}

export function YmdHiFormat (date) {
    return YmdFormat(date) + " " + HiFormat(date);
}

export function modifyDays (date, days) {
    const ts = formatDate(date);
    ts.setDate(ts.getDate() + days);
    return YmdFormat(ts);
}

export function formatStartdate (date) {
    return YmdFormat(date) + ' 00:00:00';
}

export function formatEnddate (date) {
    return YmdFormat(date) + ' 23:59:59';
}
export function daysInMonth (date, offset = 0) {
    let ymd = YmdFormat(date);
    return new Date(ymd.substring(0, 4), parseInt(ymd.substring(5,7)) + offset, 0).getDate();
}
export function getLastMonday (ts) {
    const date  = formatDate(ts);
    const day     = date.getDay();
    let prevMonday = formatDate(date);
    prevMonday.setDate(date.getDate() - (day-1));
    return formatDate(prevMonday);
}