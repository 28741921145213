import { CloudUpload } from '@mui/icons-material';
import { Box } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import DocumentService from '../../Services/DocumentService.js';
import CachingHelper from '../../Utility/CachingHelper.js';
import Events from '../../Utility/Events.js';
import { AuthContext } from '../Contexts/AuthContext.js';
import ConfiguredCustomText from '../Elements/ConfiguredCustomText.js';
import CustomFab from '../Elements/CustomFab.js';
import MainContent from '../Elements/MainContent.js';
import DocumentList from './Lists/DocumentList.js';


const useStyles = (theme) => ({
    extendedIcon: {
        marginRight: theme.spacing(1)
    }
});


class Documents extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            documents: CachingHelper.get('documents') || [],
            path: '',
            uploadEnabled: true,
        };

        this.uploadBtnRef = React.createRef();
        this.uploader     = null;
    }

    componentDidMount() {
        this.loadData();

        if (this.uploadBtnRef && this.uploadBtnRef.current) {
            const { clientConfigurations } = this.context;
            if (clientConfigurations) {
                const { storage_bucket, storage_folder } = clientConfigurations;
                if (storage_bucket && storage_folder) {
                    const self = this;
                    this.uploader = DocumentService.registerUploader(this.uploadBtnRef.current, {
                        storage_bucket: ''+storage_bucket,
                        storage_folder: ''+storage_folder,
                        storage_subfolder: 'docs',
                    }, { 
                        multiple: true,
                        callbacks: {
                            onSubmit: function (id, fileName) {
                                Events.dispatch("startLoading");
                            },
                            onAllComplete: function(succeeded, failed) {
                                Events.dispatch("doneLoading");
                                self.loadData();
                                setTimeout(() => {
                                    self.loadData();
                                }, 5000);
                            }
                        },
                    });
                }
            }
        }
    }
    
    loadData = () => {
        DocumentService
            .list(true, true)
            .then(response => {
                this.setState({
                    documents: response
                }, () => {
                    CachingHelper.set('documents', response);
                });
            });
    }

    handleSwitchPath = (e, path) => {
        this.setState({
            path: path
        });
    }

    render() {
        const { t, classes, type } = this.props;
        const { documents, uploadEnabled, path } = this.state;

        const isUploadEnabled = (clientConfigurations) => {
            if (clientConfigurations) {
                if (typeof clientConfigurations.portal_upload_disabled === 'undefined' || !clientConfigurations.portal_upload_disabled) {
                    return true;
                }
            }
            return false;
        }

        const getDocumentsInPath = (path) => {
            if (path !== null) {
                const folder =  documents.find(item => item.path === path);
                if (folder) {
                    if (typeof folder.children !== 'undefined') {
                        return folder.children;
                    }
                }
            }
            return documents;
        }

        return (
            <AuthContext.Consumer>
                {({ clientConfigurations }) => (
                    <MainContent
                        title={<ConfiguredCustomText fallback={t('documents')} section={'documents'} label={'title'}/>}
                        textPrimary={<ConfiguredCustomText fallback={t('text-head-documents')} section={'documents'} label={'primary'}/>}
                        textSecondary={<ConfiguredCustomText fallback={t('text-subhead-basedata')} section={'documents'} label={'secondary'}/>}
                        actionComponent={isUploadEnabled(clientConfigurations) && <Box flexShrink={1} alignSelf="center">
                            <CustomFab
                                disabled={!uploadEnabled}
                                component="span"
                            >
                                <a ref={this.uploadBtnRef}>
                                    <CloudUpload className={classes.extendedIcon} />
                                    {t('upload')}
                                </a>
                            </CustomFab>
                        </Box>}
                    >
                        <DocumentList items={getDocumentsInPath(path)} handleSwitchPath={(e, path) => this.handleSwitchPath(e, path)} type={'documents'}/>
                    </MainContent>
                )}
            </AuthContext.Consumer>
        );
    }
}
Documents.contextType = AuthContext;
const  TranslatedComponent = withTranslation('translation')(Documents);
export default withStyles(useStyles)(TranslatedComponent);