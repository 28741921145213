import { Edit as EditIcon, Save as SaveIcon } from '@mui/icons-material';
import {
    Autocomplete, Box,
    CircularProgress,
    FormControlLabel,
    Grid, Switch,
    TextField
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import React from 'react';
import { withTranslation } from 'react-i18next';
import ContactService from '../../Services/ContactService.js';
import ContactTypemapComponent from '../../Utility/ContactTypemapComponent.js';
import Events from '../../Utility/Events.js';
import { AuthContext } from '../Contexts/AuthContext.js';
import AdvancedDatepicker from '../Elements/AdvancedDatepicker.js';
import ConfiguredCustomText from '../Elements/ConfiguredCustomText.js';
import CustomFab from '../Elements/CustomFab.js';
import MainContent from '../Elements/MainContent.js';
import AutoCompleteVirtualizedList from './Lists/AutoCompleteVirtualizedList.js';

const useStyles = (theme) => ({
    formcontainer: {
        width: '100%',
        marginTop: theme.spacing(2),
        '& .MuiGrid-item .MuiFormControl-root, & .MuiGrid-item > .MuiInputBase-root': {
            width: 'calc(100% - 20px)',
            margin: '10px'
        },
        '& .MuiGrid-item > .MuiInputBase-root': {
            marginTop: '25px'
        },
        '& .MuiGrid-item > .MuiFormControlLabel-root': {
            padding: theme.spacing(2),
        }
    },
    editIcon: {
        marginRight: theme.spacing(1),
        fontSize: '1rem'
    }
});

class Basedata extends ContactTypemapComponent {
    static contextType = AuthContext;

    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            mounted: false,
            editMode: false,
            data: null,
            typemap: {},
            options: {},
            errors: {},
            initial: true,
            mandatory: ['email', 'lastname'],
            hidden: [],
        };
    }
    
    componentDidMount() {
        this.getContactTypemap();

        const { clientConfigurations } = this.context;
        if (clientConfigurations) {
            let mandatory = [];
            if (typeof clientConfigurations.online_booking_mandatory_fields !== 'undefined') {
                mandatory = clientConfigurations.online_booking_mandatory_fields;
            }
            if (typeof clientConfigurations.portal_registration_fields_mandatory !== 'undefined') {
                mandatory = clientConfigurations.portal_registration_fields_mandatory;
            }
            let hidden = [];
            if (typeof clientConfigurations.portal_contact_hidden_fields !== 'undefined') {
                hidden = clientConfigurations.portal_contact_hidden_fields;
            }
            mandatory.push('email');
            mandatory.push('lastname');
            this.setState({
                mandatory: mandatory,
                hidden: hidden,
            });
        }
    }

    afterPopulateTypemap = () => {
        this.setState({
            initial: false,
            data: this.context.contact ?? null,
            mounted: true,
        });
    }

    handleToggleEditMode = (e) => {
        this.setState({
            editMode: !this.state.editMode
        });
    }

    handleSubmit = (e, setContactCallback) => {
        const { t } = this.props;

        ContactService
            .store({contact: this.state.data})
            .then(response => {
                setContactCallback(response.contact ?? null);
                this.setState({
                    data: response.contact ?? null,
                    errors: {}
                });

                this.setState({
                    editMode: false
                });
                Events.dispatch('msgSuccess', t('save-successfull'));
            })
            .catch(err => {
                this.setState({
                    errors: err.response.data.errors ?? {},
                });
                Events.dispatch('doneLoading');
                Events.dispatch('msgError', t('save-failed'));
            });
    }

    handleToggleSwitch = (e) => {
        const data = this.state.data;
        const name = e.target.name;
        const checked = e.target.checked;
        this.setState({
            data: {...data, [name]: checked}
        });
    }

    handleOnChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        var data = this.state.data;
        data[name] = value;

        this.setState({
            data: {...data}
        });
    }

    handleChangeBirthday = (date) => {
        this.handleOnChange({
            target: {
                name: 'birthday',
                value: date,
            }
        });
    }
    
    render() {
        const { classes, t }   = this.props;
        const { mounted, editMode, data, errors, initial, options }  = this.state;

        const editBtn = <CustomFab
            onClick={this.handleToggleEditMode}
        >
            <EditIcon className={classes.editIcon} />
            {t('edit')}
        </CustomFab>;

        const saveBtn = <AuthContext.Consumer>
            {({ setContact }) => (
                <CustomFab
                    onClick={(e) => this.handleSubmit(e, setContact)}
                >
                    <SaveIcon className={classes.editIcon} />
                    {t('save')}
                </CustomFab>
            )}
        </AuthContext.Consumer>;

        const getValue = (name, fallback = '') => {
            return this.getPatchedValue(name, fallback);
        }

        const getBooleanValue = (name, fallback = false) => {
            const value = getValue(name, fallback);
            return value ? true : false;
        }

        const getTitle = (title) => {
            return this.getTitle(title);
        };

        const getPatchedValue = (name) => {
            return (initial ? '' : getValue(name));
        }

        const inputProps = (name) => {
            var props = {
                disabled:!editMode,
                name:name,
                value: getPatchedValue(name),
                label: t(getTitle(name)),
                onChange: this.handleOnChange,
                options: [],
                error: (typeof errors[name] !== 'undefined' ? true : undefined),
                helperText: undefined,
                required: false,
            };

            if (!(initial ? '' : getValue(name))) {
                props.sx = {
                    opacity: editMode ? 0.5 : 0.3
                };
            }

            const { mandatory } = this.state;
            let nameKey = name.replace('boolean_', '');
            if (mandatory.includes(nameKey)) {
                props.required = true;
            }

            if (props.error) {
                if (typeof errors[name]['validFormat'] !== 'undefined') {
                    props['helperText'] = errors[name]['validFormat'];
                }
            }

            props['options'] = [{id: '', label: ''}];            

            const { typemap } = this.state;
            if (typeof typemap[name] !== 'undefined') {
                if (['autocomplete', 'select'].includes(typemap[name]['type'])) {
                    if (typeof options[name] !== 'undefined') {
                        props['onChange'] = (e, v, r) => this.handleAutocompleteInputChange(e,v,r,name);
                        props['options'] = options[name];
                        props['value']      = null;
                        const selectedValue = getValue(name);
                        let selected = options[name].find(x => x.id == selectedValue);
                        if (selected && typeof selected !== 'undefined') {
                            props['value']   = selected;
                        }
                    }
                }
            }
            return props;
        };

        const inputPropsSubElement = (name) => {
            const props = inputProps(name);

            return {
                error: props.error ?? undefined,
                helperText: props.helperText ?? undefined,
                required: props.required ?? false,
            };
        }

        const hasOptions = (name) => {
            if (typeof options[name] !== 'undefined') {
                if (Array.isArray(options[name]) && options[name].length > 0) {
                    return true;
                }
            }
            return false;
        }

        const isVisible = (name, alias = []) => {
            let nameKey = name.replace('boolean_', '');
            const { hidden } = this.state;
            if (hidden.includes(nameKey)) {
                return false;
            } else {
                if (alias.length > 0) {
                    return !alias.some(i => hidden.includes(i));
                }
            }
            return true;
        }

        return (
            <MainContent
                title={<ConfiguredCustomText fallback={t('basedata')} section={'basedata'} label={'title'}/>}
                textPrimary={<ConfiguredCustomText fallback={t('text-head-basedata')} section={'basedata'} label={'primary'}/>}
                textSecondary={<ConfiguredCustomText fallback={t('text-subhead-basedata')} section={'basedata'} label={'secondary'}/>}
                actionComponent={
                    <Box flexShrink={1} alignSelf="center">
                        { editMode ? saveBtn : editBtn }
                    </Box>
                }
            >
                {!mounted && <Box textAlign='center' marginTop={15}><CircularProgress size={150}/></Box>}
                {mounted && <form noValidate>
                    <Grid container className={classes.formcontainer}>
                        <Grid item xs={12} lg={6}>
                            <Grid container>
                                <Grid item xs={12} md={6}>
                                    {isVisible('salutation_id') && <Autocomplete
                                        {...inputProps("salutation_id")}
                                        ListboxComponent={AutoCompleteVirtualizedList}
                                        renderOption={(props, option) => [props, option]}
                                        renderInput={(params) => <TextField {...params} label={t(getTitle('salutation_id'))}/>}
                                    />}
                                </Grid>
                                <Grid item xs={12} md={6}></Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} md={6}>{isVisible('title') && <TextField {...inputProps("title")} />}</Grid>
                                <Grid item xs={12} md={6}>{isVisible('title2') && <TextField {...inputProps("title2")}  label={t('title-postfix')} />}</Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} md={6}>{isVisible('firstname') && <TextField {...inputProps("firstname")} />}</Grid>
                                <Grid item xs={12} md={6}>{isVisible('lastname') && <TextField {...inputProps("lastname")} />}</Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} md={6}>{isVisible('address') && <TextField {...inputProps("address")} />}</Grid>
                                <Grid item xs={12} md={6}>{isVisible('zip') && <TextField {...inputProps("zip")} />}</Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} md={6}>{isVisible('city') && <TextField {...inputProps("city")} />}</Grid>
                                <Grid item xs={12} md={6}>
                                    {isVisible('nation_id') && <Autocomplete
                                        {...inputProps("nation_id")}
                                        ListboxComponent={AutoCompleteVirtualizedList}
                                        renderOption={(props, option) => [props, option]}
                                        renderInput={(params) => <TextField {...params} label={t(getTitle('nation_id'))}/>}
                                    />}
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} md={6}>{isVisible('insurance_number') && <TextField {...inputProps("insurance_number")} />}</Grid>
                                <Grid item xs={12} md={6}>
                                    {isVisible('birthday') && <AdvancedDatepicker 
                                        inputProps={inputProps("birthday")}
                                        onChange={this.handleChangeBirthday}
                                        value={getPatchedValue('birthday')}
                                    />}
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12}>
                                    {isVisible('insurance_id') && <Autocomplete
                                        {...inputProps("insurance_id")}
                                        ListboxComponent={AutoCompleteVirtualizedList}
                                        renderOption={(props, option) => [props, option]}
                                        renderInput={(params) => <TextField {...params} {...inputPropsSubElement('insurance_id')} label={t(getTitle('insurance_id'))}/>}
                                    />}
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} md={6}>
                                    {isVisible('insurance_private') && <FormControlLabel
                                        disabled={!editMode}
                                        control={<Switch color="primary" name="insurance_private" onChange={this.handleToggleSwitch} checked={getBooleanValue('insurance_private', false)}/>}
                                        label={t('insurance_private')}
                                        labelPlacement="end"
                                    />}
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    {isVisible('family_doctor_id') && <Autocomplete
                                        {...inputProps("family_doctor_id")}
                                        ListboxComponent={AutoCompleteVirtualizedList}
                                        renderOption={(props, option) => [props, option]}
                                        renderInput={(params) => <TextField {...params} {...inputPropsSubElement('family_doctor_id')} label={t(getTitle('family_doctor_id'))}/>}
                                    />}
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} md={6}>
                                    {isVisible('delivery_preference_id') && <Autocomplete
                                        {...inputProps("delivery_preference_id")}
                                        ListboxComponent={AutoCompleteVirtualizedList}
                                        renderOption={(props, option) => [props, option]}
                                        renderInput={(params) => <TextField {...params} {...inputPropsSubElement('delivery_preference_id')} label={t(getTitle('delivery_preference_id'))}/>}
                                    />}
                                </Grid>
                                {hasOptions('assignment_mode_id') && <Grid item xs={12} md={6}>
                                    {isVisible('assignment_mode_id') && <Autocomplete
                                        {...inputProps("assignment_mode_id")}
                                        ListboxComponent={AutoCompleteVirtualizedList}
                                        renderOption={(props, option) => [props, option]}
                                        renderInput={(params) => <TextField {...params} {...inputPropsSubElement('assignment_mode_id')} label={t(getTitle('assignment_mode_id'))}/>}
                                    />}
                                </Grid>}
                            </Grid>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <Grid container>
                                <Grid item xs={12} md={6}>{isVisible('mobile') && <TextField {...inputProps("mobile")} />}</Grid>
                                <Grid item xs={12} md={6}>{isVisible('email') && <TextField {...inputProps("email")} />}</Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} md={6}>{isVisible('phone') && <TextField {...inputProps("phone")} label={t('phone-private')}/>}</Grid>
                                <Grid item xs={12} md={6}>{isVisible('phone_secondary') && <TextField {...inputProps("phone_secondary")} label={t('phone-business')}/>}</Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} md={6}>{isVisible('iban', ['bank_iban']) && <TextField {...inputProps("iban")} />}</Grid>
                                <Grid item xs={12} md={6}>{isVisible('bic', ['bank_bic']) && <TextField {...inputProps("bic")} />}</Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} md={6}>
                                    {isVisible('boolean_sms_notification') && <FormControlLabel
                                        {...inputProps('boolean_sms_notification')}
                                        control={<Switch color="primary"  name="boolean_sms_notification" onChange={this.handleToggleSwitch} checked={getBooleanValue('boolean_sms_notification', false)}/>}
                                        labelPlacement="end"
                                    />}
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    {isVisible('boolean_email_notification') && <FormControlLabel
                                        {...inputProps('boolean_email_notification')}
                                        control={<Switch color="primary" name="boolean_email_notification" onChange={this.handleToggleSwitch} checked={getBooleanValue('boolean_email_notification', false)}/>}
                                        labelPlacement="end"
                                    />}
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} md={6}>
                                    {isVisible('boolean_newsletter') && <FormControlLabel
                                        {...inputProps('boolean_newsletter')}
                                        control={<Switch color="primary" name="boolean_newsletter" onChange={this.handleToggleSwitch} checked={getBooleanValue('boolean_newsletter', false)}/>}
                                        labelPlacement="end"
                                    />}
                                </Grid>
                                <Grid item xs={12} md={6}>&nbsp;</Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>}
            </MainContent>
        );
    }
}

Basedata.contextType=AuthContext;

const  TranslatedComponent = withTranslation('translation')(Basedata);
export default withStyles(useStyles)(TranslatedComponent);