import { FormControl, FormHelperText, FormLabel, MenuItem, Select } from '@mui/material';
import React, { Component } from 'react';

class CustomSelect extends Component {
    render() {
        const { options, value, name, label, onChange, disabled, error, helperText, ...otherprops } = this.props;
        return (
            <FormControl fullWidth component="fieldset" margin='normal' {...this.props}>
                <FormLabel component="legend">{label}</FormLabel>
                {(helperText) && <FormHelperText error={error ? true : false}>{helperText}</FormHelperText>}
                <Select aria-label={label} value={value} disabled={disabled} name={name} onChange={onChange}>
                    {Object.entries(options).map(([key, value]) => {
                        return <MenuItem value={key}>{value}</MenuItem>;
                    })}
                </Select>
            </FormControl>
        );
    }
}

CustomSelect.defaultProps = {
    options: {},
    value: null,
    name: null,
    label: '',
    disabled: false,
};

export default CustomSelect;