import { NavigateBefore } from '@mui/icons-material';
import { Button, Card, CardActions, CardContent, CardHeader, Container, TextField, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { Box } from '@mui/system';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { formatDateTimeFormal, formatTime, formatPrice } from '../../../Utility/Formatter';
import { BookingContext } from '../../Contexts/BookingContext';

import Events from '../../../Utility/Events.js';
import history from '../../../Utility/RouterHistory';
import ConfiguredCustomText from '../ConfiguredCustomText';
import { AuthContext } from '../../Contexts/AuthContext.js';


const useStyles = (theme) => ({
    container: {
        paddingTop: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            padding: 0,
            paddingTop: theme.spacing(2),
        }
    },
    buttonBox: {
        width: '100%',
        textAlign: 'center',
    },
    description: {
        marginTop: theme.spacing(4)
    },
    topButtons: {
        position: 'absolute',
        top: 0,
        display: 'flex',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }       
    },
    paragraph: {
        marginBottom: theme.spacing(2)
    }
});

class Checkout extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            description: ''
        };
    }

    handleOnChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({
            [name]: value
        });
    };

    handleSubmitBookingClick = (e, submitMethod, data = {}, gobackMethod) => {
        const { t } = this.props;

        submitMethod(e, data)
            .then(res => {
                history.push('/appointments');
                Events.dispatch('msgSuccess', t('booking-successfull'))
            })
            .catch(res => {
                Events.dispatch('msgError', t('appointment-already-booked'));
                Events.dispatch('doneLoading');
                gobackMethod({});
            });
    }

    render() {
        const { t, classes, i18n } = this.props;

        const { description } = this.state;

        const cardBackAction = (passed, handleGoBack) =>  {
            return <Button aria-label="back" variant='contained' disabled={passed.length === 0} onClick={(e) => handleGoBack(e)}>
                <NavigateBefore />
            </Button>;
        }

        const getCardTitle = (passed, handleGoBack) => {
            return <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ flexShrink: 1, display: { xs: 'block', sm: 'none' }, marginRight: 1 }}>
                    {cardBackAction(passed, handleGoBack)}
                </Box>
                <Box flexGrow={1}>
                    {t('your-choice')}
                </Box>
            </Box>;
        };

        const hasPrice = (booking) => {
            if (typeof booking.articleDetails !== 'undefined' && typeof booking.articleDetails.gross_amount !== 'undefined') {
                return true;
            }
            return false;
        }

        const getPrice = (booking) => {
            if (hasPrice(booking)) {
                const price = booking.articleDetails.gross_amount ?? null;
                return price;
            }
            return null;
        }

        const showDuration = (configs) => {
            if (typeof configs !== 'undefined') {
                if (typeof configs['online_booking_article_hide_duration'] !== 'undefined') {
                    if (configs['online_booking_article_hide_duration']) {
                        return false;
                    }
                }
            }
            return true;
        }

        const hasDuration = (booking) => {
            if (typeof booking.duration !== 'undefined' && booking.duration !== null) {
                return true;
            }
            return false;
        }

        const getDuration = (booking) => { 
            if (hasDuration(booking)) {
                const duration = booking.duration ?? null;
                return duration + ' min';
            }
            return null;
        }

        return <BookingContext.Consumer>
            {({ booking, passed, handleGoBack, handleSubmitBooking }) => (
                <Box sx={{ position: 'relative' }}>
                    <Box className={classes.topButtons}>
                        <Box flexShrink={1} marginRight={1}>
                            {cardBackAction(passed, handleGoBack)}
                        </Box>
                    </Box>
                    <Container maxWidth="xs" className={classes.container}>
                        <Card>
                            <CardHeader
                                title={getCardTitle(passed, handleGoBack)}
                            />
                            <CardContent>
                                <Typography variant='caption' color='secondary' component='div'>
                                    {t('appointment')}
                                </Typography>
                                <Typography className={classes.paragraph} variant='p' component='div'>
                                    {formatDateTimeFormal(booking.begin)} - {formatTime(booking.end)}
                                </Typography>
                                <Typography variant='caption' color='secondary' component='div'>
                                    <ConfiguredCustomText fallback={t('therapist')} section="ressource" label="label"/>
                                </Typography>
                                <Typography className={classes.paragraph} variant='p' component='div'>
                                    {booking.trainer ? booking.trainer : (booking.user ?? '')}
                                </Typography>
                                {booking.articleDetails && <React.Fragment>
                                    <Typography variant='caption' color='secondary' component='div'>
                                        <ConfiguredCustomText fallback={t('service')} section="service" label="label"/>
                                    </Typography>
                                    <Typography className={classes.paragraph} variant='p' component='div'>
                                        <div>{booking.title ? booking.title : (booking.articleDetails.name ?? '')}</div>
                                        <div>{(booking.articleDetails.info ?? '')}</div>
                                    </Typography>
                                    {hasPrice(booking) && <React.Fragment>
                                        <Typography variant='caption' color='secondary' component='div'>
                                            {t('price')}
                                        </Typography>
                                        <Typography className={classes.paragraph} variant='p' component='div'>
                                            {getPrice(booking)}
                                        </Typography>
                                    </React.Fragment>}
                                    <AuthContext.Consumer>
                                        {({ clientConfigurations }) => (
                                            hasDuration(booking) && showDuration(clientConfigurations) && <React.Fragment>
                                                <Typography variant='caption' color='secondary' component='div'>
                                                    {t('duration')}
                                                </Typography>
                                                <Typography className={classes.paragraph} variant='p' component='div'>
                                                    {getDuration(booking)}
                                                </Typography>
                                            </React.Fragment>
                                        )}
                                    </AuthContext.Consumer>
                                </React.Fragment>}
                                {booking.locationDetails && <React.Fragment>
                                    <Typography variant='caption' color='secondary' component='div'>
                                        {t('location')}
                                    </Typography>
                                    <Typography className={classes.paragraph} variant='p' component='div'>
                                        {booking.locationDetails.name ?? ''}
                                    </Typography>
                                </React.Fragment>}
                                {(typeof booking.date_id !== 'undefined') && <React.Fragment>
                                    <Typography variant='caption' color='secondary' component='div'>
                                            {t('appointmenttype')}
                                        </Typography>
                                    <Typography className={classes.paragraph} variant='p' component='div'>
                                        <ConfiguredCustomText fallback={t('groupdate')} section={'booking'} label={'groupdate_label'}/>
                                    </Typography>
                                </React.Fragment>}

                                <TextField
                                    className={classes.description}
                                    label={<ConfiguredCustomText fallback={t('description')} section={'checkout'} label={'text_placeholder'}/>}
                                    fullWidth
                                    multiline
                                    value={description}
                                    name='description'
                                    onChange={this.handleOnChange}
                                    rows={4}
                                    autoFocus
                                    placeholder={t('optional-details-for-booking')}
                                />
                            </CardContent>
                            <CardActions>
                                <Box className={classes.buttonBox}>
                                    <Box>
                                        <Button color='primary' variant='contained' onClick={(e) => this.handleSubmitBookingClick(e, handleSubmitBooking, { description: description }, handleGoBack)}>
                                            {t('book-binding')}
                                        </Button>
                                    </Box>
                                    <Box>
                                        <Button color='secondary' to='/appointments' component={Link}>
                                            {t('cancel')}
                                        </Button>
                                    </Box>
                                </Box>
                            </CardActions>
                        </Card>
                    </Container>
                </Box>
            )}
        </BookingContext.Consumer>;
    }
}

const  TranslatedComponent = withTranslation('translation')(Checkout);
export default withStyles(useStyles)(TranslatedComponent);