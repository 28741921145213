import AuthService from "../Services/AuthService";

const CachingHelper = {
    cachekey(cacheKey, prefix) {
        return [cacheKey, prefix, AuthService.getActiveClientNumber(), AuthService.getActiveContactId()].filter(Boolean).join('_');
    },
    get(cacheKey, prefix = null) {
        return JSON.parse(sessionStorage.getItem(this.cachekey(cacheKey, prefix))) || null;
    },
    set(cacheKey, data, prefix = null) {
        return sessionStorage.setItem(this.cachekey(cacheKey, prefix), JSON.stringify(data));
    },
    remove(cacheKey, prefix = null) {
        return sessionStorage.removeItem(this.cachekey(cacheKey, prefix));
    }
};

export default CachingHelper;