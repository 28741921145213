import { MoreVert as MoreVertIcon } from '@mui/icons-material';
import {
    Alert,
    Avatar,
    Box,
    Card,
    CardContent,
    CardHeader,
    CardMedia,
    ClickAwayListener,
    Grid,
    IconButton,
    MenuItem,
    MenuList,
    Popover,
    Typography
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import DocumentService from '../../../Services/DocumentService';
import Events from '../../../Utility/Events';
import { ellipsis, formatDateTime, formatFilesize } from '../../../Utility/Formatter';
import { isThisMinute } from 'date-fns';
import { Folder } from '@mui/icons-material';

const useStyles = (theme) => ({
    card: {
        margin: theme.spacing(1)
    },
    cardHeader: {
        '& .MuiCardHeader-content': {
            maxWidth: '100%',
            overflow: 'hidden',
        }
    },
    media: {
        height: '150px',
        transform: 'unset'
    },
    mediaFallback: {
        textAlign: 'center',
        '& h2': {
            paddingTop: theme.spacing(5)
        }
    },
    gallery: {
        marginTop: theme.spacing(2),
        '& .MuiGrid-item': {
            marginBottom: theme.spacing(2),
        }
    },
    galleryplaceholder: {
        textAlign: 'center',
        marginTop: theme.spacing(2)
    }
});

class DocumentList extends Component {
    constructor(props) {
        super(props);

        this.state = {
            openedMenu: null,
            openedMenuAnchor: null,
        };

        this.anchorRef = React.createRef(null);
    }

    handleToggle = (e, id) => {
        const { openedMenu } = this.state;

        this.setState({
            openedMenu: openedMenu === id ? null : id,
            openedMenuAnchor: e.target
        });
    };

    handleDownloadFile = (e, item) => {
        const { t } = this.props;

        this.handleClose(e);
        DocumentService
            .download(item.id)
            .then(res => {
                if (res.data.url) {
                    window.open(res.data.url, '_blank');
                } else {
                    Events.dispatch('msgError', t('download-failed'));
                }
            })
            .catch(err => {
                Events.dispatch('msgError', t('download-failed'));
            });
    }
    
    handleClose = (e) => {
        if (this.anchorRef.current && this.anchorRef.current.contains(e.target)) {
            return;
        }
        this.setState({
            openedMenu: null,
            openedMenuAnchor: null,
        });
    }

    handleListKeyDown = (e) => {
        if (e.key === 'Tab') {
            e.preventDefault();
            this.setState({
                openedMenu: null
            });
        }
    }

    render() {
        const { t, classes, items, type, handleSwitchPath }   = this.props;
        const { openedMenu, openedMenuAnchor } = this.state;

        const getTitle = (item) => {
            let title = item.description ?? '';
            if (title === '') {
                title = item.filename;
            }
            return title;
        }

        const getTimestamp = (item) => {
            return formatDateTime(item.created ?? null);
        }

        const getExtension = (item) => {
            return <Typography variant="h2" color="secondary">.{item.filetype ?? ''}</Typography>;
        }

        const listing = () => {
            return (
                <Grid container className={classes.gallery} alignItems="center">
                    {items.map((item, index) => {
                        const isFolder = (typeof item.type === 'string' && item.type === 'folder') ? true : false;
                        return (
                            <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                                {isFolder
                                ? <Card className={classes.card} onClick={(e) => handleSwitchPath(e, item.path)}>
                                    <CardHeader className={classes.cardHeader}/>
                                    <CardContent>
                                        <Box
                                            className={classes.media}
                                            sx={{ marginBottom: '10px', textAlign: 'center' }}
                                        >
                                            <Folder sx={{ height: '150px', width: '150px' }}/>
                                        </Box>
                                        <Typography gutterBottom variant="h5" component="div">
                                            {item.label ?? item.name}
                                        </Typography>
                                        <Typography variant="body2" component="p">
                                            {item.sublabel ?? t('open-folder')}
                                        </Typography>
                                    </CardContent>
                                </Card>                            
                                : <React.Fragment>
                                    <Card className={classes.card}>
                                        <CardHeader
                                            className={classes.cardHeader}
                                            avatar={
                                                <Avatar className={classes.avatar}>
                                                    {type === 'documents' ? <span title={t('documents')}>D</span> : <span title={t('receipts')}>R</span>}
                                                </Avatar>
                                            }
                                            action={
                                                item.url && <Box>
                                                    <IconButton
                                                        aria-label="settings"
                                                        onClick={(e) => this.handleToggle(e, item.id)}
                                                        size="large">
                                                        <MoreVertIcon />
                                                    </IconButton>
                                                    <Popover 
                                                        open={(openedMenu && openedMenu === item.id) ? true : false}
                                                        anchorEl={openedMenuAnchor}
                                                        anchorOrigin={{
                                                            vertical: 'bottom',
                                                            horizontal: 'right',
                                                        }}
                                                        transformOrigin={{
                                                            vertical: 'top',
                                                            horizontal: 'right',
                                                        }}
                                                    >
                                                        <ClickAwayListener onClickAway={this.handleClose}>
                                                            <MenuList autoFocusItem={openedMenu && openedMenu === item.id} onKeyDown={this.handleListKeyDown}>
                                                                <MenuItem onClick={this.handleClose} href={item.url} component={'a'} target="_blank">{t('preview')}</MenuItem>
                                                                <MenuItem onClick={(e) => this.handleDownloadFile(e, item)}>{t('download')}</MenuItem>
                                                            </MenuList>
                                                        </ClickAwayListener>
                                                    </Popover>
                                                </Box>
                                            }
                                            title={ellipsis(getTitle(item))}
                                            subheader={ellipsis(getTimestamp(item))}
                                        />
                                        {item.thumbnail
                                            ?<CardMedia
                                                className={classes.media}
                                                image={item.thumbnail ?? null}
                                                title={getTitle(item)}
                                                {...(item.url ? { component: 'a', href: item.url, target: '_blank' } : {})}    
                                            />
                                            :<Box className={classes.media + " " + classes.mediaFallback}>{getExtension(item)}</Box>
                                        }
                                        <CardContent style={{ paddingBottom: 16 }}>
                                            <Grid container>
                                                <Grid item xs={12} style={{ textAlign: 'right', marginBottom: 0}}>
                                                    <Typography variant="body2" component="p">
                                                        {t('filesize')}: <strong>{formatFilesize(item.filesize ?? null)}</strong>
                                                    </Typography>
                                                    <Typography variant="body2" component="p">
                                                        {t('filetype')}: <strong>.{item.filetype ?? ''}</strong>
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </React.Fragment>}
                            </Grid>
                        );
                    })}
                </Grid>
            );
        }
    
        const placeholder = () => {
            return <Box>
                <Box className={classes.galleryplaceholder}>
                    <Alert severity="info">{t('no-'+type+'-uploaded')}</Alert>
                </Box>
            </Box>;
        }

        return (
            <Box>
                {items.length
                    ? listing()
                    : placeholder()
                }
            </Box>
        );
    }
}

DocumentList.defaultProps = {
    items: [],
    type: 'documents',
    handleSwitchPath: undefined,
};


const  TranslatedComponent = withTranslation('translation')(DocumentList);
export default withStyles(useStyles)(TranslatedComponent);