import RestAppService from "./RestAppService";

class SophyappService {
    list(params = {}) {
        return RestAppService
            .getCore("/sophyapp/programs", params);
    }
    get(id, params = {}) {
        return RestAppService
            .getCore("/sophyapp/programs/" + id, params);
    }
    sources(params = {}) {
        return RestAppService
            .getCore("/sophyapp/sources", params);
    }
    feedback(id, data = {}) {
            return RestAppService
            .post("/sophyapp/feedback/" + id, data);
    }
    feedbackTypes() {
        return RestAppService
            .getCore("/sophyapp/feedbackTypes");
    }
}

export default new SophyappService();