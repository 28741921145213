import { AccountCircle, Lock } from '@mui/icons-material';
import { Avatar, Box, Button, Container, Grid, TextField } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import MainContent from '../Elements/MainContent';

import { AuthContext } from '../Contexts/AuthContext';
import ConfiguredCustomText from '../Elements/ConfiguredCustomText';
import ChangePassword from '../Elements/Security/ChangePassword';

const useStyles = (theme) => ({
    avatar: {
        height: theme.spacing(20),
        width: theme.spacing(20),
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    submitBtn: {
        width: '100%'
    }
});

class Profile extends Component {
    constructor(props) {
        super(props);

        this.state = {
            changePassword: false,
        };
    }

    handleTogglePasswordMode = (e) => {
        const { changePassword } = this.state;
        this.setState({
            changePassword: !changePassword
        });
    }

    handlePasswordChangeSuccess = () => {
        this.setState({
            changePassword: false,
        });
    }
    
    render() {
        const { t, classes }   = this.props;
        const { changePassword }  = this.state;
        
        const inputProps = (name) => {
            let props = {
                fullWidth: true,
                margin: 'normal',
                disabled: true,
                name: name,
                label: t(name)
            };
            return props; 
        }

        return (
            <AuthContext.Consumer>
                {({ user }) => (
                    <MainContent
                        title={<ConfiguredCustomText fallback={t('profile')} section={'profile'} label={'title'}/>}
                        textPrimary={<ConfiguredCustomText fallback={t('below-your-profile-data')} section={'profile'} label={'primary'}/>}
                        textSecondary={<ConfiguredCustomText fallback={t('credentials-to-log-in')} section={'profile'} label={'secondary'}/>}
                    >
                        <Container>
                            <Grid container>
                                <Grid item xs={12} md={4}>
                                    <Box>
                                        <Avatar className={classes.avatar}>
                                            <AccountCircle className={classes.avatar} />
                                        </Avatar>
                                    </Box>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12} md={4} marginBottom={4}>
                                    <Container maxWidth={'xs'}>
                                        <Box>
                                            <Box><TextField {...inputProps('email')} value={user.email}/></Box>
                                            {user.mobile && <Box><TextField {...inputProps('mobile')} value={user.mobile}/></Box>}
                                        </Box>
                                        <Box marginTop={2}>
                                            <Button variant='contained' color='primary' disabled={changePassword} className={classes.submitBtn} onClick={this.handleTogglePasswordMode}><Lock />&nbsp;{t('change-password')}</Button>
                                        </Box>
                                    </Container>
                                </Grid>
                                <Grid item xs={12} md={8}>
                                    {changePassword && <ChangePassword successCallback={this.handlePasswordChangeSuccess}/>}
                                </Grid>
                            </Grid>
                        </Container>
                    </MainContent>
                )}
            </AuthContext.Consumer>
        );
    }
}

const  TranslatedComponent = withTranslation('translation')(Profile);
export default withStyles(useStyles)(TranslatedComponent);