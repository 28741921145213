import RestAppService from "./RestAppService";

class ConactService {
    get(params = {}) {
        return RestAppService
            .get("/contacts/get", params);
    }

    //TODO: cache
    getTypemap(params = {}) {
        return RestAppService
            .get("/contacts/getTypemap", params);
    }

    store(data = {}) {
        return RestAppService
            .put("/contacts/store", data);
    }
}

export default new ConactService();