import { Visibility as VisibilityIcon, VisibilityOff as VisibilityOffIcon } from '@mui/icons-material';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import PasswordStrengthMeter from './PasswordStrengthMeter';

const useStyles = (theme) => ({
    input: {
        width: '100%'
    },
});

class PasswordCreateField extends Component {
    constructor(props) {
        super(props);

        this.state = {
            focused: false,
            showPassword: false,
        };
    }

    handleChange = (e) => {
        const { onChange } = this.props;
        if (typeof onChange === 'function') {
            onChange(e);
        }
    }

    handleFocus = (e) => {
        this.setState({
            focused: true,
        });
        const { onFocus } = this.props;
        if (typeof onFocus === 'function') {
            onFocus(e);
        }
    }

    handleBlur = (e) => {
        this.setState({
            focused: false
        });
        const { onBlur } = this.props;
        if (typeof onBlur === 'function') {
            onBlur(e);
        }
    }

    handleClickShowPassword = (e) => {
        this.setState({
            showPassword: !this.state.showPassword
        });
    }

    handleOnKeyPress = (e) => {
        if (typeof this.props.onKeyPress === 'function') {
            this.props.onKeyPress(e);
        }
    }

    render() {
        const { field, value, required, autoFocus, error, classes, t, disableStrengthMeter, className } = this.props;
        const { focused, showPassword } = this.state;


        return (
            <React.Fragment>
                <TextField
                    margin="normal"
                    id={field}
                    name={field}
                    label={t(field)}
                    type={showPassword ? 'text' : 'password'}
                    error={error}
                    onChange={this.handleChange}
                    onFocus={this.handleFocus}
                    onBlur={this.handleBlur}
                    onKeyPress={this.handleOnKeyPress}
                    value={value}
                    className={className}
                    fullWidth
                    autoFocus={autoFocus}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    tabIndex={-1}
                                    aria-label="toggle password visibility"
                                    onClick={this.handleClickShowPassword}
                                    size="large">
                                {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                />
                {disableStrengthMeter === false && <PasswordStrengthMeter password={value} hasFocus={focused}/>}
            </React.Fragment>
        );
    }
}

PasswordCreateField.defaultProps = {
    field: null,
    value: '',
    onChange: null,
    onFocus: null,
    onBlur: null,
    onKeyPress: null,
    required: true,
    error: undefined,
    autoFocus: false,
    disableStrengthMeter: false,
    className: ''
};


const TranslatedPasswordRequest = withTranslation('translation')(PasswordCreateField);
export default withStyles(useStyles)(TranslatedPasswordRequest);