import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import { ButtonGroup } from '@mui/material';
import { Accordion, AccordionDetails, AccordionSummary, Alert, Box, Button, ButtonBase, Grid, LinearProgress, Typography, Chip } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import {
    CategoryScale, Chart as ChartJS, Legend, LinearScale, LineElement, PointElement, Title,
    Tooltip
} from 'chart.js';
import React, { Component } from 'react';
import { Line } from 'react-chartjs-2';
import { withTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import SophyappService from '../../Services/SophyappService.js';
import CachingHelper from '../../Utility/CachingHelper.js';
import Events from '../../Utility/Events.js';
import { formatDateWithDay } from '../../Utility/Formatter.js';
import { AuthContext } from '../Contexts/AuthContext.js';
import ConfiguredCustomText from '../Elements/ConfiguredCustomText.js';
import MainContent from '../Elements/MainContent.js';

const useStyles = (theme) => ({
    url: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
    },
    listItem: {
        maxWidth: '100%',
        position: 'relative',
        '& .MuiAccordionSummary-content': {
            maxWidth: '100%',
            position: 'relative',
        }
    },
    linechart: {
        height: 300,
    }
});

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

class SophyappList extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            programs: CachingHelper.get('programlist') || [],
            expanded: [0],
            listType: 'active',
        };
    }

    handleAccordionChange = (index) => {
        let { expanded } = this.state;

        if (expanded.includes(index)) {
            expanded = expanded.filter(i => i !== index);
        } else {
            expanded.push(index);
        }

        this.setState({
            expanded: expanded
        });
    }

    componentDidMount() {
        this.loadData();
    }

    loadData = () => {
        const { t } = this.props;
        const { listType } = this.state;

        Events.dispatch('startLoading');

        SophyappService
            .list({
                type: listType
            })
            .then(response => {
                const body = response.data;
                this.setState({
                    programs: body
                }, () => {
                    CachingHelper.set('programlist', body);
                });
                Events.dispatch('doneLoading');
            })
            .catch(err => {
                Events.dispatch('msgError', t('failed-to-fetch-data'));
                Events.dispatch('doneLoading');
            });
    }

    handleChangeListType = (e, listType) => {
        const { listTypes } = this.props;

        if (listTypes.includes(listType)) {
            this.setState({
                listType: listType,
            }, () => {
                this.loadData();
            });
        }
    }

    render() {
        const { t, classes, listTypes } = this.props;
        const { programs, expanded, listType } = this.state;

        const getChartOptions = (item) => {
            return {
                responsive: true,
                maintainAspectRatio: false,
                scaleShowVerticalLines: false,
                scales: {
                    y: {
                        min: 0,
                        max: 10,
                    },
                    x: {
                        grid: {
                            display: false
                        }
                    }
                }
            };
        }

        const getChartData = (item) => {
            const { theme } = this.props;

            const chartdata = item.chartdata ?? {};
            const labels    = chartdata.labels ?? [];
            const datasets  = chartdata.datasets ?? {};
            const painlevel = datasets['painlevel'] ?? []; 

            return {
                labels,
                datasets: [
                    {
                        label: t('painlevel'),
                        data: painlevel,
                        spanGaps: true,
                        borderColor: theme.palette.primary.main,
                        backgroundColor: theme.palette.primary.main,
                    }
                ],
            };
        }

        const programStillActive = (item) => {
            if (typeof item.is_active !== 'undefined') {
                if (item.is_active) {
                    return true;
                }
            }
            return false;
        }

        const getTitle = (item) => {
            return <Box sx={{ display: 'flex' }}>
                <Box flexGrow={1}>{item.name ?? ''}</Box>
                <Box flexShrink={1} sx={{ marginRight: 2 }}>{programStillActive(item) ? <Chip color="success"  variant="outlined" label={t('active')}/> : <Chip color="warning" variant="outlined" label={t('done')}/>}</Box>
            </Box>;
        }


        return (
            <AuthContext.Consumer>
                {({ clientConfigurations }) => (
                    <MainContent
                        title={<ConfiguredCustomText fallback={t('exercises')} section={'programs'} label={'title'}/>}
                        textPrimary={<ConfiguredCustomText fallback={t('text-head-programs')} section={'programs'} label={'primary'}/>}
                        textSecondary={<ConfiguredCustomText fallback={''} section={'programs'} label={'secondary'}/>}
                    >
                        <Grid container sx={{ marginBottom: 2 }}>
                            <Grid item xs={12} textAlign='right'>
                                <ButtonGroup>
                                    {listTypes.map((i, index) => {
                                        return <Button key={'date-type-btn-'+i} variant="contained" onClick={(e) => this.handleChangeListType(e, i)} color={listType === i ? 'primary' : 'secondary'}>
                                            {t(i)}
                                        </Button>
                                    })}
                                </ButtonGroup>
                            </Grid>
                        </Grid>
                        {programs.length > 0
                        ? programs.map((item, index) => {
                            return <React.Fragment key={"program-"+item.id ?? index}>
                                <Accordion expanded={expanded.includes(index)}>
                                    <AccordionSummary 
                                        className={classes.listItem}
                                        expandIcon={<ExpandMoreIcon onClick={() => this.handleAccordionChange(index)} />}
                                    >
                                        <ButtonBase
                                            sx={{ width: '100%', position: 'relative' }}
                                            title={t('open program')}
                                            component={RouterLink} 
                                            to={"/programs/" + item.id}
                                        >
                                            <Grid container>
                                                <Grid item xs={12} md={6}>
                                                    <Typography variant='h6'>{getTitle(item)}</Typography>
                                                    <Typography color='secondary' variant='p'>
                                                        {item.description ?? ''}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12} md={6}>
                                                    <Box>
                                                        <Typography color='secondary' variant='p'>
                                                            {t('frequency') + ': ' + (item.frequency ?? '') + ' ' + t('times per week')}
                                                        </Typography>
                                                    </Box>
                                                    <Box>
                                                        <Typography color='secondary' variant='p'>
                                                            {t('goal') + ': ' + (item.goal ?? '')}
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </ButtonBase>
                                    </AccordionSummary>                                   
                                    <AccordionDetails>
                                        <Box>
                                            <Box sx={{ display: 'flex', width: '100%' }}>
                                                <Box flexGrow={1}>
                                                    <Typography variant='h6'>
                                                        {t('progress')}: {item.progress}%
                                                    </Typography>
                                                </Box>
                                                <Box flexShrink={1} alignSelf={'flex-end'}>
                                                    <Typography color='secondary' variant='p'>
                                                        {t('ends at')}: {formatDateWithDay(item.ends_at ?? null)}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                            <LinearProgress variant="determinate" value={item.progress ?? 0}/>
                                            <Box className={classes.linechart}>
                                                <Line options={getChartOptions(item)} data={getChartData(item)}/>
                                            </Box>
                                        </Box>
                                        <Box textAlign={'right'} sx={{ marginTop: 1 }}>
                                            <Button variant="contained" component={RouterLink} to={"/programs/" + item.id}>{t('open program')}</Button>
                                        </Box>
                                    </AccordionDetails>
                                </Accordion>
                            </React.Fragment>;
                        })
                        :<Box className={classes.galleryplaceholder}>
                            <Alert severity="info">{t('no-programs-available')}</Alert>
                        </Box>}
                    </MainContent>
                )}
            </AuthContext.Consumer>
        );
    }
}
SophyappList.defaultProps={
    listTypes: ['active', 'done']
};

const  TranslatedComponent = withTranslation('translation')(SophyappList);
export default withStyles(useStyles, { withTheme: true })(TranslatedComponent);