import { AccountCircle, ExitToApp as LogoutIcon, NavigateBefore, NavigateNext, SwitchAccount } from '@mui/icons-material';
import { Avatar, Box, Collapse, Divider, IconButton, Menu, MenuItem, Slide, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import { Link } from 'react-router-dom';
import AuthService from '../../Services/AuthService';
import Events from '../../Utility/Events';
import history from '../../Utility/RouterHistory';
import { AuthContext } from '../Contexts/AuthContext';

const languages = {
    de: 'german',
    en: 'english'
};

const useStyles = (theme) => ({
    profilebtn: {
        borderRadius: '5px',
    },
    profilemenu: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
        '& svg': {
            marginRight: '10px',
        },
        '& ul': {
            paddingTop: 0,
            paddingBottom: 0,
        }
    },
    languageFlag: {
        height: theme.spacing(2.5),
        width: theme.spacing(2.5),
        marginLeft: theme.spacing(0.25),
        marginRight: theme.spacing(1.25)
    },
    menuAvatarBox: {
        textDecoration: 'none',
        color: 'inherit',
    },
    menuAvatar: {
        width: theme.spacing(10),
        height: theme.spacing(10),
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
    },
    menuAvatarCaption: {
        textAlign: 'center',
        marginBottom: theme.spacing(2),
        width: '250px'
    },
});

class ProfileMenu extends Component {
    constructor(props) {
        super(props);

        this.state = {
            profileMenuAnchor: null,
            languageSubmenu: false,
        };

        this.profileMenuRef = React.createRef(null);
    }    

    handleLogout = (e, logoutCallback) => {
        const { t } = this.props;

        this.handleProfileMenuClose();
        logoutCallback()
            .then(res => {
                Events.dispatch('msgSuccess', t('successfull-logged-out'));
            });
    }

    handleChangeClient = (e, changeClientCallback) => {
        changeClientCallback(null)
            .then(res => {
                history.push('/client');
            });
    }

    handleProfileMenu = (e) => {
        this.setState({
            profileMenuAnchor: e.currentTarget
        });
    }

    handleProfileMenuClose = () => {
        this.setState({
            profileMenuAnchor: null,
            languageSubmenu: false,
        });
    }

    handleClickLanguageSubmenu = (e) => {
        this.setState({
            languageSubmenu: !this.state.languageSubmenu,
        });
    }

    handleSelectLanguage = (e, lang) => {
        const { i18n } = this.props;

        i18n.changeLanguage(lang);
        AuthService.updateLanguage(lang);
    }

    render() {
        const { t, i18n, classes }   = this.props;
        const { tab, profileMenuAnchor, languageSubmenu, showDSGVO } = this.state;
        const profileMenuOpen = Boolean(profileMenuAnchor);

        const getLanguageFlag = (language) => {
            let langShort = language.substring(0,2);
            return <Avatar className={classes.languageFlag} src={process.env.PUBLIC_URL + '/images/languages/' + langShort +  '.png'} alt={language}/>
        }

        return <AuthContext.Consumer>
            {({ contact, logout, changeClient, associations }) => (
                <Box className={classes.profile}>
                    <IconButton
                        onClick={this.handleProfileMenu}
                        color="inherit"
                        className={classes.profilebtn}
                        size="large">
                        <Typography>
                            {contact && contact.firstname}
                        </Typography>
                        <AccountCircle sx={{ marginLeft: 1 }}/>
                    </IconButton>
                    <Menu
                        className={classes.profilemenu}
                        id="menu-appbar"
                        anchorEl={profileMenuAnchor}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={profileMenuOpen}
                        onClose={this.handleProfileMenuClose}
                        ref={this.profileMenuRef}
                    >
                        <Box 
                            className={classes.menuAvatarBox}
                            component={Link} to="/profile"
                            onClick={this.handleProfileMenuClose}
                        > 
                            <Avatar className={classes.menuAvatar}>
                                {contact
                                    ? contact.firstname.charAt(0)
                                    : ''
                                }
                            </Avatar>
                            <Box className={classes.menuAvatarCaption}>
                                <Typography variant="body2" component="p">
                                    {contact ? (contact.firstname + ' ' + contact.lastname) : ''}
                                </Typography>
                                <AuthContext.Consumer>
                                    {({ user }) => (
                                        <Typography variant="body2" component="p" color="secondary">
                                            {user ? user.email : ''}
                                        </Typography>
                                    )}
                                </AuthContext.Consumer>
                            </Box>
                        </Box>
                        <Divider/>
                        <Slide in={!languageSubmenu} direction='right' container={this.profileMenuRef.current}>
                            <Collapse in={!languageSubmenu}>
                                <Box>
                                    <MenuItem onClick={this.handleProfileMenuClose} component={Link} to="/profile">
                                        <AccountCircle/>
                                        {t('profile')}
                                    </MenuItem>
                                    <MenuItem onClick={this.handleClickLanguageSubmenu}>
                                        <Box sx={{ display: 'flex', width: '100%', alignItems: 'center' }}>
                                            <Box flexShrink={1}>{getLanguageFlag(i18n.language)}</Box>
                                            <Box flexGrow={1}>{t('language')}</Box>
                                            <Box flexShrink={1}><NavigateNext /></Box>
                                        </Box>
                                    </MenuItem>
                                    {associations.length > 1 && <MenuItem onClick={(e) => this.handleChangeClient(e, changeClient)}>
                                        <SwitchAccount/>
                                        {t('switch-provider')}
                                    </MenuItem>}
                                    <MenuItem onClick={(e) => this.handleLogout(e, logout)}>
                                        <LogoutIcon/>
                                        {t('logout')}
                                    </MenuItem>
                                </Box>
                            </Collapse>
                        </Slide>
                        <Slide in={languageSubmenu} direction='left' container={this.profileMenuRef.current}>
                            <Collapse in={languageSubmenu}>
                                <Box>
                                    <MenuItem onClick={this.handleClickLanguageSubmenu}>
                                        <NavigateBefore />
                                        {t('back')}
                                    </MenuItem>
                                    <Divider className={classes.minimumDivider}/>
                                    {(Object.entries(languages)).map(([i, v]) => {
                                        return <MenuItem key={'language-sel-'+i} onClick={(e) => this.handleSelectLanguage(e, i)}>
                                            {getLanguageFlag(i)}
                                            {t(v)}
                                        </MenuItem>;
                                    })}
                                </Box>
                            </Collapse>
                        </Slide>
                    </Menu>
                </Box>
            )}
        </AuthContext.Consumer>;
    }
}

const  TranslatedComponent = withTranslation('translation')(ProfileMenu);
export default withStyles(useStyles)(TranslatedComponent);