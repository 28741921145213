import {
    Backdrop,
    CircularProgress, CssBaseline,
    Snackbar
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import withStyles from '@mui/styles/withStyles';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Redirect, Router, Switch } from 'react-router-dom';
import Events from '../../Utility/Events.js';
import RouteLayoutMain from '../../Utility/RouteLayoutMain.js';
import RouteLayoutMinimal from '../../Utility/RouteLayoutMinimal.js';
import history from '../../Utility/RouterHistory';
import AuthContextProvider, { AuthContext } from '../Contexts/AuthContext.js';
import CustomThemeProvider, { CustomThemeContext } from '../Contexts/CustomThemeContext.js';
import DsgvoPublic from '../Elements/DSGVO/DsgvoPublic.js';
import AppointmentBooking from '../Secured/AppointmentBooking.js';
import Appointments from '../Secured/Appointments.js';
import Basedata from '../Secured/Basedata.js';
import Bookmarks from '../Secured/Bookmarks.js';
import Documents from '../Secured/Documents.js';
import Profile from '../Secured/Profile.js';
import Questionaires from '../Secured/Questionaires.js';
import QuestionairesData from '../Secured/QuestionairesData.js';
import Receipts from '../Secured/Receipts.js';
import SophyappList from '../Secured/SophyappList.js';
import SophyappProgram from '../Secured/SophyappProgram.js';
import ChooseClient from '../Unsecured/ChooseClient.js';
import CreateAccount from '../Unsecured/CreateAccount.js';
import Login from '../Unsecured/Login.js';
import PasswordChange from '../Unsecured/PasswordChange.js';
import PasswordRequest from '../Unsecured/PasswordRequest.js';
import Register from '../Unsecured/Register.js';
import VerifyMsg from '../Unsecured/VerifyMsg.js';
import Watchdog from '../Unsecured/Watchdog.js';

const useStyles = (theme) => ({
    backdrop: {
        zIndex: 999,
    },
    spinner: {
        width: '200px !important',
        height: '200px !important'
    }
});

class Authenticator extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            alertShow: false,
            alertType: 'error',
            alertMsg: '',
            backdropOpen: false,
            renderings: 1,
            initialPagePath: null,
        };
    }

    componentDidMount() {
        Events.on("msgSuccess", (msg) => {
            this.handleMsgType(msg, 'success');
        });
        Events.on("msgError", (msg) => {
            this.handleMsgType(msg, 'error');
        });
        Events.on("msgWarning", (msg) => {
            this.handleMsgType(msg, 'warning');
        });
        Events.on("msgInfo", (msg) => {
            this.handleMsgType(msg, 'info');
        });
        Events.on("startLoading", () => {
            this.setState({
                backdropOpen: true,
            });
        });
        Events.on("doneLoading", () => {
            this.setState({
                backdropOpen: false,
            });
        });

        if (history.location) {
            if (history.location.pathname !== '/') {
                const initialPagePath = history.location.pathname.split('/')[1];
                this.setState({
                    initialPagePath: initialPagePath,
                });
            }
        }
    }

    componentWillUnmount() {
        Events.remove("msgSuccess");
        Events.remove("msgError");
        Events.remove("msgWarning");
        Events.remove("msgInfo");
        Events.remove("startLoading");
        Events.remove("doneLoading");
    }
    
    handleMsgType = (msg, type) => {
        this.setState({
            alertShow: true,
            alertType: type,
            alertMsg: msg
        });
    }
    
    handleClose = (e, reason) => {
        this.setState({
            alertShow: false,
        });
    }

    render() {
        const { t, classes } = this.props;
        const { alertShow, alertType, alertMsg, backdropOpen } = this.state;

        const isRegistrationEnabled = (currentClient, currentPublicClientConfigurations) => {
            return (!currentClient || (currentPublicClientConfigurations.registration ?? true));
        }

        const getDefaultComponent = (tabs, clientConfigurations) => {
            let first = tabs[0] ?? 'profile';

            const startPage = clientConfigurations.portal_start_page ?? false;
            if (startPage) {
                if (startPage === 'profile' || tabs.includes(startPage)) {
                    first = startPage;
                }
            }

            const { initialPagePath } = this.state;
            if (initialPagePath && typeof initialPagePath === 'string' && initialPagePath !== '/') {
                if (initialPagePath === 'profile' || tabs.includes(initialPagePath)) {
                    this.setState({
                        initialPagePath: null,
                    }, () => {
                        if (!localStorage.getItem('booking-'+parseInt(clientConfigurations.main_client_number))) {
                            history.push('/' + initialPagePath);
                        }
                    });
                }
            }

            const map = {
                basedata: Basedata,
                questionaires: Questionaires,
                appointments: Appointments,
                documents: Documents,
                receipts: Receipts,
                profile: Profile,
            };
            return map[first];
        }

        const hasScope = (tabs, scope) => {
            if (tabs.includes(scope)) {
                return true;
            }
            return false;
        }

        const getLayout = (initial, user, client) => {
            let layout;
            if (initial === false) {
                layout = <CustomThemeContext.Consumer>
                    {({ currentClient, currentPublicClientConfigurations }) => (
                        <AuthContext.Consumer>
                            {({ isAuthenticated, tabs, clientConfigurations, hasSophyappAccess }) => (
                                <Router history={history} basename={process.env.PUBLIC_URL}>
                                    <Switch>
                                        <RouteLayoutMain exact path='/appointments/booking/:clientNumber/:deeplink' component={AppointmentBooking} />
                                        <RouteLayoutMain exact path='/appointments/booking/:clientNumber' component={AppointmentBooking} />
                                        <RouteLayoutMain exact path='/appointments/booking' component={AppointmentBooking} />
                                        {(isAuthenticated() && hasScope(tabs, 'programs') && hasSophyappAccess()) && <RouteLayoutMain exact path='/programs/:id' component={SophyappProgram} />}
                                        {(isAuthenticated() && hasScope(tabs, 'programs') && hasSophyappAccess()) && <RouteLayoutMain exact path='/programs' component={SophyappList} />}
                                        {(isAuthenticated() && hasScope(tabs, 'bookmarks')) && <RouteLayoutMain exact path='/bookmarks' component={Bookmarks} />}
                                        {(isAuthenticated() && hasScope(tabs, 'appointments')) && <RouteLayoutMain exact path='/appointments' component={Appointments} />}
                                        {(isAuthenticated() && hasScope(tabs, 'documents')) && <RouteLayoutMain exact path='/documents' component={Documents} />}
                                        {(isAuthenticated() && hasScope(tabs, 'receipts')) && <RouteLayoutMain exact path='/receipts' type='receipts' component={Receipts}/>}
                                        {isAuthenticated() && <RouteLayoutMain exact path='/profile' component={Profile} />}
                                        {(isAuthenticated() && hasScope(tabs, 'questionaires')) && <RouteLayoutMain path='/questionaires/:id' component={QuestionairesData} />}
                                        {(isAuthenticated() && hasScope(tabs, 'questionaires')) && <RouteLayoutMain exact path='/questionaires' component={Questionaires} />}
                                        {(isAuthenticated() && hasScope(tabs, 'basedata')) && <RouteLayoutMain exact path='/basedata' component={Basedata} />}
                                        {isRegistrationEnabled(currentClient, currentPublicClientConfigurations) && <RouteLayoutMinimal path='/verify' component={VerifyMsg} />}
                                        {isRegistrationEnabled(currentClient, currentPublicClientConfigurations) && <RouteLayoutMinimal path='/create/:code/:joincode' component={CreateAccount} />}
                                        {isRegistrationEnabled(currentClient, currentPublicClientConfigurations) && <RouteLayoutMinimal path='/create/:code' component={CreateAccount} />}
                                        <RouteLayoutMinimal exact path='/register/:clientNumber/:joincode' component={Register} />
                                        <RouteLayoutMinimal exact path='/register/:clientNumber' component={Register} />
                                        {isRegistrationEnabled(currentClient, currentPublicClientConfigurations) && <RouteLayoutMinimal path='/register' component={Register} />}
                                        <RouteLayoutMinimal path='/password-request' component={PasswordRequest} />
                                        <RouteLayoutMinimal path='/password-change/:code' component={PasswordChange} />
                                        {!isAuthenticated() && <RouteLayoutMinimal path='/client/:clientNumber' component={ChooseClient} />}
                                        {!isAuthenticated() && <RouteLayoutMinimal exact path='/client' component={ChooseClient} />}
                                        <RouteLayoutMain exact path='/dsgvo/:clientNumber' component={DsgvoPublic}/>
                                        {isAuthenticated() &&<RouteLayoutMain exact path='/login/:clientNumber' component={Watchdog}/>}
                                        {isAuthenticated() &&<RouteLayoutMain exact path='/register/:clientNumber' component={Watchdog}/>}
                                        {!isAuthenticated() && <RouteLayoutMinimal exact path='/login/:clientNumber' component={Login} />}
                                        {isAuthenticated()
                                            ? <RouteLayoutMain exact path='/' component={getDefaultComponent(tabs, clientConfigurations)} />
                                            : <RouteLayoutMinimal exact path='/' component={Login} />
                                        }
                                        <Redirect to={{pathname:'/'}} />
                                    </Switch>
                                </Router>
                            )}          
                        </AuthContext.Consumer>
                    )}
                </CustomThemeContext.Consumer>;
            }
            return layout;
        }

        const getSpinner =  (clConfig) => {
            if (clConfig) {
                if (typeof clConfig.portal_custom_theme_spinner === 'string') {
                    return <img src={clConfig.portal_custom_theme_spinner} className={classes.spinner}/>;
                }
            }
            return <CircularProgress color="primary" className={classes.spinner}/>;
        }

        return (
            <CustomThemeProvider>
                <AuthContextProvider>
                    <CssBaseline />
                    <Snackbar open={alertShow} autoHideDuration={3000} onClose={this.handleClose} anchorOrigin={{ vertical:'top', horizontal:'center' }}>
                        <MuiAlert elevation={6} variant="filled" severity={alertType}>
                            {t(alertMsg)}
                        </MuiAlert>
                    </Snackbar>
                    <AuthContext.Consumer>
                        {({ initial, user, client }) => (
                            <React.Fragment>
                                <CustomThemeContext.Consumer>
                                    {({currentPublicClientConfigurations}) => (
                                        <Backdrop open={backdropOpen || initial} className={classes.backdrop}>
                                            {getSpinner(currentPublicClientConfigurations)}
                                        </Backdrop>
                                    )}
                                </CustomThemeContext.Consumer>
                                {getLayout(initial, user, client)}
                            </React.Fragment>
                        )}
                    </AuthContext.Consumer>
                </AuthContextProvider>
            </CustomThemeProvider>
        );
    }
}

const TranslatedComponent =  withTranslation('translation')(Authenticator);
export default withStyles(useStyles)(TranslatedComponent);