import { Box, Button, Container } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import AuthService from '../../../Services/AuthService';
import Events from '../../../Utility/Events';
import PasswordRequirements from '../../../Utility/PasswordRequirements';
import CheckLabel from '../CheckLabel';
import PasswordCreateField from '../PasswordCreateField';


const useStyles = (theme) => ({
    avatar: {
        height: theme.spacing(20),
        width: theme.spacing(20),
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    submitBtn: {
        width: '100%'
    }
});

class ChangePassword extends Component {
    constructor(props) {
        super(props);

        this.state = {
            oldPassword: '',
            newPassword: '',
            newPasswordRepeat: '',
            newPasswordsMatch: true,
            oldPasswordError: false,
            newPasswordError: false,
        };
    }


    handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;

        this.setState({
            [name]: value
        });
    }    

    componentDidUpdate(prevProps, prevState) {
        const { newPassword, newPasswordRepeat } = this.state;
        if (prevState.newPassword !== newPassword || prevState.newPasswordRepeat !== newPasswordRepeat) {
            let newPasswordsMatch = true;
            if (newPassword !== newPasswordRepeat) {
                newPasswordsMatch = false;
            } 
            this.setState({
                newPasswordsMatch: newPasswordsMatch,
            });
        }
    }

    handleSubmit = (e) => {
        const { t } = this.props;
        const { oldPassword, newPassword, newPasswordRepeat } = this.state;
        this.setState({
            oldPasswordError: false,
            newPasswordError: false,
        });

        let oldPasswordError = !PasswordRequirements.isValidPassword(oldPassword);
        let newPasswordError = (!PasswordRequirements.isValidPassword(newPassword) || !PasswordRequirements.isValidPassword(newPasswordRepeat));

        new Promise((resolve, reject) => {
            if (!oldPasswordError && !newPasswordError) {
                AuthService
                    .passwordChangeManually(oldPassword, newPassword, newPasswordRepeat)
                    .then(res => {
                        const { successCallback } = this.props;
                        if (typeof successCallback === 'function') {
                            successCallback();
                        }
                        Events.dispatch('msgSuccess', t('password-change-successfull'));
                        resolve();
                    })
                    .catch(res => {
                        oldPasswordError = true;
                        newPasswordError = true;
                        Events.dispatch('msgError', t('password-change-failed'));
                        resolve();
                    });
            } else {
                resolve();
            }
        }).then(r => {
            const self = this;
            setTimeout(() => {
                self.setState({
                    oldPasswordError: oldPasswordError,
                    newPasswordError: newPasswordError,
                });
            }, 100);
        });
    }
    
    
    render() {
        const { t, classes }   = this.props;
        const { newPasswordsMatch, oldPasswordError, newPasswordError }  = this.state;
        
        const passwordField = (field, params = {}) => {
            return <PasswordCreateField field={field} value={this.state[field] ?? ''} onChange={this.handleChange} {...params}/>;
        }

        const isSubmitValid = () => {
            return false;
        }

        return (
            <Container maxWidth={'xs'} sx={{ paddingBottom: 4 }}>
                <form noValidate onSubmit={this.handleSubmit}>
                    {passwordField('oldPassword', { disableStrengthMeter: false, className: (oldPasswordError ? 'shake' : ''), error: (oldPasswordError? true : undefined) })}
                    {passwordField('newPassword', { className: (newPasswordError ? 'shake' : ''), error: (newPasswordError? true : undefined) })}
                    {passwordField('newPasswordRepeat', { className: (newPasswordError ? 'shake' : ''), error: (newPasswordError? true : undefined) })}
                    {newPasswordsMatch === false && <Box>
                        <CheckLabel value={false} label={t('passwords-dont-match')}/>    
                    </Box>}
                    <Box marginTop={2}>
                        <Button variant='contained' color='primary' disabled={isSubmitValid()} onClick={this.handleSubmit} className={classes.submitBtn}>{t('save')}</Button>
                    </Box>
                </form>
            </Container>
        );
    }
}

ChangePassword.defaultProps = {
    successCallback: null,
};

const  TranslatedComponent = withTranslation('translation')(ChangePassword);
export default withStyles(useStyles)(TranslatedComponent);