import axios from "axios";

const getAuthHeader = () => {
    let token = localStorage.getItem('access-token');
    return {
        Authorization: "Bearer " + token,
    };
}

const getPublicAuthHeader = () => {
    let token = localStorage.getItem('public-token');
    return {
        Authorization: "Bearer " + token,
    };
}

const getDefaultHeaders = () => {
    return {
        'Accept': 'application/json',
    };
}


class AuthService {
    async login(username, password) {
        return axios
            .post(process.env.REACT_APP_PORTAL_API_URL + "users/token", {
                username: username,
                password: password,
                appkey: process.env.REACT_APP_PORTAL_API_KEY
            })
            .then(response => {
                if (typeof response.data.token !== 'undefined') {
                    localStorage.setItem("access-token", response.data.token);
                }
                if (typeof response.data.client !== 'undefined' && response.data.client) {
                    this.loadTheme();
                }
                return response.data;
            });
    }

    async check(username, password) {
        return axios
            .post(process.env.REACT_APP_PORTAL_API_URL + "users/token", {
                username: username,
                password: password,
                appkey: process.env.REACT_APP_PORTAL_API_KEY
            });
    }
    
    async clients () {
        return axios.get(process.env.REACT_APP_PORTAL_API_URL + "clients/configurations", {
            headers: {
                ...getDefaultHeaders(),
                ...getAuthHeader()
            },
            params: {
                appkey: process.env.REACT_APP_PORTAL_API_KEY,
            },
        });
    }

    logout() {
        localStorage.removeItem("access-token");
    }

    async register(code, user, contact) {
        return axios.post(process.env.REACT_APP_PORTAL_API_URL + "users/register", {
            code: code,
            user: user,
            contact: contact,
        }, {
            headers: {
                ...getDefaultHeaders(),
                ...getPublicAuthHeader()
            }
        }).then(response => {
            if (typeof response.data.token !== 'undefined') {
                localStorage.setItem("access-token", response.data.token);
            }
            return response;
        });
    }
    async updateLanguage(language) {
        return axios
            .post(process.env.REACT_APP_PORTAL_API_URL + "users/updateLanguage", {
                language: language
            }, {
                headers: {
                    ...getDefaultHeaders(),
                    ...getAuthHeader()
                }
            })
            .then(response => {
                if (typeof response.data.token !== 'undefined') {
                    localStorage.setItem("access-token", response.data.token);
                }
            });
    }

    async verificationCheck(code) {
        this.clearAccessToken();
        return axios.post(process.env.REACT_APP_PORTAL_API_URL + "verifications/check", {
            code: code,
            appkey: process.env.REACT_APP_PORTAL_API_KEY,
        }, {
            headers: {
                ...getDefaultHeaders(),
            }
        }).then(response => {
            return response.data;
        });
    }

    async verificationRequest(params) {
        return axios.post(process.env.REACT_APP_PORTAL_API_URL + "verifications/create", params, {
            headers: {
                ...getDefaultHeaders(),
                ...getPublicAuthHeader()
            }
        });
    }

    async inlineVerificationRequest(params) {
        return axios.post(process.env.REACT_APP_PORTAL_API_URL + "verifications/createInline", params, {
            headers: {
                ...getDefaultHeaders(),
                ...getAuthHeader()
            }
        });
    }

    async refresh(userClientId = null, contactId = null) {
        var data = {
            appkey: process.env.REACT_APP_PORTAL_API_KEY,
        };
        if (userClientId) {
            data['user_client_id'] = userClientId;
            if (contactId) {
                data['contact_id'] = contactId;
            }
        }

        return axios
            .post(process.env.REACT_APP_PORTAL_API_URL + "users/refreshToken", data, {
                headers: {
                    ...getDefaultHeaders(),
                    ...getAuthHeader()
                }
            }).then(response => {
                if (typeof response.data.token !== 'undefined') {
                    localStorage.setItem("access-token", response.data.token);
                }
                return this.loadTheme()
                            .then(r => {
                                return response.data
                            });
            });
    }

    async passwordRequest (username) {
        return axios
            .post(process.env.REACT_APP_PORTAL_API_URL + "users/passwordRequest", {
                username: username,
                appkey: process.env.REACT_APP_PORTAL_API_KEY
            })
            .then(response => {
                return response.data;
            });
    }

    async passwordChange (resetId, password, passwordRepeat) {
        return axios
            .post(process.env.REACT_APP_PORTAL_API_URL + "users/passwordChange", {
                resetId: resetId,
                password: password,
                passwordRepeat: passwordRepeat,
                appkey: process.env.REACT_APP_PORTAL_API_KEY
            })
            .then(response => {
                return response.data;
            });
    }

    async passwordChangeManually (oldPassword, newPassword, newPasswordRepeat) {
        return axios
            .post(process.env.REACT_APP_PORTAL_API_URL + "users/passwordChangeManually", {
                oldPassword: oldPassword,
                newPassword: newPassword,
                newPasswordRepeat: newPasswordRepeat,
            }, {
                headers: {
                    ...getDefaultHeaders(),
                    ...getAuthHeader()
                }
            });
    }

    clearAccessToken () {
        localStorage.removeItem('access-token');
    }

    getAccessToken() {
        return localStorage.getItem('access-token');
    }

    clearActiveClientNumber () {
        return localStorage.removeItem("active-client");
    }

    getActiveClientNumber () {
        if (localStorage.getItem("active-client")) {
            return localStorage.getItem("active-client");
        }
        return null;
    }

    getActiveContactId () {
        if (localStorage.getItem("active-contact-id")) {
            return localStorage.getItem("active-contact-id");
        }
        return null;
    }

    async privacyStatement () {
        return axios
            .get(process.env.REACT_APP_PORTAL_API_URL + "privacy/statement", { 
                headers: {
                    ...getDefaultHeaders(),
                    ...getPublicAuthHeader()
                }
            });
    }

    async setPrivacyConsent () {
        return axios
            .post(process.env.REACT_APP_PORTAL_API_URL + "privacy/accept", {}, {
                headers: {
                    ...getDefaultHeaders(),
                    ...getAuthHeader()
                }
            });
    }

    async publicToken(client_number) {
        return axios
            .post(process.env.REACT_APP_PORTAL_API_URL + "clients/token", {
                client_number: client_number,
                appkey: process.env.REACT_APP_PORTAL_API_KEY
            })
            .then(response => {
                localStorage.setItem("active-client", client_number);
                if (typeof response.data.token !== 'undefined') {
                    localStorage.setItem("public-token", response.data.token);
                }
                //get theme configurations
                return this.loadTheme()
                    .then(r => {
                        return response.data;
                    });
            })
            .catch(err => {
                this.clearPublicToken();
                throw err;
            });
    }

    async loadTheme () {
        return axios
            .get(process.env.REACT_APP_PORTAL_API_URL + "configurations/theme", { 
                headers: {
                    ...getDefaultHeaders(),
                    ...getPublicAuthHeader()
                }
            })
            .then(response => {
                if (typeof response.data.client_number !== 'undefined') {
                    localStorage.setItem("active-client", response.data.client_number);
                    localStorage.removeItem("theme-" + response.data.client_number);
                    if (typeof response.data.theme !== 'undefined') {
                        localStorage.setItem("theme-" + response.data.client_number, JSON.stringify(response.data.theme));
                    }
                }
                return response;
            });
    }

    clearPublicToken() {
        localStorage.removeItem('public-token');
    }

    getPublicToken() {
        return localStorage.getItem('public-token');
    }

    async registrationFields () {
        return axios.get(process.env.REACT_APP_PORTAL_API_URL + "configurations/registrationFields", {
            headers: {
                ...getDefaultHeaders(),
                ...getPublicAuthHeader()
            }
        })
        .then(response => {
            return response.data;
        });
    }

    async getUniqueContactByEmail (email) {
        return axios.get(process.env.REACT_APP_PORTAL_API_URL + "clients/getUniqueContact", {
            headers: {
                ...getDefaultHeaders(),
                ...getPublicAuthHeader()
            },
            params: {
                email: email,
            }
        })
        .then(response => {
            return response.data;
        });
    }
}

export default new AuthService();