import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { CustomThemeContext } from '../Contexts/CustomThemeContext.js';

const ConfiguredCustomText = (props) => {
    const { fallback, section, label } = props;
    const { i18n } = useTranslation();
    const { currentPublicClientConfigurations } = useContext(CustomThemeContext);
    
    const getPortalSectionText = (section, label) => {
        let stringKey;
        if (section === 'ressource' && label === "label") {
            stringKey = "global_label_practitioner";
        } else if (section === 'service' && label === "label") {
            stringKey = "global_label_service";
        } else {
            stringKey = "portal_texts_" + section + "_" + label;
        }
        if (typeof currentPublicClientConfigurations !== 'undefined' && currentPublicClientConfigurations) {
            if (typeof currentPublicClientConfigurations[stringKey] !== 'undefined') {
                return currentPublicClientConfigurations[stringKey];
            }
        }
        return false;
    }

    const getConfiguredText = () => {
        if (section && label) {
            let string = getPortalSectionText(section, label);
            if (string) {
                if (typeof string === 'string') {
                    return string;
                } else {
                    const lang = (i18n.language).substring(0,2);
                    if (typeof string[lang] !== 'undefined') {
                        return string[lang];
                    }
                }
            }
        }
        if (fallback) {
            return fallback;
        }
        return '';
    }

    return getConfiguredText();
}


export default ConfiguredCustomText;