import { Backdrop, Box, CircularProgress } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import AuthService from '../../Services/AuthService.js';
import { AuthContext } from '../Contexts/AuthContext.js';
import history from '../../Utility/RouterHistory';

const useStyles = (theme) => ({
    backdrop: {
        zIndex: 999,
    },
    spinner: {
        width: '200px !important',
        height: '200px !important'
    }
});

class Watchdog extends Component {
    componentDidMount() {
        const { clientNumber } = this.props.match.params;
        const { logout } = this.context;

        if (clientNumber !== undefined) {
            const acl = parseInt(AuthService.getActiveClientNumber());
            if (parseInt(clientNumber) !== acl) {
                logout();
            } else {
                let url = new URL(window.location.href);
                let searchParams = url.searchParams;
                
                let redirectString = '/';
                if (searchParams.has('redirect')) {
                    const redirectParam = searchParams.get('redirect'); 
                    if (typeof redirectParam === 'string' && redirectParam.indexOf('/') === 0) {
                        redirectString = redirectParam;
                    }
                }
                history.push(redirectString);
            }
        } else {
            logout();
        }
    }
    

    render() {
        const { classes } = this.props;
        return (
            <Backdrop open={true} className={classes.backdrop}>
                <CircularProgress color="primary" className={classes.spinner}/>
            </Backdrop>
        );
    }
}
Watchdog.contextType=AuthContext;


const  TranslatedComponent = withTranslation('translation')(Watchdog);
export default withStyles(useStyles)(TranslatedComponent);