import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import AdvancedDatepicker from '../AdvancedDatepicker';

class CustomDatefield extends Component {
    handleChangeDate = (date) => {
        const { onChange, name } = this.props;
        if (typeof onChange === 'function') {
            onChange({
                target: {
                    name: name,
                    value: date,
                }
            }); 
        }
    }

    render() {
        const { value } = this.props;

        return (
            <AdvancedDatepicker 
                inputProps={this.props}
                onChange={this.handleChangeDate}
                value={value ?? null}
            />
        );
    }
}

CustomDatefield.defaultProps = {
    value: null,
    name: null,
    label: '',
    disabled: false,
    onChange: null,
    helperText: '',
    error: false,
    required: false,
};

export default withTranslation('translation')(CustomDatefield);