import axios from "axios";
import Events from "../Utility/Events";
import AxiosStorage from 'axios-storage';

// set global config
AxiosStorage.config({
    storagePrefix: 'axios-storage',
    storageMode: 'sessionStorage',
    maxAge: 120 * 60 * 1000
});

export const getCleanedUrl = (url) => {
    var mUrl = url.replace(/^\/+|\/+$/gm,''); //trim leading an trailing slashes
    return process.env.REACT_APP_TREATSOFT_API_URL + mUrl;
} 

export const getPublicHeader = () => {
    let token = localStorage.getItem('public-token');
    return {
        Authorization: "Bearer " + token,
    };
}

export const getAuthHeader = () => {
    let token = localStorage.getItem('public-token');
    if (typeof localStorage.getItem('access-token') !== 'undefined' &&  localStorage.getItem('access-token')) {
        token = localStorage.getItem('access-token');
    }
    return {
        Authorization: "Bearer " + token,
    };
}

export const getDefaultHeaders = () => {
    return {
        'Accept': 'application/json',
    };
}

export const isValidHttpUrl = (string) => {
    let url;
    
    try {
      url = new URL(string);
    } catch (_) {
      return false;  
    }
  
    return url.protocol === "http:" || url.protocol === "https:";
}


const caches = {
    default: {
        maxAge: 60 * 60 * 1000,
        storageMode: 'sessionStorage'
    }
}

const axiosCached = axios.create({
    adapter: AxiosStorage.adapter
});

class RestAppService {
    //TODO: not in use yet
    async getCached(url, params = {}, headers = {}, cacheName = 'default') {
        return axiosCached({
            method: 'GET',
            url: getCleanedUrl(url),
            cacheConfig: caches[cacheName] ?? {},
            headers: {
                ...getDefaultHeaders(),
                ...getAuthHeader(),
                ...headers
            },
            params: {
                appkey: process.env.REACT_APP_TREATSOFT_API_KEY,
                ...params
            },
        })
        .then(res => {
            return res.data;
        });                    
    }

    async getCore(url, params = {}, headers = {}) {
        return axios.get(getCleanedUrl(url), {
                headers: {
                    ...getDefaultHeaders(),
                    ...getAuthHeader(),
                    ...headers
                },
                params: {
                    appkey: process.env.REACT_APP_TREATSOFT_API_KEY,
                    ...params
                },
            });
    }

    async download (url, params = {}, headers = {}) {
        return axios.get(getCleanedUrl(url), {
                headers: {
                    ...getAuthHeader(),
                    ...headers
                },
                params: {
                    ...params
                }
            });
    }

    async getPublic(url, params = {}, headers = {}) {
        return this.get(url, params, {...getPublicHeader()});
    }

    async get(url, params = {}, headers = {}) {
        Events.dispatch("startLoading");
        return this.getCore(url,params, headers)
            .then(response => {
                Events.dispatch("doneLoading");
                return response.data;
            })
            .catch(err => {
                Events.dispatch("doneLoading");
                throw err;
            });
    }

    async put(url, data = {}, headers = {}) {
        Events.dispatch("startLoading");
        return axios.put(getCleanedUrl(url), data, {
                headers: {
                    ...getDefaultHeaders(),
                    ...getAuthHeader(),
                    ...headers
                },
                params: {
                    appkey: process.env.REACT_APP_TREATSOFT_API_KEY,
                },
            })
            .then(response => {
                Events.dispatch("doneLoading");
                return response.data;
            });
    }

    async post(url, data = {}, headers = {}) {
        Events.dispatch("startLoading");
        return axios.post(getCleanedUrl(url), data, {
                headers: {
                    ...getDefaultHeaders(),
                    ...getAuthHeader(),
                    ...headers
                },
                params: {
                    appkey: process.env.REACT_APP_TREATSOFT_API_KEY,
                },
            })
            .then(response => {
                Events.dispatch("doneLoading");
                return response.data;
            });
    }
};
export default new RestAppService();