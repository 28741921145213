import React, { Component } from 'react';
import ContactService from '../Services/ContactService';
import RestAppService from '../Services/RestAppService';
import { formatDate } from './Formatter';

/**
 * creates a [{ id: '', label: ''}] object out of a key/value dictionary
 */
const objectify = (obj) => {
    if (Array.isArray(obj) && typeof obj[0] !== 'undefined') {
        return obj;
    }
    return (Object.entries(obj)).map(([id, label]) => ({ id: id, label: label }));
}


const formatFields = {
    birthday: formatDate
};

class ContactTypemapComponent extends Component {
    constructor(props) {
        super(props);
        
        this.state={
            ...this.state,
            options: {},
            typemap: {},
        };
    }
    

    getContactTypemap = () => {
        ContactService
            .getTypemap()
            .then(response => {
                this.populateTypeMap(response.typemap ?? {})
                    .then(res => {
                        if (typeof this.afterPopulateTypemap === 'function') {
                            this.afterPopulateTypemap();
                        }
                    });
            });
    }

    populateTypeMap = (typemap = {}) => {
        return new Promise((resolveMain, rejectMain) => {
            let options = {};
            let promises = [];
            Object.entries(typemap).forEach(([key,value]) => {
                promises.push(new Promise((resolve, reject) => {
                    if ((value['type'] ?? false) === 'autocomplete' && typeof value['url'] !== 'undefined') {
                        RestAppService
                            .get(value['url'])
                            .then(response => {
                                options[key] = objectify(response);
                                resolve();
                            });
                    } else {
                        options[key] = objectify(typemap[key]['options'] ?? {});
                        resolve();
                    }
                }));
            });
    
            Promise.all(promises).then(values => {
                this.setState({
                    options: options,
                    typemap: typemap
                });
                resolveMain();
            });
        });
    }

    getData = () => {
        const { data } = this.state;
        return data;
    }

    getPatchedValue = (name, fallback = '') => {
        const data = this.getData();
        if (data) {
            if (typeof data[name] !== 'undefined') {
                if (typeof formatFields[name] !== 'undefined') {
                    return formatFields[name](data[name]);
                } else {
                    return data[name];
                }
            }
            if (typeof formatFields[name] !== 'undefined') {
                return null;
            }
        }
        return fallback;
    }

    getTitle = (title) => {
        var label = title;
        label = label.replace('boolean_', '');
        label = label.replace('_id', '');
        label = label.replace('_ID', '');
        label = label.replace('_Id', '');
        label = label.replace('_', '-');
        return label;
    };

    handleAutocompleteInputChange = (e, value, reason, name) => {
        this.handleOnChange({
            target: {
                name: name,
                value: value ? value.id : null
            }
        });
    }
};

export default ContactTypemapComponent;