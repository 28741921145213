import moment from 'moment';

export function formatDateStr (value) {
    const dateStr = value.substring(0,10);
    if (/([\d]{2}).([\d]{2}).([\d]{4})/.test(dateStr)) {
        const split = dateStr.split(".");
        return split.reverse().join('-');
    } 
    if (/([\d]{4})-([\d]{2})-([\d]{2})/.test(dateStr)) {
        return dateStr;
    }
    return false;
}
export function formatDate (value) {
    if (typeof value === 'string') {
        const dateStr = formatDateStr(value);
        if (dateStr) {
            return dateStrToDate(dateStr);
        }
    } else {
        return value;
    }
    return null;
}
export function formatDateWithDay (date, i18n = 'de-DE') {
    if (date !== null && date !== '') {
        var dateTime = dateStrToDate(date);
        var dateOptions = {
            weekday: 'short',
            year: 'numeric', month: '2-digit', day: '2-digit',
        };

        return new Intl.DateTimeFormat(i18n, dateOptions).format(dateTime);
    }
    return "";
};
export function formatDateMonthDayOnly (date, i18n = 'de-DE') {
    if (date !== null && date !== '') {
        var dateTime = dateStrToDate(date);
        var dateOptions = {
            month: 'short', day: '2-digit',
        };

        return new Intl.DateTimeFormat(i18n, dateOptions).format(dateTime);
    }
    return "";
};
export function formatDateWithoutDay (date, i18n = 'de-DE') {
    if (date !== null && date !== '') {
        var dateTime = dateStrToDate(date);
        var dateOptions = {
            year: 'numeric', month: '2-digit', day: '2-digit',
        };

        return new Intl.DateTimeFormat(i18n, dateOptions).format(dateTime);
    }
    return "";
};

export function formatDateTime (date, i18n = 'de-DE') {
    if (date !== null && date !== '') {
        var dateTime = dateStrToDate(date);
        var dateTimeOptions = {
            hour12: false,
            hour: 'numeric', minute: 'numeric',
            year: 'numeric', month: '2-digit', day: '2-digit',
        };

        return new Intl.DateTimeFormat(i18n, dateTimeOptions).format(dateTime);
    }
    return "";
};

export function formatDateTimeFormal (date, i18n = 'de-DE') {
    if (date !== null && date !== '') {
        var dateTime = dateStrToDate(date);
        var dateTimeOptions = {
            hour12: false,
            hour: 'numeric', minute: 'numeric',
            weekday: 'short',
            year: 'numeric', month: 'short', day: '2-digit',
        };

        return new Intl.DateTimeFormat(i18n, dateTimeOptions).format(dateTime);
    }
    return "";
};

export function formatWeekday (date, i18n = 'de-DE') {
    if (date !== null && date !== '') {
        var dateTime = dateStrToDate(date);
        var dateOptions = {
            weekday: 'long'
        };
        return new Intl.DateTimeFormat(i18n, dateOptions).format(dateTime);
    }
    return "";
}

export function formatWeekdayShort (date, i18n = 'de-DE') {
    if (date !== null && date !== '') {
        var dateTime = dateStrToDate(date);
        var dateOptions = {
            weekday: 'short'
        };
        return new Intl.DateTimeFormat(i18n, dateOptions).format(dateTime);
    }
    return "";
}

export function formatWeekdayDate (date, i18n = 'de-DE') {
    if (date !== null && date !== '') {
        var dateTime = dateStrToDate(date);
        var dateOptions = {
            day: '2-digit', month: 'short'
        };
        if (dateTime.getFullYear() !== (new Date()).getFullYear()) {
            dateOptions.year = 'numeric';
        }
        return new Intl.DateTimeFormat(i18n, dateOptions).format(dateTime);
    }
    return "";
}

export function formatTime (date, i18n = 'de-DE') {
    if (date !== null && date !== '') {
        var dateTime = dateStrToDate(date);
        var timeOptions = {
            hour12: false,
            hour: 'numeric', minute: 'numeric'
        };
        return new Intl.DateTimeFormat(i18n, timeOptions).format(dateTime);
    }
    return "";
}

export function getTimeInMinutes (date) {
    if (date !== null && date !== '') {
        var dateTime = dateStrToDate(date);
        
        return dateTime.getHours() * 60 + dateTime.getMinutes();
    }
    return 0;
}

export function formatFilesize (bytes, decimals = 2) {
    if (bytes === null) return '---';
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}
export function ellipsis (label) {
    return <div style={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }} title={label}>{label}</div>;
}
export function dateStrToDate (date) {
    let dateStr = date;
    let format = 'YYYY-MM-DD';
    if (dateStr.length > 10) {
        format = 'YYYY-MM-DD hh:mm:ss';
    }
    return moment(dateStr, format).toDate();
}

//removes time from date and returns clean date at midnight
export function getDateOnly(date) {
    let newDate = date;
    //respect daylight zone
    if (newDate instanceof Date && !isNaN(newDate)) {
        var userTimezoneOffset = date.getTimezoneOffset() * 60000;
        newDate = new Date(date.getTime() - userTimezoneOffset);
        newDate.setUTCHours(0);
        newDate.setUTCMinutes(0);
        newDate.setUTCSeconds(0);
        newDate.setUTCMilliseconds(0);
    }
    return newDate;
}
export function formatPrice (price) {
    return formatNumber(price).replace(".", ",");
}
export function formatNumber (number) {
    return (Math.round(number*Math.pow(10,2))/Math.pow(10,2)).toFixed(2)
}
export function secondsToTime (s) {
    const pad = (n, z = 2) => ("00" + n).slice(-z);
    const secs = s % 60;
    s = (s - secs) / 60;
    const mins = s % 60;
    const hrs = (s - mins) / 60;
    return pad(hrs) + ":" + pad(mins) + ":" + pad(secs);
}