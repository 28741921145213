import { Fab } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import React, { Component } from 'react';
import Events from '../../Utility/Events';
import { CustomThemeContext } from '../Contexts/CustomThemeContext';

const useStyles = (theme) => ({
    fab: {
        transition: 'font-size 0.2s ease-in-out, margin 0.2s ease-in-out',
        whiteSpace: 'nowrap',
        [theme.breakpoints.down('md')]: {
            position: 'fixed',
            bottom: theme.spacing(9),
            right: theme.spacing(2),
            zIndex: 20,
        },
    },
    keyboardShown: {
        [theme.breakpoints.down('md')]: {
            bottom: theme.spacing(2),
        },
        [theme.breakpoints.down('sm')]: {
            bottom: theme.spacing(4),
        },
    },
    scrollDownClass: {
        [theme.breakpoints.down('md')]: {
            fontSize: '0px',
            '& svg': {
                marginRight: '0px',
            }
        }
    },
});

class CustomFab extends Component {
    constructor(props) {
        super(props);
        
        this.state = {
            scrollDown: false
        };
    }
    

    componentDidMount() {
        const self = this;
        Events.on('scrollUp', (e) => {
            self.setState({
                scrollDown: false
            });
        });

        Events.on('scrollDown', (e) => {
            self.setState({
                scrollDown: true
            });
        });
    }

    componentWillUnmount() {
        Events.remove('scrollUp');
        Events.remove('scrollDown');
    }
        

    render() {
        const { classes, children, disabled, variant, size, color, onClick } = this.props;
        const { scrollDown } = this.state;

        const getOptionalProps = () => {
            let props = {};
            if (typeof this.props.component !== 'undefined') {
                props.component = this.props.component;
            }
            if (typeof this.props.to !== 'undefined') {
                props.to = this.props.to;
            }
            return props;
        };

        return (
            <CustomThemeContext.Consumer>
                {({ isMobileKeyboardOpen, hasSidebarMenu }) => (
                    <Fab
                        variant={variant}
                        size={size}
                        color={color}
                        className={classes.fab + ' ' + (isMobileKeyboardOpen ? classes.keyboardShown : '') + ' ' + (scrollDown ? classes.scrollDownClass : '') + ' ' + (hasSidebarMenu() ? classes.keyboardShown : '')}
                        onClick={onClick}
                        disabled={disabled}
                        {...getOptionalProps()}
                    >
                        {children}
                    </Fab>
                )}
            </CustomThemeContext.Consumer>
        );
    }
}

CustomFab.defaultProps = {
    onClick: null,
    variant: 'extended',
    size: 'medium',
    color: 'primary',
    disabled: false,
}

export default withStyles(useStyles)(CustomFab);