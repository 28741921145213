import { Route } from "react-router-dom";
import Layout from "../Components/Secured/Layout";

function RouteLayoutMain ({ component: Component, ...rest }) {
    return (
        <Route
            {...rest}
            render={routeProps => (
                <Layout {...routeProps}>
                    <Component {...routeProps}/>
                </Layout>
            )}
        />
    );
}

export default RouteLayoutMain;