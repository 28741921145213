import { FormControl, FormControlLabel, FormHelperText, FormLabel, Radio, RadioGroup } from '@mui/material';
import React, { Component } from 'react';

class CustomRadio extends Component {
    render() {
        const { options, value, name, label, onChange, disabled, error, helperText } = this.props;
        return (
            <FormControl fullWidth component="fieldset" {...this.props}>
                <FormLabel component="legend">{label}</FormLabel>
                {(helperText) && <FormHelperText error={error ? true : false}>{helperText}</FormHelperText>}
                <RadioGroup aria-label={label} value={value} name={name} onChange={onChange}>
                    {Object.entries(options).map(([key, value]) => {
                        return <FormControlLabel key={"crgf-"+key} value={key} disabled={disabled} control={<Radio color="primary"/>} label={value}/>;
                    })}
                </RadioGroup>
            </FormControl>
        );
    }
}

CustomRadio.defaultProps = {
    options: {},
    value: null,
    name: null,
    label: '',
    disabled: false,
};

export default CustomRadio;