const PasswordRequirements = {
    hasLowercase(password) {
        return /[a-z]/.test(password);
    },
    hasUppercase(password) {
        return /[A-Z]/.test(password);
    },
    hasDigits(password) {
        return /[0-9]/.test(password);
    },
    hasOthers(password) {
        return /[^0-9A-Za-z]/.test(password);
    },
    hasLength(password) {
        return password.length > 7;
    },
    getSecurityFactors(password) {
        var factors = 0;
        if (this.hasLowercase(password)) {
            factors++;
        }
        if (this.hasUppercase(password)) {
            factors++;
        }
        if (this.hasDigits(password)) {
            factors++;
        }
        if (this.hasOthers(password)) {
            factors++;
        }
        return factors;
    },
    getCurrentStrength(password) {
        var strength = 0;
        if (password !== '') {
            if (this.hasLength(password)) {
                strength = this.getSecurityFactors(password);
            } else {
                strength = 1;
            }
        }
        return strength;
    },
    isValidPassword(password) {
        return this.getCurrentStrength(password) >= 3; 
    }
};

export default PasswordRequirements;