import RestAppService from "./RestAppService";

class BookingService {
    workflowPublic() {
        return RestAppService
            .getPublic("/bookings/workflow");
    }
    workflowAuthenticated() {
        return RestAppService
            .get("/bookings/workflow");
    }
}

export default new BookingService();