import React, { Component } from 'react';
import { Typography, TextField, Button, Grid, Link, Box } from '@mui/material';

import withStyles from '@mui/styles/withStyles';

import { Link as RouterLink } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import Events from '../../Utility/Events';
import AuthService from '../../Services/AuthService';

import history from '../../Utility/RouterHistory';
import ConfiguredCustomText from '../Elements/ConfiguredCustomText';
import { CustomThemeContext } from '../Contexts/CustomThemeContext';

const useStyles = (theme) => ({
    form: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        paddingTop: theme.spacing(2),
    },
    spacer: {
        marginTop: theme.spacing(1),
    },
    spaceActions: {
        marginTop: theme.spacing(4),
    }
});


class PasswordRequest extends Component {
    constructor(props) {
        super(props);

        this.state = {
            username: '',
            usernameError: undefined,
        };
    }

    handleUsernameChange = (e) => {
        this.setState({
            username: e.target.value.trim()
        });
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const { t } = this.props;
        this.setState({
            usernameError: undefined,
        });
        localStorage.setItem('last-username', this.state.username);
        AuthService
            .passwordRequest(this.state.username)
            .then(response => {
                history.push('/');
                Events.dispatch('msgSuccess', t('password-request-successfull'));
            })
            .catch(err => {
                this.setState({
                    usernameError: true,
                });
                Events.dispatch('msgError', t('password-request-failed'));
            });
    }

    render() {
        const { t, classes } = this.props;
        const { username, usernameError } = this.state;

        return (
            <form className={classes.form} noValidate onSubmit={this.handleSubmit}>
                <Box>
                    <Typography component="h1" variant="h5"><ConfiguredCustomText fallback={t('reset-password')} section={'pwrequest'} label={'title'}/></Typography>
                    <Typography component="h5" variant="subtitle2" color="textSecondary"><ConfiguredCustomText fallback={t('request-password-link-subtitle')} section={'pwrequest'} label={'primary'}/></Typography>
                    <Typography component="h5" variant="subtitle2" color="textSecondary"><ConfiguredCustomText section={'pwrequest'} label={'secondary'}/></Typography>
                </Box>
                <Box style={{ flexGrow: 1 }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="username"
                        name="username"
                        label={t('email')}
                        autoComplete="username"
                        autoFocus
                        error={usernameError}
                        className={usernameError ? 'shake' : ''}
                        value={username}
                        onChange={this.handleUsernameChange}
                    />
                </Box>
                <CustomThemeContext.Consumer>
                    {({ isMobileKeyboardOpen }) => (
                        <Box className={(isMobileKeyboardOpen && classes.spaceActions)}>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                disabled={username === '' ? true : false}
                                className={classes.submit}
                            >
                                {t('next')}
                            </Button>
                            <Grid container className={classes.spacer}>
                                <Grid item xs></Grid>
                                <Grid item>
                                    <Link
                                        to="/"
                                        component={RouterLink}
                                        variant="subtitle2"
                                        color="secondary"
                                        underline="hover">
                                        {t('cancel')}
                                    </Link>
                                </Grid>
                                <Grid item xs></Grid>
                            </Grid>
                        </Box>
                    )}
                </CustomThemeContext.Consumer>
            </form>
        );
    }
}

const TranslatedPasswordRequest = withTranslation('translation')(PasswordRequest);
export default withStyles(useStyles)(TranslatedPasswordRequest);