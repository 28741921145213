import { Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel } from '@mui/material';
import React, { Component } from 'react';

class CustomCheckbox extends Component {    
    render() {
        const { value, name, label, onChange, disabled, error, helperText, options } = this.props;

        const getBooleanValue = (value, fallback) => {
            if (value) {
                return true;
            }
            return false;
        }

        const getOptions = () => {
            return Object.entries(options);
        }

        const hasOptions = () => {
            return getOptions().length > 0;
        }

        const getOptionsValue = (subkey) => {
            if (typeof value[subkey] !== 'undefined') {
                return value[subkey];
            }
            return false;
        }

        return (
            <FormControl fullWidth component="fieldset" margin='normal' {...this.props}>
                <FormGroup>
                    {hasOptions()
                        ? <React.Fragment>
                            <FormLabel>{label}</FormLabel>
                            {getOptions().map(([itemValue, itemLabel], index) => {
                                const inputKey = name + '.' + itemValue;
                                return <FormControlLabel value="end" labelPlacement="end" label={itemLabel} disabled={disabled} control={<Checkbox color="primary" checked={getOptionsValue(itemValue)} name={inputKey} onChange={onChange}/>}/>;
                            })}
                        </React.Fragment>
                        : <FormControlLabel value="end" labelPlacement="end" label={label} disabled={disabled} control={<Checkbox color="primary" checked={getBooleanValue(value)} name={name} onChange={onChange}/>}/>
                    }
                </FormGroup>
                {(helperText) && <FormHelperText error={error ? true : false}>{helperText}</FormHelperText>}
            </FormControl>
        );
    }
}

CustomCheckbox.defaultProps = {
    value: null,
    name: null,
    label: '',
    disabled: false,
    options: [],
};

export default CustomCheckbox;