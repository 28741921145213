import { Box, Button, Card, CardActionArea, CardContent, Typography } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { formatTime } from '../../../Utility/Formatter';
import { BookingContext } from '../../Contexts/BookingContext';

import LocationOnIcon from '@mui/icons-material/LocationOn';
import ConfiguredCustomText from '../ConfiguredCustomText';

const useStyles = (theme) => ({
    item: {
        left: 0,
        width: '100%',
        '&.dark': {
            boxShadow: "0px 0px 3px " + theme.palette.primary.main,
        }
    },
    itemHeader: {
        display: 'flex', 
        flexWrap: 'wrap',
        alignItems: 'center',
        paddingBottom: 'unset',
    },
    timeheader: {
        fontSize: theme.spacing(2.25),
    }
});

class DateCardItem extends Component {
    render() {
        const { t, classes, theme } = this.props;
        const { item, open, sx, denseView, viewType } = this.props;

        const getGroupdateTitle = (item) => {
            if (typeof item.title === 'string' && item.title !== '') {
                return item.title;
            }
            return <ConfiguredCustomText fallback={t('groupdate')} section={'booking'} label={'groupdate_label'}/>;
        }

        const getDateRessource = (item) => {
            if (typeof item.trainer === 'string' && item.trainer !== '') {
                return item.trainer;
            }
            return item.user;
        }

        const getDateLocationLabel = (item) => {
            if (item) {
                if (typeof item.locationDetails !== 'undefined') {
                    if (item.locationDetails && typeof item.locationDetails.id !== 'undefined') {
                        return <Typography variant="subtitle2" component="div" color="secondary" title={item.locationDetails.address ?? item.locationDetails.name}>
                            {item.locationDetails.name}
                        </Typography>;
                    }
                }
            }
            return null;
        }

        return <BookingContext.Consumer>
            {({ handleStageOptionClick, users, location }) => (
                <Card className={classes.item + " " + theme.palette.mode} sx={sx} variant='elevation'>
                    <CardActionArea component={Button} onClick={(e) => handleStageOptionClick(e, { type: 'booking', next: 'checkout', item: item })} sx={{ textTransform: 'none' }}>
                        <CardContent>
                            <Box><Typography variant="h7" className={classes.timeheader}><strong>{formatTime(item.begin)}</strong> | {item.duration + ' min.'}</Typography></Box>
                            <Box>
                                <Box><Typography variant="subtitle2" component="div" color="secondary">{getDateRessource(item)}</Typography></Box>
                                <Box>{getDateLocationLabel(item)}</Box>
                                {typeof item.date_id !== 'undefined' && <Box>
                                    <Typography color='secondary' component="div" variant='subtitle2'>{getGroupdateTitle(item)}</Typography>
                                </Box>}
                            </Box>
                        </CardContent>
                    </CardActionArea>
                </Card>
            )}
        </BookingContext.Consumer>;
    }
}

DateCardItem.defaultProps = {
    item: {},
    sx: {},
    denseView: false,
}


const  TranslatedComponent = withTranslation('translation')(DateCardItem);
export default withStyles(useStyles, { withTheme: true })(TranslatedComponent);